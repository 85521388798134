import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { statusCode } from 'src/app/common/constants/constant';
import { messages } from 'src/app/common/constants/messages';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/common/services/service/common.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { CustomValidationService } from 'src/app/common/services/service/custom-validation.service';

@Component({
  selector: 'app-add-collection',
  templateUrl: './add-collection.component.html',
  styles: [ ]
})
export class AddCollectionComponent implements OnInit {

  apiConstant = APIConstants;
  statusCode = statusCode;
  messages = messages;

  collectionName;
  collectionForm: FormGroup;
  submitted = false;
  id;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private commonService: CommonService,
    public showErrorService: ShowErrorService,
    private customValidationService: CustomValidationService
  ) { }

  ngOnInit(): void {
    this.collectionForm = this.fb.group({
      collectionName: [this.collectionName, [Validators.required, Validators.maxLength(50), this.customValidationService.noWhitespaceValidator()]]
    });
  }

  /*********************************************************
  @PURPOSE: save collectionName
  *********************************************************/
  saveCollection() {
    if (this.collectionForm.valid) {
      const data = { name: this.collectionForm.controls.collectionName.value };
      const url = this.collectionName ? this.apiConstant.MY_FAV + '/' + this.id : this.apiConstant.MY_FAV;
      const method = this.collectionName ? 'put' : 'post';
      this.commonService.callApi(url, data, method, false, false, false).then((success) => {
        if (success.status === this.statusCode.SUCCESS) {
          this.bsModalRef.hide();
          this.event.emit();
        }
      });
    } else {
      this.submitted = true;
    }
  }
  /*********************************************************/

}
