import { ShortProfileComponent } from './../short-profile/short-profile.component';
import { ForgetPasswordComponent } from './../forget-password/forget-password.component';
import { RegisterComponent } from '../register/register.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { statusCode } from './../../../common/constants/constant';
import { APIConstants } from './../../../common/constants/callAPI-constants';
import { Component, OnInit } from '@angular/core';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { CommonService } from 'src/app/common/services/service/common.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { VerifyOtpComponent } from '../verify-otp/verify-otp.component';
import { messages } from 'src/app/common/constants/messages';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { CustomValidationService } from 'src/app/common/services/service/custom-validation.service';
import { QBHelper } from 'src/app/common/services/TS-files/qbHelper';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  apiConstant = APIConstants;
  urlConstant = URLConstants;
  imgUrl = environment.imgUrl;
  statusCode = statusCode;
  messages = messages;

  loginForm: FormGroup;
  submitted = false;
  passwordType: boolean;
  mobile: any;
  emailId: any;

  constructor(
    private router: Router,
    private commonService: CommonService,
    private localStorageService: LocalStorageService,
    public showErrorService: ShowErrorService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private customValidator: CustomValidationService,
    private qbHelper: QBHelper
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.loginForm = this.fb.group({
      emailId: ['', [Validators.required, Validators.maxLength(50), this.customValidator.validateEmailMobile()]],
      password: ['', Validators.required]
    });
  }

  toggleFieldTextType() {
    this.passwordType = !this.passwordType;
  }

  /*************************************************************
  @Purpose     : Validate on Submit and if valid then call login
  /*************************************************************/
  onSubmit() {
    if (this.loginForm.valid) {
      this.mobile = this.customValidator.validateMobile(this.loginForm.controls.emailId.value) ? this.loginForm.controls.emailId.value : '';
      this.emailId = this.customValidator.validateEmail(this.loginForm.controls.emailId.value) ? this.loginForm.controls.emailId.value : '';
      const data = {
        emailId: this.emailId,
        mobile: this.mobile,
        password: this.loginForm.controls.password.value
      };
      this.login(data);
    } else {
      this.submitted = true;
    }
  }

  /*************************************************************
  @Purpose     : Social Login
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  socialLogin() {
    const data = {
      socialId: '',
      socialType: ''
    };
    this.login(data);
  }
  /*************************************************************/

  /*************************************************************
  @Purpose     : Normal Login
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  login(data) {
    this.commonService.callApi(this.apiConstant.LOGIN, data, 'post', true, false, false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        this.showErrorService.displayToastr('success', this.messages.SUCCESS.LOGIN);
        this.bsModalRef.hide();
        const response = success.body.data;
        this.commonService.notificationCount = 0;
        if (response.isVerified) {
          this.localStorageService.setToken('altToken', success.body.extra_meta.token);   // this is for short profile
          if (response.isRegisterProfileCompleted) {
            this.localStorageService.setToken('accessToken', success.body.extra_meta.token);
            this.localStorageService.setToken('userId', response.userId);
            this.localStorageService.setToken('firstName', response.firstName);
            this.localStorageService.setToken('lastName', response.lastName);
            this.localStorageService.setToken('emailId', response.emailId);
            this.localStorageService.setToken('mobile', response.mobile);
            this.localStorageService.setToken('hasPassword', response.hasPassword);
            this.localStorageService.setToken('myReferralCode', response.myReferralCode);
            this.localStorageService.setToken('googleAccountLinked', response.googleAccountLinked);
            this.localStorageService.setToken('connectionPrivacy', response.connectionPrivacy);
            this.localStorageService.setToken('followPrivacy', response.followPrivacy);
            this.localStorageService.setToken('statusPrivacy', response.statusPrivacy);
            if (response.photo !== undefined) {
              this.localStorageService.setToken('photo', this.imgUrl + response.photo);
            }
            if (success.body.quickBloxUser) {
              this.qbHelper.setQBLogin(success.body.quickBloxUser);
            }
            this.router.navigate([this.urlConstant.HOME]);
          } else {
            this.modalService.show(ShortProfileComponent, { backdrop: 'static', class: '' });
          }
        } else {
          this.modalService.show(VerifyOtpComponent, {
            backdrop: 'static',
            initialState: {
              userId: response.userId,
              verificationCode: response.verificationCode,
              emailIdMobile: response.emailId ? response.emailId : response.mobile,
              fromForgotPass: false,
              shortProfile: true
            }
          });
        }
        this.commonService.getNotificationList();
      }
    });
  }

  forgetPass() {
    this.bsModalRef.hide();
    this.modalService.show(ForgetPasswordComponent, { backdrop: 'static', class: 'common-modal modal-dialog-centered' });
  }

  register() {
    this.bsModalRef.hide();
    this.modalService.show(RegisterComponent, { backdrop: 'static', class: 'common-modal signup-modal login-signup-wrap modal-dialog-centered' });
  }
}

