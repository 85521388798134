import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';

interface VideoPlayer extends HTMLVideoElement {
  requestPictureInPicture(): any;
}

@Component({
  selector: 'app-cover-video-popup',
  templateUrl: './cover-video-popup.component.html',
  styles: []
})
export class CoverVideoPopupComponent implements OnInit {

  imgUrl = environment.imgUrl;
  data;
  initialSlide = 0;
  isIntro = false;
  isAtt = false;
  isImg = false;
  isChat = false;
  isMultiple = false;
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    initialSlide: 0,
    adaptiveHeight: true,
    arrows: true,
    swipe: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        },
      }
    ]
  };

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    if (this.isMultiple) {
      this.slideConfig.initialSlide = this.initialSlide
    }
   }

  /*********************************************************
  @PURPOSE: view video in Picture-in-Picture mode
  *********************************************************/
  viewPictureInPicture() {
    const video: VideoPlayer = this.videoPlayer.nativeElement;
    this.bsModalRef.hide();
    video.requestPictureInPicture();
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: add class for full screen
  *********************************************************/
  addFullScreen() {
    const video = document.getElementById('videoblock').classList;
    video.add('fullscreen');
    this.exitEvent();
  }

  /*********************************************************
  @PURPOSE: remove class on full screen exit
  *********************************************************/
  exitEvent() {
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);

    function exitHandler() {
      if (!document['webkitIsFullScreen'] && !document['mozFullScreen'] && !document['msFullscreenElement']) {
        const video = document.getElementById('videoblock').classList;
        video.remove('fullscreen');
      }
    }
  }

}
