<!-- header -->
<app-header [publicHeader]="publicHeader" [linkedinHeader]="linkedinHeader" [authHeader]="authHeader"
    [authSubHeader]="authSubHeader"></app-header>
<!-- header -->
<app-video-upload-progress *ngIf = "(authHeader || authSubHeader)"></app-video-upload-progress>
<app-common-message-connection *ngIf = "showMessageWindow"></app-common-message-connection>
<app-common-message-dialog *ngIf = "showMessageWindow"></app-common-message-dialog>
<div class="layout-wrap">
    <router-outlet></router-outlet>
</div>
<!-- footer -->
<app-footer></app-footer>
<!-- footer -->
<ngx-ui-loader></ngx-ui-loader>