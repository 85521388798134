export const messages = {
    ERROR: {
        REQUIRED: {
            username: 'Enter username',
            firstName: 'Enter first name',
            lastName: 'Enter last name',
            emailId: 'Enter email ID or mobile number ',
            mobile: 'Enter mobile number',
            oldPassword: 'Enter old password',
            newPassword: 'Enter new password',
            confirmPassword: 'Enter confirm password',
            otp: 'Enter OTP',
            fill_required: 'Please fill all required details properly',
            position: 'Select position',
            summary: 'Enter summary',
            title: 'Enter title',
            company: 'Enter company name',
            location: 'Enter location',
            startDate: 'Enter start date',
            endDate: 'Enter end date',
            instituteName: 'Enter school or institute',
            degree: 'Enter degree',
            fields: 'Enter field of study',
            startYear: 'Enter start year',
            endYear: 'Enter end year',
            orgName: 'Enter issuing organization',
            issueDate: 'Enter issue date',
            expiryDate: 'Enter expiry date',
            locations: 'Enter headquarter location',
            overview: 'Enter overview',
            investmentRangeFrom: 'Enter minimum investment range',
            investmentRangeTo: 'Enter maximum investment range',
            industryIds: 'Select industry',
            investmentStages: 'Select investment stages',
            companyName: 'Enter company name',
            stage: 'Select funding stage',
            amount: 'Enter amount',
            investmentDate: 'Select investment date',
            exitDate: 'Select exit date',
            email: 'Enter email',
            foundedYear: 'Enter founded year',
            website: 'Enter website',
            companyDescription: 'Enter company description',
            teamMemberName: 'Enter team member name',
            positionname: 'Enter position',
            bio: 'Enter short bio',
            emailId1: 'Enter email id',
            subType: 'Enter investor type',
            subIndustryIds: 'Select sub industry',
            privacy: 'Select Privacy',
            tagLine: 'Enter tagline',
            hourlyRate: 'Enter hourly rate',
            languages: 'Select languages',
            skills: 'Select skills',
            projecttitle: 'Enter project title',
            projectoverview: 'Enter project overview',
            startProjectDate: 'Enter project start date',
            endProjectDate: 'Enter project end date',
            identity: 'Select Identity',
            packagename: 'Enter package name',
            packagedescription: 'Enter package description',
            deliveryTime: 'Enter delivery time',
            deliveryIn: 'Enter delivery in',
            priceRangeFrom: 'Enter minimum price range',
            priceRangeTo: 'Enter maximum price range',
            investmentCriteria: "Enter investment criteria",
            selectType: 'Select your page type',
            comment: 'Please enter comment',
            comments: 'Please enter your comments',
            companyUrl: 'Enter linkedin or company URL',
            investorName: 'Enter investor name',
            totalFundsRaisedToDate: 'Enter total funds raised to date',
            currentFundingRound: 'Select current funding round type',
            investorRoles: 'Select ideal investor roles',
            currentRoundAmount: 'Enter amount to be raised in current round',
            currentRoundAmountReceived: 'Enter commitments or amount for current round',
            roundSubscribedPercent: 'Enter % of current round subscribed',
            totalLots: 'Enter no.of lots / tranches offered',
            amountPerLot: 'Enter investment size per lot / tranche',
            meetingTitle: 'Enter meeting title',
            meetingDate: 'Select meeting date',
            meetingTime: 'Enter meeting time',
            meetingUrl: 'Enter meeting URL',
            businessSummary: 'Enter business summary',
            problemStatement: 'Enter problem statement',
            solutionOffering: 'Enter solution offering',
            marketOpportunity: 'Enter market opportunity',
            productService: 'Enter product/services',
            valueProposition: 'Enter value proposition',
            traction: 'Enter traction',
            businessModel: 'Enter business/revenue model',
            financialSummary: 'Enter financial summary',
            targetedValuationDate: 'Enter targeted valuation date',
            targetedValuationAmount: 'Enter targeted valuation amount',
            allocation: 'Enter % of allocation',
            note: 'Enter note',
            expectedRoiPercent: 'Enter expected investor returns',
            expectedRoiNote: 'Enter expected investor returns',
            reason: 'Enter reason for reporting',
            collectionName: 'Enter collection name',
            collection: 'Select collection',
            folder: 'Select folder',
            folderName: 'Enter folder name',
            cardNumber: 'Enter card number',
            name: 'Enter card holder name',
            expDate: 'Enter expiration',
            cvc: 'Enter CVV code',
            bankName: 'Enter bank name',
            accountNumber: 'Enter account number',
            routingNumber: 'Enter routing number',
            country: 'Select country name',
            currency: 'Select currency',
            legalName: 'Enter legal name',
            dateOfBirth: 'Enter date of birth',
            foundingDate: 'Enter founded year',
            residentAddress: 'Enter residence address',
            phoneNumber: 'Enter phone number',
            countryOfCitizenship: 'Select country of citizenship',
            countryOfCurrentResidence: 'Select country of current residence',
            closeReason: 'Select a reason',
            otherReason: 'Enter reason',
            jobDescription: 'Enter your requirements',
            jobTitle: 'Enter job title',
            contract: 'Select contract',
            period: 'Select period',
            amountType: 'Select amount type',
            durationType: 'Select duration type',
            duration: 'Enter duration',
            changeEmail: 'Enter new email id',
            changeMobile: 'Enter new mobile number',
            totalAmountPercent: 'Enter percentage',
            milestoneAmount: 'Enter milestone amount',
            tentativeDate: 'Select milestone date',
            milestoneTitle: 'Enter milestone title',
            experience: 'Enter your experience',
            document: 'Add atleast 1 document',
            participantName: 'Enter name',
            
        },
        PATTERN: {
            emailId: 'Enter valid email ID or mobile number (include "+" and country code with mobile number)',
            mobile: 'Enter valid mobile number (include "+" and country code with mobile number)',
            newPassword: 'Password must have atleast 8 characters and should contain atleast 3 among uppercase, lowercase, digit, special character',
            confirmPassword: 'New password and confirm password are not same.',
            oldNewPassword: 'Old password and new password can not be same',
            firstName: 'Enter valid first name',
            lastName: 'Enter valid last name',
            location: 'Enter valid location',
            locations: 'Enter valid headquarter location',
            emailId1: 'Enter valid email id',
            title: 'Enter valid title',
            email: 'Enter valid email',
            companyUrl: 'Enter valid linkedin or company URL',
            linkLinkedin: 'Enter valid linkedin URL',
            linkFacebook: 'Enter valid facebook URL',
            linkBlog: 'Enter valid blog URL',
            linkReddit: 'Enter valid reddit URL',
            linkTwitter: 'Enter valid twitter URL',
            meetingTitle: 'Enter valid meeting title',
            meetingUrl: 'Enter valid meeting URL',
            website: 'Enter valid website',
            cardName: 'Enter valid card holder name',
            cardNumber: 'Enter valid card number',
            expDate: 'Enter valid expiration',
            legalName: 'Enter valid legal name',
            phoneNumber: 'Enter valid phone number (include "+" and country code with mobile number)',
            accountHolderName: 'Enter valid account holder name',
            bankName: 'Enter valid bank name',
            dateOfBirth: 'Enter valid date of birth',
            foundingDate: 'Enter valid founded year',
            participantName: 'Enter valid name'
        },
        MINLENGTH: {
            mobile: 'Mobile must have atleast 10 digits',
            newPassword: 'Password must have atleast 8 characters',
            firstName: 'Firstname should be in 2-20 characters',
            lastName: 'Lastname should be in 2-20 characters',
            teamMemberName: 'Team member should be in 2-20 characters',
            investorName: 'Investor should be in 2-50 characters',
            companyName: 'Company name should be in 2-50 characters',
            projecttitle: 'Project title should be in 2-50 characters',
            packagename: 'Package name should be in 2-50 characters',
            residentAddress: 'Enter valid residence address',
            accountNumber: 'Account number should be 8-18 digit long',
            routingNumber: 'Routing number should be 9 digit long'
        },
        MAXLENGTH: {
            firstName: 'Firstname should be in 2-20 characters',
            lastName: 'Lastname should be in 2-20 characters',
            teamMemberName: 'Team member should be in 2-20 characters',
            investorName: 'Investor should be in 2-50 characters',
            companyName: 'Company name should be in 2-50 characters',
            projecttitle: 'Project title should be in 2-50 characters',
            packagename: 'Package name should be in 2-50 characters'
        },
        MAX: {
            priceRangeFrom: 'Min price range must be less than the max price range',
            investmentRangeFrom: 'Min investment range must be less than the max investment range'
        },
        MIN: {
            priceRangeTo: 'Max price range must be greater than the min price range',
            investmentRangeTo: 'Max investment range must be greater than the min investment range'
        },
        LENGTH: {
            cardNumber: 'Card Number should be 16 digit long',
            cvc: 'CVV code should be minimum 3 digit long'
        },
        OTHERS: {
            INVALID_OTP: 'Invalid OTP',
            date: 'Enter valid date',
            TC: 'Please accept Terms and Conditions',
            time: 'Enter valid time',
            location: 'Select valid location',
            locations: 'Select valid location',
            NO_RESPONSE: 'Something went wrong..',
            SAME_EMAIL: 'Email id already exist',
            SAME_MOBILE: 'Mobile Number already exist',
            verificationNotInitiated: 'Your account is not verified. Please update below details to verify your account before proceeding to verify your accredited status',
            verificationPending: 'Page verification request for selected identity is pending for approval',
            verificationRejected: 'Page verification request for selected identity is rejected.',
            accrPending: 'Accreditation request for selected identity is pending for approval',
            accrRejected: 'Accreditation request for selected identity is rejected',
            noVerify: 'You do not have any identites to verify / all identites approved.',
            noAccr: 'You do not have any accreditation to verify / all accreditation approved.',
            FILE_SIZE: 'The media file is too large, it exceeds the 10MB size',
            VIDEO_SIZE: 'Video size should be greater than 5MB',
            PARTICIPANT_EMAIL_EXIST : 'This email id already exist on platform, you can directly add them through name',
            PARTICIPANT_EXIST: 'Participant already added'
        },
        CUSTOM: {},
        ALERT: {
            DELETE: 'Are you sure you want to delete',
            DELETE_BTN: 'Delete',
            DELETE_CANCEL_BTN: 'Cancel',
            DELETE_COVER: 'Are you sure you want to delete cover video',
            DELETE_COVER_BTN: 'Delete',
            DELETE_COVER_CANCEL_BTN: 'Cancel',
            LOGOUT: 'Are you sure you want to logout?',
            LOGOUT_BTN: 'Logout',
            LOGOUT_CANCEL_BTN: 'No',
            CLOSE_ACCOUNT: 'Are you sure you want to close this account?',
            CLOSE_ACCOUNT_BTN: 'Close Account',
            CLOSE_ACCOUNT_CANCEL_BTN: 'Cancel',
            RSVP: 'RSVP',
            RSVP_BTN: 'Yes',
            RSVP_CANCEL_BTN: 'No',
            UNFOLLOW: 'Are you sure you want to unfollow?',
            UNFOLLOW_BTN: 'Unfollow',
            UNFOLLOW_CANCEL_BTN: 'No',
            REMOVE_CONNECTION_REQUEST: 'Are you sure you want to remove request?',
            REMOVE_CONNECTION: 'Are you sure you want to remove connection?',
            REMOVE_CONNECTION_BTN: 'Remove',
            REMOVE_CONNECTION_CANCEL_BTN: 'No',
            REMOVE_FAV: 'Are you sure you want to remove favorite?',
            REMOVE_FAV_BTN: 'Remove',
            REMOVE_FAV_CANCEL_BTN: 'No',
            CLOSE_UPLOAD: 'Uploaded video will be discarded, are you sure you want to close?',
            CLOSE_UPLOAD_BTN: 'Close',
            CLOSE_UPLOAD_CANCEL_BTN: 'Cancel',
            ACCEPT_MSG: 'Are you sure you want to accept invitation?',
            ACCEPT_MSG_BTN: 'Yes',
            ACCEPT_MSG_CANCEL_BTN: 'No',
            REJECT_MSG: 'Are you sure you want to reject invitation?',
            REJECT_MSG_BTN: 'Yes',
            REJECT_MSG_CANCEL_BTN: 'No',
            BLOCK_MSG: 'Are you sure you want to block?',
            BLOCK_MSG_BTN: 'Block',
            BLOCK_MSG_CANCEL_BTN: 'Cancel',
            UNBLOCK_MSG: 'Are you sure you want to unblock?',
            UNBLOCK_MSG_BTN: 'Unblock',
            UNBLOCK_MSG_CANCEL_BTN: 'Cancel',
            PAY_MSG: 'Are you sure you want to do this payment?',
            PAY_MSG_BTN: 'Pay',
            PAY_MSG_CANCEL_BTN: 'Cancel',
            PLAN_MSG: 'Are you sure you want to change the plan?',
            PLAN_MSG_BTN: 'Yes',
            PLAN_MSG_CANCEL_BTN: 'Cancel',
            REMOVE_PROMOTE_MSG: 'Are you sure you want to stop promotion for this',
            REMOVE_PROMOTE_BTN: 'Yes',
            REMOVE_PROMOTE_CANCEL_BTN: 'Cancel',
            INSUF_STORAGE_TITLE: 'Buy Storage',
            INSUF_STORAGE_MSG: 'You do not have enough storage to upload this file . Please buy storage',
            INSUF_STORAGE_BTN: 'Buy',
            INSUF_CONN_TITLE: 'Buy Connection Requests',
            INSUF_CONN_MSG: 'You do not have enough connection request to send this request . Please buy request',
            INSUF_CONN_BTN: 'Buy',
            INSUF_CHAT_TITLE: 'Buy Message credits',
            INSUF_CHAT_MSG: 'You do not have enough message credits to send this chat request . Please buy message credits',
            INSUF_CHAT_BTN: 'Buy',
            INSUF_JOB_TITLE: 'Add credits',
            INSUF_JOB_MSG: 'You are running out of credits. Please purchase credits to apply for this job.',
            INSUF_JOB_BTN: 'Purchase',
            INSUF_PAGE_TITLE: 'Upgrade Plan',
            INSUF_PAGE_MSG: 'Please upgrade your plan to create additional business pages',
            INSUF_PAGE_BTN: 'Upgrade',
            INSUF_TEASER_TITLE: 'Upgrade Plan',
            INSUF_TEASER_MSG: 'Please upgrade your plan to upload your own investment teaser',
            INSUF_TEASER_BTN: 'Upgrade',
            INSUF_PROMO_TITLE: 'Add credits',
            INSUF_PROMO_MSG: 'You are running out of credits. Please purchase credits to promote.',
            INSUF_PROMO_BTN: 'Purchase',
            CANCEL_SUBS_MSG: 'Are you sure you want to cancel this plan?',
            CANCEL_SUBS_BTN: 'Yes',
            CANCEL_SUBS_CANCEL_BTN: 'No',
            PROMOTE_MSG: 'Are you sure you want to promote this ',
            PROMOTE_MSG_BTN: 'Promote',
            PROMOTE_MSG_CANCEL_BTN: 'Cancel',
            PAY_NOW_MSG_BTN: 'Pay Now',
            PAY_NOW_MSG_CANCEL_BTN: 'Pay Later',
            NEED_HELP_ALERT: 'You missed your payment milestone date.',
            NEED_HELP_MSG: "You missed your payment milestone date , to edit milestone tap/click on 'Need help'",
            NEED_HELP_MSG_BTN: 'Need help',
            NEED_HELP_MSG_CANCEL_BTN: 'Cancel',
            MILESTONE_ADD_MSG_BTN: 'Add Milestone',
            MILESTONE_EDIT_MSG_BTN: 'Edit Milestone',
            MILESTONe_MSG_CANCEL_BTN: 'Cancel',
            CROSS_PLATFORM_PLAN: "You can change paid plan only from the platform you've purchased . Ex: android, ios, web",
            CROSS_PLATFORM_OK: 'Ok'
        }
    },
    SUCCESS: {
        LOGIN: 'Logged in successfully',
        RESEND_OTP: 'New OTP is sent to your given email address or phone number.',
        RESEND_OTP_EMAIL: 'New OTP is sent to your given email address',
        RESEND_OTP_MOBILE: 'New OTP is sent to your given mobile number.',
        VIDEO_UPLOAD: 'Video uploaded successfully',
        COMMENT: 'Comment added successfully',
        EMAIL_CHANGE: 'Email ID changed successfully',
        MOBILE_CHANGE: 'Mobile Number changed successfully'
    },
    VERIFY_MSG: 'All users who have provided documents to self-verify their own or their business’s identity and residency will be tagged as verified on our platform. The documents shared for self-verification are reviewed by but are not independently verified by G’t Funded.',
    ACCR_MSG: 'All investors who have provided documents to self-verify their US SEC accreditation requirements will be tagged as accredited investors on our platform. The documents shared for self-verification are reviewed by but are not independently verified by G’t Funded.',
    NO_DATA: {
        LIST: 'Sorry! We did not find any relevant data!',
        PAGE: 'You have not created any Pages. Click “+” icon to create your first page.',
        MEETING: 'You have no scheduled meetings for this day',
        FOLDER: 'You have not created any folder. Once created, your folders will be listed here',
        DOCUMENT: 'You have not uploaded any documents',
        COMMON_MSG: 'This user has not posted anything yet',
        OTHER_PAGES: 'There are no other pages associated with this account',
        MESSAGE: 'You have not yet sent or received any messages',
        FILTER_MSG: 'We do not have any results based on your criteria. Please edit your criteria to see the available results or check back again later.',
        CREDIT: 'There are no previous purchase transactions. Make your first purchase today.',
        JOB_POSTINGS: 'You have not posted any jobs yet. Click “Create Job” to post your first job today.',
        ONGOING_JOBS: 'You currently have no ongoing jobs',
        PAST_JOBS: 'You have not completed any jobs on G’tFunded platform yet',
        JOB_INVITES: 'You have not received any Job Invites yet',
        JOB_PROPOSAL: 'You have not applied for any jobs yet. Once you apply for a job, your proposal details will be available here',
        DISPUTES: 'There are no disputes',
        NOTIFICATIONS: 'You have no new notifications',
        CONNECTIONS: 'You do not have any connections yet'
    }
};
