import { VideoProcessingService } from 'src/app/common/services/service/video-processing.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { CommonService } from 'src/app/common/services/service/common.service';
import { APIConstants } from './../../common/constants/callAPI-constants';
import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, FacebookLoginProvider } from 'angularx-social-login';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { statusCode } from '../../common/constants/constant'
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { messages } from 'src/app/common/constants/messages';
import { environment } from 'src/environments/environment';
import { EmailMobileComponent } from '../modal/email-mobile/email-mobile.component';
import { VerifyOtpComponent } from '../modal/verify-otp/verify-otp.component';
import { ShortProfileComponent } from '../modal/short-profile/short-profile.component';
import { QBHelper } from 'src/app/common/services/TS-files/qbHelper';

@Component({
  selector: 'app-social-signup',
  templateUrl: './social-signup.component.html',
  styles: []
})

export class SocialSignupComponent implements OnInit {

  apiConstant = APIConstants;
  urlConstant = URLConstants;
  imgUrl = environment.imgUrl;
  statusCode = statusCode;
  messages = messages;
  public photo: any;
  public facebookId: any;
  public facebookData: any = {};
  public emailId;
  public mobile;
  public alreadyRegistered: boolean = false;
  public login: boolean = false;
  public register: boolean = false;
  public count = 0;
  linkedInCredentials = {
    clientId: environment.linkedin_clientid,
    redirectUrl: environment.linkedin_redirect_url,
    scope: "r_liteprofile%20r_emailaddress",// To read basic user profile data and email,
    random: 'unicorn'
  };
  @Input() type: any;

  constructor(private router: Router, private localStorageService: LocalStorageService, private http: HttpClient, private route: ActivatedRoute, private authService: AuthService,
    private commonService: CommonService,
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private qbHelper: QBHelper) {
    this.modalService.onHidden.subscribe(res => {
      if (res) {
        this.emailId = res.emailId;
        this.mobile = res.mobile;
        if (this.count == 0 || this.count == 1) {
          this.count = this.count + 1;
          if (this.login) {
            this.loginCallApi(this.facebookData, res, 'email')
          }
          if (this.register) {
            this.registerCallApi(this.facebookData, res, 'email')
          }
        }
      }
    })
  }

  ngOnInit(): void {
  }

  socialLogin(socialtype) {
    this.localStorageService.setToken('typeregisterlogin', this.type)
    if (socialtype == 'linkedin') {
      window.open(`https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${this.linkedInCredentials.clientId}&redirect_uri=${this.linkedInCredentials.redirectUrl}&scope=${this.linkedInCredentials.scope}&state=${this.linkedInCredentials.random}`, '_parent').focus();
    } else {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((x) => {
        this.facebookData = x;
        this.emailId = x.email;
        this.mobile = '';
        this.commonService.callApi(this.apiConstant.SOCIAL_ID_VERIFY, { socialId: x.id, emailId: x.email, socialType: 'facebook' }, 'post', true, false).then((success) => {
          this.facebookId = x.id;
          if (success.body.data.isExist) {
            if (!this.emailId && !this.mobile) {
              this.login = true;
              this.bsModalRef.hide()
              this.modalService.show(EmailMobileComponent, { backdrop: 'static', class: 'common-modal modal-dialog-centered' })
            }
            if (this.emailId || this.mobile) {
              this.login = true;
              this.loginCallApi(x, true)
            }
          } else {
            if (!this.emailId && !this.mobile) {
              this.register = true;
              this.bsModalRef.hide()
              this.modalService.show(EmailMobileComponent, { backdrop: 'static', class: 'common-modal modal-dialog-centered' })
            }
            if (this.emailId || this.mobile) {
              this.register = true;
              this.registerCallApi(x, true)
            }
          }
        })
      })
    }
  }
  loginCallApi(success, res, type?) {
    if (res != null) {
      this.commonService.callApi(this.apiConstant.LOGIN, { socialId: this.facebookId, emailId: this.emailId, mobile: this.mobile, socialType: 'facebook' }, 'post', true, false).then((response) => {
        if (response.status === this.statusCode.SUCCESS) {
          this.bsModalRef.hide();
          if (response.body.data.isVerified) {
            this.localStorageService.setToken('userId', response.body.data.userId);
            this.localStorageService.setToken('emailId', response.body.data.emailId);
            this.localStorageService.setToken('mobile', response.body.data.mobile);
            if (response.body.data.isRegisterProfileCompleted) {
              this.localStorageService.setToken('accessToken', response.body.extra_meta.token)
              this.localStorageService.setToken('firstName', response.body.data.firstName);
              this.localStorageService.setToken('lastName', response.body.data.lastName);
              this.localStorageService.setToken('myReferralCode', response.body.data.myReferralCode);
              this.localStorageService.setToken('googleAccountLinked', response.body.data.googleAccountLinked);
              this.localStorageService.setToken('connectionPrivacy', response.connectionPrivacy);
              this.localStorageService.setToken('followPrivacy', response.followPrivacy);
              this.localStorageService.setToken('statusPrivacy', response.statusPrivacy);

              if (response.body.data.photo !== undefined) {
                this.localStorageService.setToken('photo', this.imgUrl + response.body.data.photo);
              }

              if (success.body.quickBloxUser) {
                this.qbHelper.setQBLogin(success.body.quickBloxUser);
              }

              this.router.navigate([this.urlConstant.HOME]);
              this.showErrorService.displayToastr('success', this.messages.SUCCESS.LOGIN);
            } else {
              this.localStorageService.setToken('altToken', response.body.extra_meta.token)
              this.modalService.show(ShortProfileComponent, {
                backdrop: 'static', class: '',
                initialState: {
                  socialData: {
                    firstName: success.firstName,
                    lastName: success.lastName,
                    photo: success.photoUrl
                  }
                }
              });
            }
          } else {
            // this.modalService.show(VerifyOtpComponent, {
            //   initialState: {
            //     userId: response.body.data.userId,
            //     verificationCode: response.body.data.verificationCode,
            //     emailIdMobile: response.body.data.emailId ? response.body.data.emailId : response.body.data.mobile,
            //     emailId: response.body.data.emailId,
            //     mobile: response.body.data.mobile,
            //     fromForgotPass: false,
            //     shortProfile: false,
            //     login: true
            //   }
            // });
            this.router.navigate([this.urlConstant.HOME])
            this.showErrorService.displayToastr('success', 'User logged in successfully')
          }
        }
      })
    }
  }

  registerCallApi(success, res?, type?) {
    if (res != null) {
      const params = {
        socialId: success.id,
        emailId: this.emailId,
        socialType: 'facebook',
        mobile: this.mobile,
        hasToVerify: (type == 'email' || type == true) ? true : false
      }
      this.commonService.callApi(this.apiConstant.REGISTER, params, 'post', true, false).then((reg) => {
        if (reg.status === this.statusCode.SUCCESS) {
          if (reg.body.data.isVerified) {
            this.router.navigate([this.urlConstant.HOMEPAGE])
            this.loginCallApi(success, reg.body.data, true)
          } else {
            this.bsModalRef.hide();
            this.register = false;
            this.login = true
            this.modalService.show(VerifyOtpComponent, {
              initialState: {
                userId: reg.body.data.userId,
                verificationCode: reg.body.data.verificationCode,
                emailIdMobile: reg.body.data.emailId ? reg.body.data.emailId : reg.body.data.mobile,
                fromForgotPass: false,
                emailId: reg.body.data.emailId,
                mobile: reg.body.data.mobile,
                shortProfile: false,
                login: true
              }
            });
          }
        }
      })
    }
  }
}
