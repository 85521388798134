<div #domPortalContent class="video-upload-wrap video-dialoguewrap userlist-popup">
  <kendo-window class="visible message-layout" title="My Connections" [resizable]="false" [width]="320" [top]="750"
    [left]="1308">
    <kendo-window-titlebar>
      <div class="popuptitlebar d-flex justify-content-between w-100 align-items-center">
        <div class="flex-shrink-1 flex-grow-1 d-flex align-items-center">
          <div class="imgbox position-relative">
            <img
              [src]="localStorageService.getToken('photo') ? localStorageService.getToken('photo') : '../../../../assets/images/gitfunded.svg'"
              (error)="img1.src = '../../../../assets/images/gitfunded.svg'" #img1 alt="Profile" width="32" height="32">
            <span class="active-dot"></span>
          </div>
          <h6 class="usermsg-titlename">My Connections</h6>
        </div>
        <div class="flex-shrink-0">
          <button class="btn popup-btn" (click)="showList = !showList" *ngIf="showList">
            <img src="/assets/images/up-arrow.svg" alt="Up" width="" height="" />
          </button>
          <span class="icon-Icon-down-arrow-block" (click)="showList = !showList" *ngIf="!showList"></span>
        </div>
      </div>
    </kendo-window-titlebar>
    <div class="msg-list-wrap" *ngIf="showList">
      <ng-container *ngIf="connectionList.length">
        <ul class="all-msg-box">
          <li *ngFor="let connection of connectionList">
            <div class="btn-group msgblock-box w-100" dropdown>
              <div href="javascript:void(0);" id="dropdown-msg" class="d-flex align-items-start chat-btn w-100"
                dropdownToggle aria-controls="dropdown-msg">
                <div class="imgbox">
                  <img [src]="connection.photo ? imgUrl + connection.photo : '../../../../assets/images/gitfunded.svg'"
                    (error)="img1.src = '../../../../assets/images/gitfunded.svg'" #img1 alt="" width="40" height="40">
                  <!-- <em class="active-dot"></em> -->
                </div>
                <div class="msg-info">
                  <h6 class="text-ellipsis-2line">{{connection.firstName}}
                    {{connection.lastName}}</h6>
                </div>
              </div>
              <div id="dropdown-msg" *dropdownMenu class="dropdown-menu card-msg-nav-dropdown dropdown-menu-right"
                role="menu" aria-labelledby="my-msg-dropdown">
                <a href="javascript:void(0);" (click)="selectChatIdentity(true, connection)">
                  <span class="icon-icon-msg"></span> Send Message Invite
                </a>
                <a href="javascript:void(0);" (click)="selectChatIdentity(false, connection)">
                  <span class="icon-icon-msg"></span> Start Conversation Now
                </a>
              </div>
            </div>
          </li>
        </ul>
        <div #loadingConnectionList></div>
      </ng-container>
      <ng-container *ngIf="!connectionList.length">
        <div class="card text-center no-data-card">
          <img src="../../../../assets/images/no-data.svg" alt="no-data" width="100" height="">
          <h6 class="mt-3">{{messages.NO_DATA.CONNECTIONS}}
          </h6>
        </div>
      </ng-container>
    </div>
  </kendo-window>
</div>