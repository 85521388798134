import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackhomeService {

  constructor() { }

  public data: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public submitted: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  // public milestone: BehaviorSubject<string> = new BehaviorSubject<string>(null);


  setOption(value) {
    this.data.next(value)
  }

  getOption(): Observable<string> {
    return this.data.asObservable();
  }

  setSubmitted(value) {
    this.submitted.next(value);
  }
  getSubmitted(): Observable<string> {
    return this.submitted.asObservable();
  }

}
