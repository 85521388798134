import { CustomValidationService } from 'src/app/common/services/service/custom-validation.service';
import { VideoProcessingService } from './../../../common/services/service/video-processing.service';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/common/services/service/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { statusCode } from 'src/app/common/constants/constant';
import { messages } from 'src/app/common/constants/messages';
import { ImageCropperComponent } from 'src/app/shared/modals/image-cropper/image-cropper.component';
import { QBHelper } from 'src/app/common/services/TS-files/qbHelper';

@Component({
  selector: 'app-short-profile',
  templateUrl: './short-profile.component.html',
  styles: []
})
export class ShortProfileComponent implements OnInit {

  apiConstant = APIConstants;
  urlConstant = URLConstants;
  statusCode = statusCode;
  messages = messages;
  imgUrl = environment.imgUrl;

  profileForm: FormGroup;
  submitted = false;

  file: any;
  sliceSize: any;
  socialData;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private router: Router,
    private commonService: CommonService,
    private modalService: BsModalService,
    private localStorageService: LocalStorageService,
    private videoService: VideoProcessingService,
    private customValidationService: CustomValidationService,
    private qbHelper: QBHelper
  ) { }

  ngOnInit(): void {
    this.createForm();
    if (this.socialData) {
      this.fetchSocialDetail();
    }
  }

  createForm() {
    this.profileForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern(/^[a-zA-Z]{2,20}$/), this.customValidationService.noWhitespaceValidator()]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern(/^[a-zA-Z]{2,20}$/), this.customValidationService.noWhitespaceValidator()]],
      photo: [],
      photoSize: []
    });
  }

  async fetchSocialDetail() {
    const self = this;
    this.profileForm.patchValue({
      firstName: self.socialData.firstName,
      lastName: self.socialData.lastName
    })
    if (this.socialData.photo) {
      let response = await fetch(this.socialData.photo);
      let fileblob = await response.blob();
      this.videoService.submitFile(fileblob, false).then((result) => {
        document.getElementById('imagePreview').setAttribute('src', `${this.imgUrl}${result.body.data.filePath}`);
        this.profileForm.patchValue({
          photo: result.body.data.filePath,
          photoSize: result.body.data.size
        })
      });
    }
  }

  onSubmit() {
    if (this.profileForm.valid) {
      const data = this.profileForm.value;
      this.commonService.callApi(this.apiConstant.UPDATE_SHORT_PROFILE, data, 'post', false, false, false).then((success) => {
        if (success.status === this.statusCode.SUCCESS) {
          const response = success.body.data;
          this.localStorageService.setToken('accessToken', this.localStorageService.getToken('altToken'));
          this.localStorageService.removeToken('altToken');
          this.localStorageService.setToken('userId', response.userId);
          this.localStorageService.setToken('firstName', response.firstName);
          this.localStorageService.setToken('lastName', response.lastName);
          this.localStorageService.setToken('emailId', response.emailId);
          this.localStorageService.setToken('mobile', response.mobile);
          this.localStorageService.setToken('hasPassword', response.hasPassword);
          this.localStorageService.setToken('myReferralCode', response.myReferralCode);
          this.localStorageService.setToken('googleAccountLinked', response.googleAccountLinked);
          this.localStorageService.setToken('connectionPrivacy', response.connectionPrivacy);
          this.localStorageService.setToken('followPrivacy', response.followPrivacy);
          this.localStorageService.setToken('statusPrivacy', response.statusPrivacy);
          if (response.photo !== undefined) {
            this.localStorageService.setToken('photo', this.imgUrl + response.photo);
          }
          this.bsModalRef.hide();
          this.router.navigate([this.urlConstant.HOME]);
        }
      });
    } else {
      this.submitted = true;
    }
  }

  openFile(event) {
    event.preventDefault();
    const element = document.getElementById('profile');
    element.click();
  }

  fileChangeEvent(event) {
    this.openImageCropper(event);
  }

  /******************* open modal to crop image ************************/
  openImageCropper(event) {
    const modalRef = this.modalService.show(ImageCropperComponent,
      { initialState: { imageChangedEvent: event }, class: 'introduction-wrap modal-dialog-centered' });
    modalRef.content.event.subscribe((file) => {
      if (file) {
        this.videoService.submitFile(file, false).then((result) => {
          document.getElementById('imagePreview').setAttribute('src', `${this.imgUrl}${result.body.data.filePath}`);
          this.profileForm.controls.photo.setValue(result.body.data.filePath);
          this.profileForm.controls.photoSize.setValue(result.body.data.size);
        });
      }
    });
  }
  /**********************************************************************/

}
