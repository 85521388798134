<div class="save-changes-notification">
    <div class="modal-card sm-modal-card">
        <button class="popup-close-btn btn" (click)="modalRef.hide()">
            <span class="icon-icon-close"></span>
        </button>
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
            [imageQuality]="92" [resizeToWidth]="600" format="png" [containWithinAspectRatio]="true" [backgroundColor] = "'white'"
            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()">
        </image-cropper>
        <form>
            <div class="btnbox justify-content-between">
                <button type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
            </div>
        </form>
    </div>
</div>