import { OneTimePackagesComponent } from './components/one-time-packages/one-time-packages.component';
import { VideoUploadProgressComponent } from './modals/video-upload-progress/video-upload-progress..component';
import { VideoPopupComponent } from './modals/video-popup/video-popup.component';
import { SendinviteComponent } from './modals/sendinvite/sendinvite.component';
import { SelectIdentityComponent } from 'src/app/shared/modals/select-identity/select-identity.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ArchwizardModule } from 'angular-archwizard';
// Plugins

import { NgSelectModule } from '@ng-select/ng-select';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AccordionModule } from 'ngx-bootstrap/accordion';

// Component
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { FilterComponent } from './components/filter/filter.component';
import { ProfileSidebarComponent } from './components/profile-sidebar/profile-sidebar.component';
import { SocialSignupComponent } from '../public/social-signup/social-signup.component';
import { VideoListComponent } from './components/video-list/video-list.component';
import { PagesListComponent } from './components/pages-list/pages-list.component';
import { NetworkListComponent } from './components/network-list/network-list.component';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { DiscardSavePageComponent } from './modals/discard-save-page/discard-save-page.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { StorageSizePipe } from './pipes/storage-size.pipe';
import { TeamMemberListComponent } from './components/team-member-list/team-member-list.component';
import { CoverVideoPopupComponent } from './modals/cover-video-popup/cover-video-popup.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { NgxCurrencyModule } from 'ngx-currency';
// @videogular/ngx-videogular
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { SelectParticipantComponent } from './modals/select-participant/select-participant.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ReportComponent } from './modals/report/report.component';
import { FollowersListComponent } from './modals/followers-list/followers-list.component';
import { TeamMemberReqListComponent } from './components/team-member-req-list/team-member-req-list.component';
import { StickyMenuDirective } from './directives/sticky-menu.directive';
import { ImageCropperComponent } from './modals/image-cropper/image-cropper.component';
import { SelectFavCollectionComponent } from './modals/select-fav-collection/select-fav-collection.component';
import { CreateFolderComponent } from './modals/create-folder/create-folder.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ViewPdfComponent } from './modals/view-pdf/view-pdf.component';
import { DateAgoPipe } from './pipes/date-ago.pipe'
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { TimeDatePipe } from './pipes/time-date.pipe';
import { NgxStripeModule } from 'ngx-stripe';
import { InsuffiecientWarnComponent } from './modals/insuffiecient-warn/insuffiecient-warn.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CommonMessageConnectionComponent } from './components/common-message-connection/common-message-connection.component';
import { CommonMessageDialogComponent } from './components/common-message-dialog/common-message-dialog.component';
import { CommonMessageChatComponent } from './components/common-message-chat/common-message-chat.component';

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    showWeekNumbers: false,
    customTodayClass: 'default-date'
  });
}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ProfileSidebarComponent,
    FilterComponent,
    SelectIdentityComponent,
    VideoPopupComponent,
    SendinviteComponent,
    CreateFolderComponent,
    SocialSignupComponent,
    VideoListComponent,
    PagesListComponent,
    NetworkListComponent,
    DiscardSavePageComponent,
    ShortNumberPipe,
    StorageSizePipe,
    TeamMemberListComponent,
    CoverVideoPopupComponent,
    VideoPlayerComponent,
    SelectParticipantComponent,
    ReportComponent,
    FollowersListComponent,
    TeamMemberReqListComponent,
    StickyMenuDirective,
    ImageCropperComponent,
    SelectFavCollectionComponent,
    ViewPdfComponent,
    DateAgoPipe,
    TimeDatePipe,
    VideoUploadProgressComponent,
    OneTimePackagesComponent,
    InsuffiecientWarnComponent,
    CommonMessageConnectionComponent,
    CommonMessageDialogComponent,
    CommonMessageChatComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    NgSelectModule,
    NgOtpInputModule,
    ArchwizardModule,
    ModalModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    NgCircleProgressModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    UiSwitchModule,
    ToastrModule.forRoot({ timeOut: 2000, extendedTimeOut: 2000 }),
    NgxBootstrapSliderModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    GooglePlaceModule,
    PopoverModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AccordionModule.forRoot(),
    ImageCropperModule,
    CreditCardDirectivesModule,
    ShareModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    NgxCurrencyModule.forRoot({ prefix: '', precision: 0, align: 'left', thousands: ',', nullable: true, allowNegative: false, allowZero: false, decimal: '', suffix: '' }),
    ButtonsModule,
    DialogsModule,
    SlickCarouselModule
    // NgxStripeModule.forRoot('pk_test_51IuaK5SGMjn8SZhVc632rvVF5FaaJY8uPCK80lftd9diw0W4L9r2dERRMgU2U0PQxmtBSjBS1sWzitBxnAAvFBTg000AGDaTJa'),
  ],
  exports: [
    // Modules
    ArchwizardModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RatingModule,
    TabsModule,
    BsDropdownModule,
    NgOtpInputModule,
    TooltipModule,
    NgCircleProgressModule,
    BsDatepickerModule,
    TimepickerModule,
    ProgressbarModule,
    ToastrModule,
    UiSwitchModule,
    NgxBootstrapSliderModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    GooglePlaceModule,
    PopoverModule,
    CalendarModule,
    ImageCropperModule,
    CreditCardDirectivesModule,
    AccordionModule,
    NgxCurrencyModule,
    ButtonsModule,
    DialogsModule,
    PortalModule,
    OverlayModule,
    ShareModule,
    ShareButtonsModule,
    ShareIconsModule,
    SlickCarouselModule,
    // Components
    HeaderComponent,
    FooterComponent,
    ProfileSidebarComponent,
    FilterComponent,
    SelectIdentityComponent,
    VideoPopupComponent,
    CoverVideoPopupComponent,
    SendinviteComponent,
    CreateFolderComponent,
    SocialSignupComponent,
    DiscardSavePageComponent,
    VideoListComponent,
    PagesListComponent,
    NetworkListComponent,
    TeamMemberListComponent,
    VideoPlayerComponent,
    SelectParticipantComponent,
    FollowersListComponent,
    TeamMemberReqListComponent,
    SelectFavCollectionComponent,
    VideoUploadProgressComponent,
    OneTimePackagesComponent,
    InsuffiecientWarnComponent,
    CommonMessageConnectionComponent,
    CommonMessageDialogComponent,
    CommonMessageChatComponent,

    // Pipe
    ShortNumberPipe,
    StorageSizePipe,
    DateAgoPipe,
    TimeDatePipe,

    // Directive
    StickyMenuDirective
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    BsModalRef,
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
    DatePipe
  ],
})
export class SharedModule { }

