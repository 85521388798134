import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

// Constants
import { URLConstants } from '../../constants/routerLink-constants';
import { MetaConstants } from '../../constants/meta-data-constants';

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {
  URLConstants = URLConstants;
  MetaConstants = MetaConstants;

  constructor(private title: Title, private meta: Meta) { }


  /***********************************************************************
  @PURPOSE      : Common Function to Set Meta Title, Keywords & Description
  @PARAMETERS   : metaTitle, metaKeywords, metaDescription
  @RETURN       : NA
  /***********************************************************************/
  setMeta(metaTitle, metaKeywords, metaDescription) {
    this.title.setTitle(metaTitle);
    this.meta.updateTag({ name: 'keywords', content: metaKeywords });
    this.meta.updateTag({ name: 'description', content: metaDescription });
  }
  /******************************************************************/
}
