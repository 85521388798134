import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SharedServices {

    public $files = new BehaviorSubject<any>([])
    public $openmodel = new BehaviorSubject<boolean>(false)
    public $fileproceed = new BehaviorSubject<any>({})
    public $newfile = new BehaviorSubject<any>({})
    public $uploadProgress = new BehaviorSubject<any>({})

    constructor(private router: Router) { }

    setModel(value: boolean): void {
        this.$openmodel.next(value);
    }

    getModel(): Observable<any> {
        return this.$openmodel.asObservable();
    }


    setNewFile(value: any): void {
        this.$newfile.next(value);
    }

    getNewFile(): Observable<any> {
        return this.$newfile.asObservable();
    }

    setFiles(value: any): void {
        this.$files.next(value);
    }

    getFiles(): Observable<any> {
        return this.$files.asObservable();
    }

    setFilePro(value: any): void {
        this.$fileproceed.next(value);
    }

    getFilePro(): Observable<any> {
        return this.$fileproceed.asObservable();
    }

    setUploadProgress(value: any): void {
        this.$uploadProgress.next(value);
    }

    getUploadProgress(): Observable<any> {
        return this.$uploadProgress.asObservable();
    }


    setStore(type: string, payload: any): void {
        switch (type) {
            case "OPEN_MODEL":
                this.$openmodel.next(true);
                return;
            case "CLOSE_MODEL":
                this.$openmodel.next(false);
                return;
            case "NEW_FILE_UPLOAD_REQUEST":
                this.$newfile.next(payload);
                return;
            case "FILE_UPLOAD_PROCEED":
                this.$fileproceed.next(payload);
                return;
            case "FILE_UPLOAD":
                this.$files.next(payload);
                return;
            case "FILE_UPLOAD_SUCCESS":
                this.$files.next(payload);
                return;
            case "FILE_UPLOAD_FAIL":
                this.$files.next(payload);
                return;
            case "FILE_UPLOAD_CANCEL":
                this.$files.next(payload);
                return;
            case "FILE_UPLOAD_CANCEL_ALL":
                this.$files.next([]);
                this.$newfile.next({});
                this.$fileproceed.next({});
                return;
            case "FILE_UPLOAD_DONE":
                this.$files.next([]);
                this.$newfile.next({});
                this.$fileproceed.next({});
                return;
            default:
                return;
        }
    }

    getStore(type: string): Observable<any> {
        switch (type) {
            case "MODEL":
                return this.$openmodel.asObservable();
            case "NEW_FILE":
                return this.$newfile.asObservable();
            case "FILE_PROCEED":
                return this.$fileproceed.asObservable();
            case "FILES":
                return this.$files.asObservable();
            default:
                return this.$openmodel.asObservable();
        }
    }

}
