<div #domPortalContent class="video-upload-wrap video-dialoguewrap singlechat-popup" *ngIf="showDialog">
    <kendo-window class="visible" [title]="selectedChatOverview?.toIdentity.name" [resizable]="false" [width]="315"
        [top]="550" [left]="1108">
        <kendo-window-titlebar>
            <div class="popuptitlebar d-flex justify-content-between w-100 align-items-center">
                <div class="flex-shrink-1 flex-grow-1 d-flex align-items-center">
                    <div class="imgbox position-relative">
                        <img [src]="selectedChatOverview?.toIdentity.photo ? imgUrl + selectedChatOverview?.toIdentity.photo : '../../../../assets/images/gitfunded.svg'"
                            (error)="img1.src = '../../../../assets/images/gitfunded.svg'" #img1
                            [alt]="selectedChatOverview?.toIdentity.name" width="32" height="32">
                        <span class="active-dot" *ngIf="dialog?.onlineStatus"></span>
                    </div><span class="usermsg-titlename">
                        <a (click)="gotoUserDetail()">
                            {{selectedChatOverview?.toIdentity.name}}
                        </a>
                        <ng-container *ngIf="dialog?.data.type == msgType.job">
                            <span> &nbsp; • &nbsp; </span>
                            <a (click)="goToJobDetail()">
                                {{dialog?.data.jobTitle}}
                            </a>
                        </ng-container>
                    </span>
                </div>
                <div class="flex-shrink-0">
                    <button kendoWindowCloseAction class="btn popup-btn" (click)="close()"></button>
                </div>
            </div>
        </kendo-window-titlebar>
        <div class="new-contact-notification"
            *ngIf="(!(selectedChatOverview.connectionData.status == this.msgStatus.accepted) || ((selectedChatOverview.connectionData.status == this.msgStatus.accepted) && (selectedChatOverview.blockData)))">
            <div class="text-center">
                <div class="imgbox">
                    <img [src]="selectedChatOverview.toIdentity.photo ? imgUrl + selectedChatOverview.toIdentity.photo : '../../../../assets/images/gitfunded.svg'"
                        (error)="img2.src = '../../../../assets/images/gitfunded.svg'" #img2
                        [alt]="selectedChatOverview.toIdentity.name" width="120" height="120">
                </div>
                <h5>{{selectedChatOverview.toIdentity.name}}</h5>
            </div>
            <span class="primary-text"><strong>{{selectedChatOverview.toIdentity.type}}</strong></span>
            <ng-container *ngIf="!selectedChatOverview.blockData">
                <ng-container
                    *ngIf="(selectedChatOverview.connectionData.fromIdentityId !== selectedChatOverview.fromIdentity.identityId) && (selectedChatOverview.connectionData.status == msgStatus.pending)">
                    <div class=" spacer-line">
                    </div>
                    <p class="text-center">Wants to connect with</p>
                    <div class="media justify-content-center">
                        <div class="imgbox mr-2">
                            <img [src]="selectedChatOverview.fromIdentity.photo ? imgUrl + selectedChatOverview.fromIdentity.photo : '../../../../assets/images/gitfunded.svg'"
                                (error)="img3.src = '../../../../assets/images/gitfunded.svg'" #img3
                                [alt]="selectedChatOverview.fromIdentity.name" width="26" height="26">
                        </div>
                        <div class="name">
                            <h6 class="primary-text">{{selectedChatOverview.fromIdentity.name}}</h6>
                        </div>
                    </div>
                    <div class="btnbox d-flex justify-content-center mt-1">
                        <button type="submit" class="btn btn-dark mw-114 btn-sm m-1"
                            (click)="acceptReject(msgStatus.rejected)">
                            <span class="icon-icon-close-black mr-1"></span> Reject
                        </button>
                        <button type="submit" class="btn btn-primary mw-114 btn-sm m-1"
                            (click)="acceptReject(msgStatus.accepted)">
                            <span class="icon-icon-tick mr-1"></span> Accept
                        </button>
                    </div>
                    <div class="text-center">
                        <button class="btn text-danger block-btn p-0 btn-sm mt-2 mx-auto" (click)="block('block')">Block
                            this user</button>
                    </div>
                </ng-container>
                <ng-container
                    *ngIf="(selectedChatOverview.connectionData.fromIdentityId == selectedChatOverview.fromIdentity.identityId) && (selectedChatOverview.connectionData.status == msgStatus.pending)">
                    <div class="spacer-line"></div>
                    <h6 class="text-center">Waiting for {{selectedChatOverview.toIdentity.name}} to accept chat
                        invitation.</h6>
                    <ng-container *ngIf="!subscriptionFeatures.features.chatCredits">
                        <p class="text-center">You have used all your direct message credits. Click below to buy
                            additional direct message
                            credits.</p>
                        <div class="btnbox mb-2  text-center">
                            <button (click)="buy()" type="submit" class="btn btn-primary btn-sm">
                                Buy
                            </button>
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="((subscriptionFeatures.subscriptionPackage == subscriptionType.freemiumName) || (subscriptionFeatures.subscriptionPackage == subscriptionType.startupName)) && (!subscriptionFeatures.features.chatCredits)">
                        <p class="text-center">Please wait or upgrade your plan to start conversation immediately</p>
                        <div class="btnbox mb-2 text-center">
                            <button (click)="upgrade()" type="submit" class="btn btn-primary btn-sm">
                                Upgrade
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedChatOverview.blockData">
                <ng-container
                    *ngIf="(selectedChatOverview.blockData.fromIdentityId == selectedChatOverview.fromIdentity.identityId)">
                    <div class="spacer-line"></div>
                    <h6 class="text-center">You have blocked {{selectedChatOverview.toIdentity.name}}</h6>
                    <div class="btnbox mb-2 text-center">
                        <button type="submit" class="btn btn-primary btn-sm" (click)="block('unblock')">
                            Unblock
                        </button>
                    </div>
                </ng-container>
                <ng-container
                    *ngIf="(selectedChatOverview.blockData.fromIdentityId != selectedChatOverview.fromIdentity.identityId)">
                    <div class="spacer-line"></div>
                    <h6 class="text-center">You are blocked by {{selectedChatOverview.toIdentity.name}}</h6>
                </ng-container>
            </ng-container>
        </div>
        <div class="msg-chatbox allmessage-receivedbx" (scroll)="loadMoreMessages($event)">
            <ng-container *ngFor="let data of messages">
                <div class="msg-receive-time-outer"><span class="msg-receive-status">{{data[0]}}</span></div>
                <app-common-message-chat *ngFor="let message of data[1];" [dialog]="dialog" [message]="message"
                    [selectedChatOverview]="selectedChatOverview" [id]="message._id">
                </app-common-message-chat>
            </ng-container>
        </div>
        <div class="msg-upload-icons" *ngIf="attachments.length">
            <ul>
                <li *ngFor="let data of attachments">
                    <div *ngIf="data.type == qbConst.ATTACHMENT.IMG_TYPE">
                        <img [src]="data.src ? data.src : '../../assets/images/gitfunded.png'"
                            (error)="img.src = '../../assets/images/gitfunded.png'" #img alt="Logo" width="90"
                            height="90" />
                    </div>
                    <div class="play-icon-btn" *ngIf="data.type == qbConst.ATTACHMENT.VIDEO_TYPE">
                        <img [src]="data.thumbnail ? data.thumbnail : '../../assets/images/gitfunded.png'"
                            (error)="img1.src = '../../assets/images/gitfunded.png'" #img1 alt="Logo" width="90"
                            height="90" />
                        <button type="submit" class="btn p-0 video-btn-play">
                            <span class="icon-icon-play"></span>
                        </button>
                    </div>
                    <div *ngIf="data.type == qbConst.ATTACHMENT.PDF_TYPE">
                        <span class="icon-icon-pdf"></span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="received-msg-card cardpopup-footer">
            <div class="msg-type-input-outer"
                *ngIf="(!selectedChatOverview.blockData)&&((selectedChatOverview.connectionData.status == this.msgStatus.accepted) || ((selectedChatOverview.connectionData.fromIdentityId == selectedChatOverview.fromIdentity.identityId) && (selectedChatOverview.connectionData.status == msgStatus.pending) && !messages.length))">
                <form (ngSubmit)="onSubmit()" autocomplete="off" class="send_message" name="send_message">
                    <div class="msg-type-input-wrap">
                        <button class="btn add-btn p-0" (click)="openFile($event)"><span
                                class="icon-icon-plush"></span></button>
                        <input [hidden]="true" class="attach_input" id="attach_btn" (change)="prepareToUpload($event)"
                            name="attach_file" type="file" (click)="$event.target.value=null"
                            accept=".png, .jpg, .jpeg, .pdf, video/mp4" />
                        <textarea #field autocapitalize="off" autocomplete="off" autocorrect="off" class="message_feald"
                            [(ngModel)]="messageField" (keydown)="onKeydown($event)" name="message_feald" placeholder="Write a message..."></textarea>
                        <button type="submit" class="btn send-msg-btn btn-primary"><span
                                class="icon-icon-send-msg"></span></button>
                    </div>
                </form>
            </div>
        </div>
    </kendo-window>

</div>