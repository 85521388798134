import { cmsType, statusCode } from './../../../common/constants/constant';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { CommonService } from 'src/app/common/services/service/common.service';
import { APIConstants } from './../../../common/constants/callAPI-constants';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VerifyOtpComponent } from '../verify-otp/verify-otp.component';
import { LoginComponent } from '../login/login.component';
import { messages } from 'src/app/common/constants/messages';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidationService } from 'src/app/common/services/service/custom-validation.service';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: []
})

export class RegisterComponent implements OnInit {

  apiConstant = APIConstants;
  statusCode = statusCode;
  messages = messages;
  urlConstant = URLConstants;
  cmsType = cmsType;

  registerForm: FormGroup;
  submitted = false;
  passwordType: boolean;
  mobile: any;
  emailId: any;
  linkedInToken: any;
  accepted = false;
  tcError = false;
  referCode;

  constructor(
    private commonService: CommonService,
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private customValidator: CustomValidationService) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.registerForm = this.fb.group({
      emailId: ['', [Validators.required, Validators.maxLength(50), this.customValidator.validateEmailMobile()]],
      password: ['', [Validators.compose([Validators.required, Validators.maxLength(16), this.customValidator.passwordPatternValidator()])]],
      referralCode: [this.referCode ? this.referCode : '']
    });
  }

  toggleFieldTextType() {
    this.passwordType = !this.passwordType;
  }

  /*************************************************************
  @Purpose     : Validate on Submit and if valid then call login
  /*************************************************************/
  onSubmit() {
    if (this.registerForm.valid) {
      if (this.accepted) {
        this.mobile = this.customValidator.validateMobile(this.registerForm.controls.emailId.value) ?
          this.registerForm.controls.emailId.value : '';
        this.emailId = this.customValidator.validateEmail(this.registerForm.controls.emailId.value) ?
          this.registerForm.controls.emailId.value : '';
        const data = {
          emailId: this.emailId,
          mobile: this.mobile,
          password: this.registerForm.controls.password.value,
          referralCode: this.registerForm.controls.referralCode.value,
          hasToVerify: true
        };
        this.register(data);
      } else {
        this.tcError = true;
      }
    } else {
      this.submitted = true;
    }
  }




  /*************************************************************
  @Purpose     : Register
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  register(data) {
    this.commonService.callApi(this.apiConstant.REGISTER, data, 'post', true, false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        const response = success.body.data;
        if (response.isVerified) {

        } else {
          this.bsModalRef.hide();
          this.modalService.show(VerifyOtpComponent, {
            backdrop: 'static',
            initialState: {
              userId: response.userId,
              verificationCode: response.verificationCode,
              emailIdMobile: response.emailId ? response.emailId : response.mobile,
              fromForgotPass: false,
              shortProfile: true
            }
          });
        }
      }
    });
  }
  /*************************************************************/

  login() {
    this.bsModalRef.hide();
    this.modalService.show(LoginComponent, { backdrop: 'static', initialState: {}, class: 'common-modal login-modal login-signup-wrap modal-dialog-centered' })
  }

}
