import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { ShortProfileComponent } from './../short-profile/short-profile.component';
import { messages } from './../../../common/constants/messages';
import { statusCode } from './../../../common/constants/constant';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { APIConstants } from './../../../common/constants/callAPI-constants';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { CommonService } from 'src/app/common/services/service/common.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { QBHelper } from 'src/app/common/services/TS-files/qbHelper';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styles: []
})
export class VerifyOtpComponent implements OnInit {

  apiConstant = APIConstants;
  urlConstant = URLConstants;
  statusCode = statusCode;
  messages = messages;
  userId;
  emailIdMobile;
  verificationCode;
  shortProfile;
  mobile;
  emailId;
  showMessage = false;
  otp: any = 0;
  login;
  register;
  errorOtp = false;
  timeLeft = 60;
  showTimer = true;

  // to check where to navigate based on previous navigation
  fromForgotPass: boolean;

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: true,
    disableAutoFocus: false,
    placeholder: ''
  };

  constructor(
    private commonService: CommonService,
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private qbHelper: QBHelper
  ) { }

  ngOnInit(): void {
    this.setTimer();
  }

  /*************************************************************
  @Purpose     : Verify OTP
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  verifyOtp() {
    if (this.otp.length === 6) {
      if (this.otp === this.verificationCode) {
        const data = {
          userId: this.userId,
          verificationCode: this.otp
        };
        this.commonService.callApi(this.apiConstant.VERIFY_OTP, data, 'post', true, false).then((success) => {
          if (success.status === this.statusCode.SUCCESS) {
            this.showMessage = true;
            this.bsModalRef.hide();
            if (this.fromForgotPass) {
              this.router.navigate([this.urlConstant.RESET_PASSWORD, success.body.data.userId]);
            } else {
              this.localStorageService.setToken('altToken', success.body.extra_meta.token);   // this is for short profile
              if (this.register) {

              }
              if (this.login) {
                this.modalService.onHidden.emit({ emailId: this.emailId, mobile: this.mobile })
              }
              if (this.shortProfile)
                if (success.body.quickBloxUser) {
                  this.qbHelper.setQBLogin(success.body.quickBloxUser);
                }
              this.modalService.show(ShortProfileComponent, { backdrop: 'static', class: '' });
            }
          }
        });
      } else {
        this.showErrorService.displayToastr('error', this.messages.ERROR.OTHERS.INVALID_OTP);
      }
    } else {
      this.errorOtp = true;
    }
  }
  /*************************************************************/

  /*************************************************************
  @Purpose     : Resend OTP
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  resendOtp() {
    const data = {
      userId: this.userId
    };

    this.commonService.callApi(this.apiConstant.RESEND_OTP, data, 'post', true, false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        this.verificationCode = success.body.data.verificationCode;
        this.ngOtpInput.setValue(null);
        this.showErrorService.popToast('success', this.messages.SUCCESS.RESEND_OTP);
        this.timeLeft = 60;
        this.showTimer = true;
        this.setTimer();
      }
    });
  }
  /*************************************************************/

  onOtpChange(otp) {
    this.otp = otp;
  }

  setTimer() {
    setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.showTimer = false;
      }
    }, 1000);
  }
  closeVerifyOtp() {
    this.bsModalRef.hide();
    if (this.register || this.login) {
      this.router.navigate([this.urlConstant.HOMEPAGE]);
    }
  }
}
