export const URLConstants = {
    HOMEPAGE: '/home-page',
    CMS: '/cms',
    RESET_PASSWORD: '/reset-password/',
    REFERRAL: '/home-page/referral',

    HOME: '/home',

    MY_VIDEOS: '/videos',
    CREATE_POST: '/videos/create-post',
    VIDEO_DETAILS: '/videos/video-details',

    MY_PROFILE: '/profile/',
    ADD_PROFILE: '/profile/add-profile',
    FAV_DETAILS: '/profile/fav-details',
    CONN_REQ_LIST: '/profile/connection',
    INTEREST_LIST: '/profile/interest',

    MY_PAGES: '/pages',
    SELECT_PAGE: '/pages/select-page',
    PAGE_SUCCESS: '/pages/page-success',
    INVESTOR: '/pages/investor',
    PERSONAL_INVESTOR: '/pages/investor/personal',
    COMPANY_INVESTOR: '/pages/investor/company',
    OTHER: '/pages/investor/other',
    STARTUP: '/pages/startup',
    SP: '/pages/service-provider',
    PERSONAL_SP: '/pages/service-provider/personal',
    COMPANY_SP: '/pages/service-provider/company',

    INVESTOR_LIST: '/investor',
    INVESTOR_DETAIL: '/investor/details',
    SP_LIST: '/service-provider',
    SP_DETAILS: '/service-provider/details',
    INV_OPP_LIST: '/investment-opportunity',
    INV_OPP_DETAILS: '/investment-opportunity/details',
    PEOPLE_LIST: '/network',
    PEOPLE_DETAILS: '/network/details',

    MEETING_LIST: '/meeting',
    MEETING_DETAILS: '/meeting/details',
    CREATE_MEETING: '/meeting/create',

    JOB_LIST: '/jobs/jobs-list/',
    BROWSE_JOBS: '/jobs/browse-jobs/list/',
    BROWSE_JOB_DETAILS: '/jobs/browse-jobs/details',
    SETUP_MILESTONES: '/jobs/browse-jobs/setup-milestones',
    JOB_POSTINGS: '/jobs/job-postings/list/',
    JOB_POSTING_DETAILS: '/jobs/job-postings/details',
    ONGOING_JOBS: '/jobs/ongoing-jobs/list/',
    PAST_JOBS: '/jobs/past-jobs/list/',
    JOB_INVITES: '/jobs/job-invites/list/',
    MY_PROPOSAL: '/jobs/my-proposal/list/',
    DISPUTES: '/jobs/disputes/list/',
    DISPUTES_DETAIL: '/jobs/disputes-detail/',
    CREATE_JOB: '/jobs/create-job',
    JOB_POSTING_VIDEO_DETAILS: '/jobs/jobs-posting-video-details',

    DOCUMENT_LIST: '/documents/list',
    MY_DOCUMENTS: '/documents/my-documents',
    SHARE_WITH_ME: '/documents/share-with-me',

    MY_MESSAGES: '/messages',

    NOTIFICATION_LIST: '/notifications',

    SETTINGS: '/settings',
    SUBSCRIPTION_SETTING: '/settings/subscription',
    ADD_ON: '/settings/addOnPackage',
    COMPARE_PLANS: '/settings/compare-plan',
    CREDITS_SETTING: '/settings/credits',
    PRIVACY: '/settings/privacy',
    CREDIT_CARD: '/settings/credit-cards',
    BANK_ACCOUNT: '/settings/bank-account',
    EMAIL_ID_SETTING: '/settings/email-id',
    MOBILE_NO_SETTING: '/settings/mobile-no',
    VERIFY_OTP_SETTING: '/settings/verify-otp',
    PASSWORD_SETTING: '/settings/password',
    VERIFY_PAGE: '/settings/verify-page',
    VERIFY_ACCREDITATION: '/settings/verify-accreditation',
    REFER_AND_EARN: '/settings/refer-and-earn',
    IDENTITY_BLOCK_LIST: '/settings/identity-block-list',
    BLOCK_LIST: '/settings/block-list',
    CLOSE_ACCOUNT: '/settings/close-account'

};
