import { msgType } from './../../../common/constants/constant';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { environment } from 'src/environments/environment';
import { identityType, statusCode } from 'src/app/common/constants/constant';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoginComponent } from 'src/app/public/modal/login/login.component';
import { RegisterComponent } from 'src/app/public/modal/register/register.component';

// Constants
import { URLConstants } from '../../../common/constants/routerLink-constants';

// Service
import { LocalStorageService } from '../../../common/services/service/local-storage.service';
import { CommonService } from 'src/app/common/services/service/common.service';
import { LogoutService } from 'src/app/common/services/service/logout.service';
import { DiscardSavePageComponent } from '../../modals/discard-save-page/discard-save-page.component';
import { BackhomeService } from 'src/app/common/services/service/backhome.service';
import { CoverVideoPopupComponent } from '../../modals/cover-video-popup/cover-video-popup.component';
import { VideoPopupComponent } from '../../modals/video-popup/video-popup.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as moment from 'moment';
import { DialogService } from 'src/app/common/services/service/dialog.service';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styles: [],
})
export class HeaderComponent implements OnInit {

	@Input() publicHeader;
	@Input() linkedinHeader;
	@Input() authHeader;
	@Input() authSubHeader;

	@ViewChild('pop2') pop2;
	@ViewChild('pop1') pop1;
	@ViewChild('dropdown') dropdown: BsDropdownDirective;
	public moment: any = moment;
	urlConstant = URLConstants;
	apiConstant = APIConstants;
	statusCode = statusCode;
	imgUrl = environment.imgUrl;
	identityType = identityType;
	msgType = msgType;
	name;
	storeFormData: any = {};
	showSidebar = false;
	temp: any = {};
	profile: any = {};
	searchText;
	searchList = [];
	isbeingSearched: boolean = false;
	@ViewChild('searchEl') searchEl: NgSelectComponent;
	@ViewChild('searchElMob') searchElMob: NgSelectComponent;
	public home: boolean = false;
	public pages: boolean = false;
	public notificationList: Array<any> = [];
	public recenttemplateValue: Array<any> = [];
	public notificationRoute;
	public chats: Array<any> = [];

	constructor(
		private router: Router,
		public localStorageService: LocalStorageService,
		private modalService: BsModalService,
		public commonService: CommonService,
		private logoutService: LogoutService,
		public homeService: BackhomeService,
		public activatedRoute: ActivatedRoute,
		public dialogService: DialogService,
		private showErrorService: ShowErrorService
	) {
		this.commonService.loginEvent.subscribe((res) => {
			if (res) {
				this.getChatList();
				this.updateChatList();
			}
		});
	}

	ngOnInit() {
		if (this.localStorageService.getToken('accessToken')) {
			this.getProfile();
			this.getChatList();
			this.updateChatList();
			this.commonService.getNotificationList();
		}
	}

	ngDoCheck() {
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			} else {
				const route = evt.url;
				this.notificationRoute = route;
				if (route == '/profile/add-profile') {
					this.home = true;
					this.pages = false;
				} else if ((route == '/pages/select-page') || ((route).split('?')[0] == '/pages/investor') || ((route).split('?')[0] == '/pages/investor/other') || ((route).split('?')[0] == '/pages/service-provider') || ((route).split('?')[0] == '/pages/page-success')) {
					this.home = false;
					this.pages = true;
				} else {
					this.home = false;
					this.pages = false;
				}
			}
		})
		this.homeService.getOption().subscribe((e) => {
			if (e)
				this.storeFormData = e;
		})
		if (this.storeFormData.params) {
			if ((this.storeFormData?.params.type == 'Venture Capital') && (this.storeFormData.lastStep == 3)) {
				this.storeFormData['data'] = ''
			}
		}
	}

	getProfile() {
		this.commonService.callApi(this.apiConstant.GET_USER_PROFILE, {}, 'get', false, false, false).then((success) => {
			if (success.status === this.statusCode.SUCCESS) {
				this.profile = success.body.data;
				this.name = this.profile.firstName + this.profile.lastName;
				this.localStorageService.setToken('firstName', this.profile.firstName);
				this.localStorageService.setToken('lastName', this.profile.lastName);
				if (this.profile.photo) {
					this.localStorageService.setToken('photo', this.imgUrl + this.profile.photo);
				}
				this.localStorageService.setToken('connectionPrivacy', this.profile.connectionPrivacy);
				this.localStorageService.setToken('followPrivacy', this.profile.followPrivacy);
				this.localStorageService.setToken('statusPrivacy', this.profile.statusPrivacy);
				this.localStorageService.setToken('profilePrivacy', this.profile.profilePrivacy);
				this.localStorageService.setToken('mobilePrivacy', this.profile.mobilePrivacy);
				this.localStorageService.setToken('emailPrivacy', this.profile.emailPrivacy);
			}
		});
	}

	openSignIn() {
		this.modalService.show(LoginComponent,
			{ backdrop: 'static', class: 'common-modal login-modal login-signup-wrap modal-dialog-centered' });
	}

	openSignUp() {
		this.modalService.show(RegisterComponent,
			{ backdrop: 'static', class: 'common-modal signup-modal login-signup-wrap modal-dialog-centered' });
	}

	openIntro() {
		this.modalService.show(CoverVideoPopupComponent,
			{ backdrop: 'static', initialState: { isIntro: true }, class: 'introduction-wrap modal-dialog-centered' });
	}

	onMenuClick() {
		document.getElementById('menu').setAttribute('class', 'menu act');
	}

	close() {
		document.getElementById('menu').setAttribute('class', 'menu');
	}

	openSidebar() {
		Array.from(document.getElementsByClassName('siderbar-wrap'))[0].setAttribute('class', 'siderbar-wrap sidebar-in');
		Array.from(document.getElementsByClassName('sidebar-menu-wrap'))[0].setAttribute('class', 'sidebar-menu-wrap slide-menu-open');
		document.getElementsByTagName('body')[0].classList.add('body-sidebar-open');
		this.clearSearch();
	}

	closeSidebar() {
		Array.from(document.getElementsByClassName('siderbar-wrap'))[0].setAttribute('class', 'siderbar-wrap');
		document.getElementsByTagName('body')[0].classList.remove('body-sidebar-open');
		this.clearSearch();
	}

	clearSearch() {
		this.searchText = null;
		this.searchList = [];
		this.searchEl.clearModel();
		this.searchElMob.clearModel();
	}

	toggleSearch() {
		const search = Array.from(document.getElementsByClassName('search-box') as HTMLCollectionOf<HTMLElement>);
		search[0].style.display = (search[0].style.display === 'block' ? 'none' : 'block');
		this.searchText = null;
		this.searchElMob.clearModel();
	}

	openSetting() {
		Array.from(document.getElementsByClassName('sidebar-menu-wrap'))[0].setAttribute('class', 'sidebar-menu-wrap');
		Array.from(document.getElementsByClassName('sidebar-settings-wrap'))[0].setAttribute('class', 'sidebar-settings-wrap slide-menu-open');
		const element = document.getElementById('settingTop')
		const x = window.scrollX;
		const y = window.scrollY;
		element.focus();
		window.scrollTo(x, y);
	}

	closeSetting() {
		Array.from(document.getElementsByClassName('sidebar-settings-wrap'))[0].setAttribute('class', 'sidebar-settings-wrap');
		Array.from(document.getElementsByClassName('sidebar-menu-wrap'))[0].setAttribute('class', 'sidebar-menu-wrap slide-menu-open');
		this.pop2.hide();
		this.pop1.hide();
	}
	openBackHomeModal(home, pages) {
		if (home) {
			this.router.navigate([this.urlConstant.HOME])
		} else if (pages) {
			this.router.navigate([this.urlConstant.MY_PAGES])
		} else {
			this.localStorageService.setToken('backhome', true)
			this.modalService.show(DiscardSavePageComponent, { backdrop: 'static', initialState: { data: this.storeFormData }, class: 'common-modal modal-dialog-centered' });
		}
	}

	getSearchData(event) {
		if (event.target.value && event.target.value.length > 2) {
			const params = { searchText: event.target.value };
			this.commonService.callApi(this.apiConstant.GLOBAL_SEARCH, params, 'get', false, false, false).then((sucess) => {
				if (sucess.status === this.statusCode.SUCCESS) {
					if (sucess.body.data.list.length > 0) {
						this.searchList = sucess.body.data.list;
						this.searchList.forEach((el) => el.identity = el.identityId + '-' + el.identityType);
						this.searchList = [...this.searchList];
					} else {
						this.searchList = [];
					}
				}
			});
		} else {
			this.searchList = [];
		}
	}

	onBlur() {
		this.clearSearch();
	}

	/*********************************************************
	@PURPOSE: redirect based on search result selection
	*********************************************************/
	onSearchSelect(isMobile?) {
		if (this.searchText) {
			const type = this.searchText.split('-')[1];
			const id = this.searchText.split('-')[0];
			switch (type) {
				case this.identityType.investor: { this.router.navigate([this.urlConstant.INVESTOR_DETAIL, 0, id]); break; }
				case this.identityType.serviceProvider: { this.router.navigate([this.urlConstant.SP_DETAILS, 0, id]); break; }
				case this.identityType.startup: { this.router.navigate([this.urlConstant.INV_OPP_DETAILS, 0, id]); break; }
				case this.identityType.user: { this.router.navigate([this.urlConstant.PEOPLE_DETAILS, 0, id]); break; }
				case this.identityType.feed: {
					this.router.navigate([this.urlConstant.HOME]);
					this.modalService.show(VideoPopupComponent, { initialState: { id }, class: 'home-video-popup modal-dialog-centered' });
					break;
				}
			}
			this.clearSearch();
			if (isMobile) { this.toggleSearch(); }
		}
	}
	/*********************************************************/

	logout() {
		this.logoutService.logout();
	}

	// getNotificationCount() {
	// 	this.commonService.callApi(this.apiConstant.NOTIFICATION_COUNT, '', 'get', false, false).then((success) => {
	// 		if (success.status == statusCode.SUCCESS) {
	// 			this.commonService.notificationCount = success.body.data.notificationCount;
	// 		}
	// 	})
	// }

	getChatList() {
		const filter = {
			limit: 100,
			sort_desc: 'updated_at'
		};

		this.dialogService.getDialogs(filter)
			.then((res) => {
				if (res) {
					res['items'].forEach((chat, index, self) => {
						if (chat.xmpp_room_jid) {
							this.dialogService.joinToDialog(chat);
						}
						self[index].last_message_date_sent = +chat.last_message_date_sent * 1000;
					});
					this.dialogService.setDialogs(res['items']);
				}
			})
			.catch((err) => {
				this.showErrorService.popToast('error', err)
			});
	}

	updateChatList() {
		this.dialogService.dialogsEvent.subscribe((chatData: any[]) => {
			this.chats = Object.values(chatData);
			this.chats = this.chats.filter(el => el.unread_messages_count);
		});
	}

	goToMsg() {
		if (this.chats.length == 0) {
			if (this.dropdown.isOpen) { this.dropdown.hide(); }
			this.router.navigate([this.urlConstant.MY_MESSAGES]);
		}
	}

	goToDialog(chat) {
		this.router.navigate([this.urlConstant.MY_MESSAGES, chat._id]);
	}

	notificationChangeStatus(id, route, data) {
		this.commonService.callApi(this.apiConstant.NOTIFICATION_LIST + '/' + id + '/' + 'change-status', '', 'patch', false, false).then((succ) => {
			if (succ.status == statusCode.SUCCESS) {
				if (data.type == '5' || data.type == '6' || data.type == '13' || data.type == '7') {
					if (data.itemId) {
						this.modalService.show(VideoPopupComponent, { backdrop: 'static', initialState: { id: data.itemId }, class: 'home-video-popup modal-dialog-centered' });
					} else {
						this.showErrorService.displayToastr('error', 'Video is not found')
					}
				} else if (data.type == '14') {
					this.router.navigate([this.urlConstant.SUBSCRIPTION_SETTING]);
				} else if (data.type == '16' || data.type == '17' || data.type == '18' || data.type == '19' || data.type == '20' || data.type == '21' || data.type == '22' || data.type == '23' || data.type == '24' || data.type == '25' || data.type == '26' || data.type == '30') {
					if (data.itemId) {
						this.router.navigate([route], { queryParams: data.query })
					}
					else
						this.showErrorService.displayToastr('error', 'Job is not found')
				} else {
					if (data.itemId) {
						this.router.navigate([route])
					} else {
						this.showErrorService.displayToastr('error', data.notType + ' is not found')
					}
				}
			}
		})
	}
}


