<div class="profile-edit-add-modal modal-card sm-modal-card">
    <button class="popup-close-btn btn" (click) = "bsModalRef.hide()">
      <span class="icon-icon-close"></span>
    </button>
    <h3>
      Report
    </h3>
    <form [formGroup]="reportForm" autocomplete="off">   
      <div class="form-group">
        <label>Reason <sup>*</sup>
        </label>
        <textarea class="form-control" formControlName="reason" placeholder="Report Reason" maxlength="500"></textarea>
        <span class="text-danger"
          *ngIf="(reportForm.controls.reason.touched || submitted) && reportForm.controls.reason.errors?.required">
          {{messages.ERROR.REQUIRED.reason}}
        </span>
        <span class="text-danger"
          *ngIf="(reportForm.controls.reason.touched || submitted) && reportForm.controls.reason.errors?.whiteSpace">
          {{messages.ERROR.REQUIRED.reason}}
        </span>
      </div>
      <div class="btnbox justify-content-center">
        <button type="submit" class="btn btn-primary btn-lg" (click) = "report()">Report</button>
      </div>
    </form>
  </div>
  