<div class="introduction-modal">
    <div class="close-ic">
        <a href="javascript:void(0);" (click)="bsModalRef.hide()">
            <span class="icon-icon-close"></span>
        </a>
    </div>
    <div class="content-header" *ngIf="isIntro && !isAtt">
        <h3>What We Offer?</h3>
        <p>Our platform guides entrepreneurs on topics that close the communication gap between them and investors and
            teaches them to pitch to investors in a language that they understand. It allows networking and provides
            convenient and unfettered access to relevant, meaningful and promising investment deal flow, qualified and
            seasoned investors and a global pool of skilled resources.</p>
    </div>
    <div class="content-header" *ngIf="!isIntro && !isAtt">
        <h3>Cover Video</h3>
    </div>
    <div class="video-block" id="videoblock" *ngIf="!isImg">
        <vg-player>
            <vg-controls [vgAutohide]="true" [vgAutohideTime]="1">
                <vg-play-pause></vg-play-pause>
                <vg-scrub-bar [vgSlider]="true" class="d-flex">
                    <vg-scrub-bar-current-time [vgSlider]="true">
                    </vg-scrub-bar-current-time>
                    <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                </vg-scrub-bar>

                <div class="custom-play-pause-time d-flex">
                    <vg-time-display vgProperty="current" vgFormat="hh:mm:ss">
                    </vg-time-display>
                    <span class="white-line"></span>
                    <vg-time-display vgProperty="total" vgFormat="hh:mm:ss"></vg-time-display>
                </div>
                <div class="custom-controls-icons d-flex align-items-center">
                    <vg-mute></vg-mute>
                    <vg-volume></vg-volume>
                    <span class="icon-maximize" (click)="viewPictureInPicture()"></span>
                    <vg-fullscreen (click)="addFullScreen()"></vg-fullscreen>
                </div>
            </vg-controls>
            <vg-overlay-play [vgFor]="'videofeed'"></vg-overlay-play>
            <vg-buffering [vgFor]="'videofeed'"></vg-buffering>
            <ng-container *ngIf="!isChat">
                <video [vgMedia]="videoPlayer" #videoPlayer [id]="'videofeed'" class="video-feed" preload="auto"
                    autoplay
                    [src]="!isIntro ? imgUrl + (data.coverVideo) : '../../../../assets/video/SampleVideo_770x460.mp4'">
                </video>
            </ng-container>
            <ng-container *ngIf="isChat">
                <video [vgMedia]="videoPlayer" #videoPlayer [id]="'videofeed'" class="video-feed" preload="auto"
                    autoplay
                    [src]="(data.coverVideo)  ? (data.coverVideo) : '../../../../assets/video/SampleVideo_770x460.mp4'">
                </video>
            </ng-container>
        </vg-player>
    </div>
    <div class="video-block modal-imgbox" id="videoblock" *ngIf="isImg">
        <ng-container *ngIf="!isChat">
            <img [src]="(data.coverVideo) ? imgUrl + (data.coverVideo) : '../../assets/images/gitfunded.png'"
                (error)="imgI.src = '../../assets/images/gitfunded.png'" #imgI alt="Logo" />
        </ng-container>
        <ng-container *ngIf="isChat">
            <ng-container *ngIf="!isMultiple">
                <img [src]="(data.coverVideo) ? (data.coverVideo) : '../../assets/images/gitfunded.png'"
                    (error)="imgM.src = '../../assets/images/gitfunded.png'" #imgM alt="Logo" />
            </ng-container>
            <ng-container *ngIf="isMultiple">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                    <ng-container *ngFor="let att of data.coverVideo">
                        <div ngxSlickItem class="slide">
                            <div class="slick-att">
                                <img [src]="att.src ? att.src : '../../../../assets/images/investor_logo1.svg'"
                                    alt="image" (error)="imgCM.src = '../../assets/images/gitfunded.png'" #imgCM
                                    width="500" height="500">
                            </div>
                        </div>
                    </ng-container>
                </ngx-slick-carousel>
            </ng-container>
        </ng-container>
    </div>
</div>