import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { Injectable } from '@angular/core';
import { qbAccount } from 'src/assets/config/qb.config';
import { LocalStorageService } from '../service/local-storage.service';
import { CommonService } from '../service/common.service';

declare var QB: any;

@Injectable()
export class QBHelper {

  qbPassword = qbAccount.qbPassword;

  constructor(
    private localStorageService: LocalStorageService,
    private showErrorService: ShowErrorService,
    private commonService: CommonService
   
  ) { }

  public getSession() {
    return QB && QB.service ? QB.service.getSession() : null;
  }

  setQBLogin(qbData) {
    this.localStorageService.setToken('qbToken', qbData.token)
    this.localStorageService.setToken('qbLogin', qbData.login)
    this.localStorageService.setToken('qbId', qbData.id)

    this.qbChatConnection().then(chatRes => { },
      chatErr => {
        this.showErrorService.popToast('error', chatErr)
      });

    this.qbCreateConnection()
      .then((loginRes) => { 
        this.commonService.loginEvent.emit(true);
      })
      .catch((loginErr) => {
        if (loginErr.status === undefined || loginErr.status !== 401) {
          this.showErrorService.popToast('error', loginErr)
        }
      });
  }

  public checkSession() {
    const user = JSON.parse(localStorage.getItem('qbUserData'));
    const sessionResponse = JSON.parse(localStorage.getItem('qbSession'));
    if (this.getSession() && user && sessionResponse) {
      return new Promise((resolve, reject) => { resolve(true) })
    } else if (sessionResponse && user) {
      return new Promise((resolve, reject) => {
        this.qbChatConnection().then(() => { resolve(true) },
          chatErr => {});

        this.qbCreateConnection()
          .then(() => { resolve(true) })
          .catch((loginErr) => {
            if (loginErr.status === undefined || loginErr.status !== 401) {
              this.showErrorService.popToast('error', loginErr);
            }
          });
      });
    }
  }

  // App Session
  public appSession(login): Promise<any> {
    return new Promise((resolve, reject) => {
      QB.createSession({ login, password: this.qbPassword }, function (sessionErr, sessionRes) {
        if (sessionErr) {
          reject(sessionErr);
        } else {
          resolve(sessionRes);
        }
      });
    });
  }

  public qbCreateAndUpload(file): Promise<any> {
    return new Promise((resolve, reject) => {
      QB.content.createAndUpload({
        public: true,
        file: file,
        name: file.name,
        type: file.type,
        size: file.size
      }, function (err, response) {
        if (err) {
          reject(err);
        } else {
          resolve(response);
        }
      });
    });
  }

  public qbDownload(uid): Promise<any> {
    return new Promise((resolve, reject) => {
      this.checkSession().then(() => {
        QB.content.getFile(uid, function (err, response) {
          // if (err) {
          //   this.showErrorService.popToast('error', err);
          //   reject(err);
          // } else {
          resolve(response);
          // }
        });
      });
    })
  }

  // create connection or login
  public qbCreateConnection(): Promise<any> {
    return new Promise((resolve, reject) => {
      const self = this, login = this.localStorageService.getToken('qbLogin');
      this.appSession(login).then(sessionResponse => {
        localStorage.setItem('qbSession', JSON.stringify(sessionResponse));
        const params = {
          login,
          password: this.qbPassword
        };
        QB.login(params, function (loginErr, loginRes) {
          if (loginErr) {
            reject(loginErr);
          } else {
            self.localStorageService.setToken('qbUserData', JSON.stringify(loginRes))
            resolve(loginRes);
          }
        });
      }).catch(error => {
        this.showErrorService.popToast('error', error);
        error.status = 401;
        reject(error);
      });
    });
  }

  /**
   * chat connection
   * @password {String} password - The user's password or session token
   * */
  public qbChatConnection(): Promise<any> {
    const userId = this.localStorageService.getToken('qbId'),
      password = this.localStorageService.getToken('qbToken');
    return new Promise((resolve, reject) => {
      const params = {
        userId,
        password
      };
      QB.chat.connect(params, function (chatErr, chatRes) {
        if (chatErr) {
          reject(chatErr);
        } else {
          resolve(chatRes);
        }
      });
    });
  }
}
