<div class="card identity-modal-card">
    <h2>Select Favorite Collection</h2>
    <div class="close-ic"><a href="javascript:void(0);" (click)="bsModalRef.hide()"><span
                class="icon-icon-close"></span></a></div>
    <div class="followers-list" *ngIf="collectionList.length > 0">
        <div class="profile-group-wrap">
            <a class="media profile-media"
                [ngClass]="selectedCol ? ((selectedCol._id === collection._id) ? 'selected' : '') : ''"
                *ngFor="let collection of collectionList" (click)="selectedCol = collection">
                <div class="media-body">
                    <h5 class="m-0 fw-sb">{{collection.name}}</h5>
                    <span class="selected-icon"><img src="../../../../assets/images/single-tick.svg" alt="" width="18"
                            height="13"></span>
                </div>
            </a>
        </div>
        <div class="btnbox">
            <button class="btn btn-primary" (click) = "done()">Done</button>
        </div>
    </div>
    <div class="card" *ngIf="collectionList.length === 0">
        <div class="no-data-card">
            <img src="../../../../assets/images/no-data.svg" alt="no-data">
            <p>{{messages.NO_DATA.LIST}}</p>
        </div>
    </div>
    <div class="add-indo-btn-wrap">
        <button type="button" class="btn" (click)="addCollection()"><span class="icon-icon-plush"></span>
            Add Collection</button>
    </div>
</div>