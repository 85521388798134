import { LocalStorageService } from './common/services/service/local-storage.service';
import { qbAccount, qbEndpoints } from './../assets/config/qb.config';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

// Constants
import { URLConstants } from './common/constants/routerLink-constants';
declare var QB: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  URLConstants = URLConstants;
  qbAccount = qbAccount;
  qbEndpoints = qbEndpoints;

  publicHeader = false;
  authHeader = false;
  authSubHeader = false;
  linkedinHeader = false;
  showMessageWindow = false;

  auth = false;

  qB: any = null;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.qB = QB;
    }
    if (this.localStorageService.getToken('accessToken')) { this.auth = true; } else { this.auth = false; }
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      } else {
        const route = evt.url;
        if (route === '/' || route === this.URLConstants.HOMEPAGE || route.includes(this.URLConstants.REFERRAL) || route.split('=')[0] == this.URLConstants.HOMEPAGE + '?' + 'code' || (route.includes(this.URLConstants.CMS) && !this.auth)) {
          this.publicHeader = true;
          this.authHeader = false;
          this.authSubHeader = false;
          this.showMessageWindow = false;
          this.linkedinHeader = false;
        } else if (route.includes(this.URLConstants.HOME) || route.includes(this.URLConstants.MY_VIDEOS)
          || route === this.URLConstants.MY_PROFILE + '0' || route === this.URLConstants.MY_PROFILE + '1'
          || route === this.URLConstants.MY_PROFILE + '2' || route === this.URLConstants.MY_PROFILE + '3'
          || route === this.URLConstants.MY_PROFILE + '4' || route.includes(this.URLConstants.FAV_DETAILS) || route.includes(this.URLConstants.CONN_REQ_LIST) || route.includes(this.URLConstants.INTEREST_LIST)
          || route === this.URLConstants.MY_PAGES || route === this.URLConstants.INVESTOR_LIST
          || route.includes(this.URLConstants.INVESTOR_DETAIL) || route === this.URLConstants.SP_LIST
          || route.includes(this.URLConstants.SP_DETAILS) || route === this.URLConstants.INV_OPP_LIST || route.includes(this.URLConstants.INV_OPP_DETAILS)
          || route === this.URLConstants.PEOPLE_LIST || route.includes(this.URLConstants.PEOPLE_LIST)
          || route === this.URLConstants.MEETING_LIST || route.includes(this.URLConstants.MEETING_LIST)
          || route === this.URLConstants.NOTIFICATION_LIST || route.includes(this.URLConstants.MY_MESSAGES)
          || route.includes(this.URLConstants.SETTINGS) || route === this.URLConstants.DOCUMENT_LIST || route.includes(this.URLConstants.DOCUMENT_LIST)
          || route.includes(this.URLConstants.MY_DOCUMENTS) || route.includes(this.URLConstants.SHARE_WITH_ME)
          || route === this.URLConstants.JOB_LIST + '0' || route.includes(this.URLConstants.BROWSE_JOB_DETAILS)
          || route.includes(this.URLConstants.SETUP_MILESTONES) || route === this.URLConstants.JOB_LIST + '1' || route.includes(this.URLConstants.JOB_POSTING_DETAILS)
          || route === this.URLConstants.JOB_LIST + '2' || route === this.URLConstants.JOB_LIST + '3' || route === this.URLConstants.JOB_LIST + '4'
          || route === this.URLConstants.JOB_LIST + '5' || route === this.URLConstants.JOB_LIST + '6' || route.includes(this.URLConstants.DISPUTES_DETAIL) || route.split('?')[0] === this.URLConstants.CREATE_JOB || route === this.URLConstants.JOB_POSTING_VIDEO_DETAILS || route.split('?')[0] === this.URLConstants.MY_DOCUMENTS
          || (route.includes(this.URLConstants.CMS) && this.auth)) {
          this.publicHeader = false;
          this.authHeader = true;
          this.authSubHeader = false;
          this.linkedinHeader = false;
          if (route.includes(this.URLConstants.MY_MESSAGES)) {
            this.showMessageWindow = false;
          } else {
            this.showMessageWindow = true;
          }
        } else if (route === this.URLConstants.ADD_PROFILE || route.includes(this.URLConstants.SELECT_PAGE) ||
          route.includes(this.URLConstants.INVESTOR) || route.includes(this.URLConstants.OTHER) || route.includes(this.URLConstants.PERSONAL_INVESTOR) ||
          route.includes(this.URLConstants.COMPANY_INVESTOR) || route.includes(this.URLConstants.PAGE_SUCCESS)
          || route.includes(this.URLConstants.STARTUP) || route.includes(this.URLConstants.SP) || route.includes(this.URLConstants.PERSONAL_SP)
          || route.includes(this.URLConstants.COMPANY_SP)) {
          this.publicHeader = false;
          this.authHeader = false;
          this.authSubHeader = true;
          this.showMessageWindow = true;
          this.linkedinHeader = false;
        }
        // else if (route === this.URLConstants.HOME || route.includes(this.URLConstants.MY_VIDEOS)
        //   || route === this.URLConstants.MY_PROFILE + '0' || route === this.URLConstants.MY_PROFILE + '1'
        //   || route === this.URLConstants.MY_PROFILE + '2' || route === this.URLConstants.MY_PROFILE + '3'
        //   || route === this.URLConstants.MY_PROFILE + '4' || route === this.URLConstants.FAV_DETAILS
        //   || route === this.URLConstants.MY_PAGES || route.includes(this.URLConstants.INVESTOR_LIST)
        //   || route.includes(this.URLConstants.SP_LIST)) {
        //   this.publicHeader = false;
        //   this.authHeader = true;
        //   this.authSubHeader = false;
        //   this.linkedinHeader = false;
        // }
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.qB.init(this.qbAccount.appId, this.qbAccount.authKey, this.qbAccount.authSecret, this.qbEndpoints);
    }
  }
}
