import { ShowErrorService } from './show-error.service';
import { QBUIHelper } from './../TS-files/qbUIHelper';
import { LocalStorageService } from './local-storage.service';
import { EventEmitter, Injectable } from '@angular/core';
import { DialogService } from './dialog.service';
import { QBHelper } from '../TS-files/qbHelper';

declare var QB: any;

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public messages: any = {};
  public datesIds: any = [];

  userId;
  messagesEvent: EventEmitter<any> = new EventEmitter();

  public intersectionObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entrie) => {
      if (document.visibilityState === 'visible' && entrie.isIntersecting) {
        const params = {
          messageId: entrie.target['dataset'].messageId,
          // tslint:disable-next-line:radix
          userId: parseInt(entrie.target['dataset'].userId),
          dialogId: entrie.target['dataset'].dialogId
        };
        const event = new CustomEvent('visibility', {
          detail: {
            dialogId: entrie.target['dataset'].dialogId,
            messageId: entrie.target['dataset'].messageId,
          }
        });
        entrie.target.dispatchEvent(event);
        QB.chat.sendReadStatus(params);
      }
    });
  }, {
    threshold: [1.0]
  });

  constructor(
    private localStorageService: LocalStorageService,
    private qbHelper: QBHelper,
    private dialogService: DialogService,
    private showErrorService: ShowErrorService
  ) {
    this.userId = this.localStorageService.getToken('qbId');
    // this.qbHelper.checkSession().then(() => {
    QB.chat.onMessageListener = this.onMessageListener.bind(this);
    QB.chat.onSystemMessageListener = this.onSystemMessageListener.bind(this);
    QB.chat.onDeliveredStatusListener = this.onDeliveredStatusListener.bind(this);
    QB.chat.onReadStatusListener = this.onReadStatusListener.bind(this);
    // });
  }


  // Get Messages
  public getMessages(params): Promise<any> {
    const self = this;
    return new Promise((resolve, reject) => {
      this.qbHelper.checkSession().then(() => {
        QB.chat.message.list(params, function (err, messages) {
          if (messages) {
            resolve(messages);
          } else {
            self.showErrorService.popToast('error', err);
            reject(err);
          }
        });
      });
    });
  }

  public sendMessage(dialog, msg) {
    // this.qbHelper.checkSession().then(() => {
    const
      message = JSON.parse(JSON.stringify(msg)),
      jidOrUserId = dialog.xmpp_room_jid || dialog._id;
    message.id = QB.chat.send(jidOrUserId, msg);
    message.extension.dialog_id = dialog._id;
    return message;
    // });
  }

  public sendSystemMessage(userIds, systemMessage) {
    userIds.forEach((userId) => {
      QB.chat.sendSystemMessage(userId, systemMessage);
    });
  }

  public setMessages(messages, scrollTo): any {
    const self = this;
    self.datesIds = [];

    messages.forEach(message => {
      if (!(message.date_sent instanceof Date)) {
        message.date_sent = new Date(+message.date_sent * 1000);
      }
      self.addMessageToDatesIds(message);
    });
    this.messages = messages;
    this.messagesEvent.emit(self.datesIds);
    setTimeout(() => {
      QBUIHelper.scrollTo(document.querySelector('.msg-chatbox'), scrollTo);
    }, 200);
  }

  public addMessageToDatesIds(message) {
    const
      self = this,
      date = new Date(message.created_at),
      month = date.toLocaleString('en-us', { month: 'short' });
    let key = month + ' ' + date.getDate() + ', ' + date.getFullYear();
    if ((date.getDate() == new Date().getDate()) && (date.getMonth() == new Date().getMonth()) && (date.getFullYear() == new Date().getFullYear())) {
      key = 'Today'
    }
    if (self.datesIds[key] === undefined) {
      self.datesIds[key] = [];
    }
    message['status'] = self.getMessageStatus(message);
    if (message.attachments) {
      message.attachments = message.attachments.map(attachment => {
        attachment.src = QB.content.publicUrl(attachment.id) + '.json?token=' + QB.service.getSession().token;
        return attachment;
      });
    }
    if (message.read_ids && message.read_ids.indexOf(Number(self.userId)) === -1) {
      message.visibilityEvent = true;
    }

    self.datesIds[key].push(message);
  }

  public fillNewMessageParams(userId, msg) {
    const self = this,
      message = {
        _id: msg.id,
        attachments: [],
        created_at: +msg.extension.date_sent || Date.now(),
        date_sent: +msg.extension.date_sent || Date.now(),
        delivered_ids: [userId],
        message: msg.body,
        read_ids: [userId],
        sender_id: userId,
        chat_dialog_id: msg.extension.dialog_id,
        selfReaded: userId == this.userId,
        read: 0
      };

    if (msg.extension.attachments) {
      message.attachments = msg.extension.attachments;
    }

    if (msg.extension.notification_type) {
      message['notification_type'] = msg.extension.notification_type;
    }

    if (msg.extension.new_occupants_ids) {
      message['new_occupants_ids'] = msg.extension.new_occupants_ids;
    }

    message['status'] = (userId != this.userId) ? self.getMessageStatus(message) : undefined;

    return message;
  }

  getMessageStatus(message) {
    if (message.sender_id != this.userId) {
      return undefined;
    }
    const
      self = this,
      deleveredToOcuupants = message.delivered_ids.some(function (id) {
        return id != self.userId;
      }),
      readedByOccupants = message.read_ids.some(function (id) {
        return id != self.userId;
      });
    return !deleveredToOcuupants ? 'sent' :
      readedByOccupants ? 'read' : 'delivered';
  }

  private onSystemMessageListener = function (message) {
    const self = this;
    if (message.extension === undefined || !message.extension.dialog_id === undefined
    ) {
      return false;
    }
    switch (message.extension.notification_type) {
      case '1':
        self.dialogService.getDialogById(message.extension.dialog_id).then(function (dialog) {
          if (dialog.xmpp_room_jid) {
            self.dialogService.joinToDialog(dialog);
          }
          const tmpObj = {};
          tmpObj[dialog._id] = dialog;
          self.dialogService.dialogs = Object.assign(tmpObj, self.dialogService.dialogs);
          self.dialogService.dialogsEvent.emit(self.dialogService.dialogs);
        }).catch(error => {
          self.showErrorService.popToast('error', error);
        });
        break;
    }
  };

  private onMessageListener = function (userId, message) {
    const self = this;
    message.extension.date_sent = new Date(+message.extension.date_sent * 1000);
    message = self.fillNewMessageParams(userId, message);
    if (userId == self.userId) {
      return false;
    }
    if (message.markable) {
      QB.chat.sendDeliveredStatus({
        messageId: message._id,
        userId: userId,
        dialogId: message.chat_dialog_id
      });
    }
    self.dialogService.setDialogParams(message);
    if (message.chat_dialog_id === self.dialogService.currentDialog._id) {
      self.messages.push(message);
      self.addMessageToDatesIds(message);
      self.messagesEvent.emit(self.datesIds);
    }
  };

  private onReadStatusListener = function (messageId, dialogId) {
    const self = this;
    if (dialogId === self.dialogService.currentDialog._id) {
      for (const [key, messages] of Object.entries(self.datesIds)) {
        // @ts-ignore
        for (let i = 0; i < messages.length; i++) {
          if (messages[i]._id === messageId) {
            self.datesIds[key][i].status = 'read';
          }
        }
      }
      self.messagesEvent.emit(self.datesIds);
    }
  };

  private onDeliveredStatusListener = function (messageId, dialogId) {
    const self = this;
    if (dialogId === self.dialogService.currentDialog._id) {
      for (const [key, messages] of Object.entries(self.datesIds)) {
        // @ts-ignore
        for (let i = 0; i < messages.length; i++) {
          if (messages[i]._id === messageId && self.datesIds[key][i].status !== 'read') {
            self.datesIds[key][i].status = 'delivered';
          }
        }
      }
      self.messagesEvent.emit(self.datesIds);
    }
  };
}
