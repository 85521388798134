import { messages } from './../../constants/messages';
import { BsModalService } from 'ngx-bootstrap/modal';
import { statusCode } from 'src/app/common/constants/constant';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// constants
import { APIConstants } from '../../constants/callAPI-constants';
import { URLConstants } from '../../constants/routerLink-constants';

// service
import { CommonService } from './common.service';
import { LocalStorageService } from './local-storage.service';
import { ShowErrorService } from './show-error.service';
import { DiscardSavePageComponent } from 'src/app/shared/modals/discard-save-page/discard-save-page.component';
import { DialogService } from './dialog.service';

declare var QB: any;

@Injectable({
	providedIn: 'root',
})
export class LogoutService {
	URLConstants = URLConstants;
	callAPIConstants = APIConstants;
	statusCode = statusCode;
	messages = messages;

	constructor(
		public localstorage: LocalStorageService,
		private router: Router,
		private commonService: CommonService,
		private showErrorService: ShowErrorService,
		private modalService: BsModalService,
		private dialogService: DialogService
	) { }

	/*************************************************************
	@PURPOSE      : Logout
	@PARAMETERS   : NA
	@RETURN       : NA
	/*************************************************************/
	logout() {
		const modalRef = this.modalService.show(DiscardSavePageComponent,
			{
				initialState: {
					message: this.messages.ERROR.ALERT.LOGOUT,
					buttonName: this.messages.ERROR.ALERT.LOGOUT_BTN,
					cancelButtonName: this.messages.ERROR.ALERT.LOGOUT_CANCEL_BTN
				}, class: 'common-modal modal-dialog-centered'
			});
		modalRef.content.event.subscribe((res) => {
			if (res === true) {
				this.commonService.callApi(this.callAPIConstants.LOGOUT, '', 'post', false, false, false).then((success) => {
					if (success.status === this.statusCode.SUCCESS) {
						this.localstorage.clearToken();
						this.commonService.loginEvent.emit(false);
						this.dialogService.dialogsEvent.emit([]);
						QB.chat.disconnect();
						QB.destroySession(() => null);
						this.showErrorService.displayToastr('success', success.body.extra_meta.message);
						this.router.navigate([URLConstants.HOMEPAGE]);
					}
				});
			}
		});
	}
	/*************************************************************/

}
