import { qbEndpoints } from './../../../../assets/config/qb.config';
import { DiscardSavePageComponent } from './../../../shared/modals/discard-save-page/discard-save-page.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { messages } from 'src/app/common/constants/messages';
import { ShowErrorService } from './show-error.service';
import { ToastrService } from 'ngx-toastr';
import { identityType, statusCode } from './../../constants/constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// environment
import { environment } from 'src/environments/environment';

// service
import { LocalStorageService } from './local-storage.service';
import { URLConstants } from '../../constants/routerLink-constants';
import { APIConstants } from '../../constants/callAPI-constants';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  authorised: any = false;
  public API_URL = '';
  public QB_URL = '';
  statusCode = statusCode;
  messages = messages;
  urlConstant = URLConstants;
  apiConstant = APIConstants;
  imgUrl = environment.imgUrl;
  identityType = identityType;
  public count = 0;
  public notificationCount = 0;
  public notificationList: Array<any> = [];
  public recenttemplateValue: Array<any> = [];
  public notificationRoute;

  loginEvent: EventEmitter<any> = new EventEmitter();
  constructor(
    public router: Router,
    public Http: HttpClient,
    public localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private modalService: BsModalService, public showerrorservice: ShowErrorService
  ) {
    this.API_URL = environment.apiUrl;
    this.QB_URL = qbEndpoints.endpoints.api_endpoint;
  }

  /*******************************************************************************************
  @PURPOSE      	: Call api.
  @Parameters 	  : {
                      url : <url of api>
                      data : <data object (JSON)>
                      method : String (get, post)
                      isForm (Optional) : Boolean - to call api with form data
                      isPublic (Optional) : Boolean - to call api without auth header
                    }
  @RETURN         : Data obtain for API
  /*****************************************************************************************/
  public showSkeletonLoader: boolean;
  callApi(url, data, method, isPublic?, isForm?, html?, type?, pagetype?, qb?): Promise<any> {
    this.showSkeletonLoader = true;
    let headers;
    if (isPublic) {
      headers = new HttpHeaders({ 'content-Type': 'application/json' });
    } else if (html) {
      headers = new HttpHeaders({ 'content-Type': 'text/html', Authorization: this.localStorageService.getToken('accessToken') });
    } else {
      headers = new HttpHeaders({
        'content-Type': 'application/json', Authorization: this.localStorageService.getToken('accessToken') ?
          this.localStorageService.getToken('accessToken') : this.localStorageService.getToken('altToken')
      });
    }
    if (isForm) {
      headers = new HttpHeaders({ Authorization: this.localStorageService.getToken('accessToken') ? this.localStorageService.getToken('accessToken') : this.localStorageService.getToken('altToken') });
    }
    else if (qb) {
      headers = new HttpHeaders({
        'content-Type': 'application/json',
        Authorization: this.localStorageService.getToken('accessToken') ?
          this.localStorageService.getToken('accessToken') : this.localStorageService.getToken('altToken'),
        'quickbloxToken': this.localStorageService.getToken('qbToken')
      });
    }
    return new Promise((resolve, reject) => {
      if (method === 'post') {
        this.ngxService.start();
        this.Http.post(this.API_URL + url, data, { headers, observe: 'response' }).subscribe((value) => {
          this.showSkeletonLoader = false;
          this.ngxService.stop();
          resolve(value);
        }, (error) => {
          this.error(error);
        });
      } else if (method === 'get') {
        if (pagetype == 'mypage') {
          this.ngxService.start();
        }
        this.Http.get(this.API_URL + url, { headers, params: data, observe: 'response' }).subscribe((value) => {
          this.showSkeletonLoader = false;
          if (pagetype == 'mypage') {
            this.ngxService.stop();
          }
          resolve(value);
        }, (error) => {
          this.error(error);
        });
      } else if (method === 'put') {
        this.ngxService.start();
        this.Http.put(this.API_URL + url, data, { headers, observe: 'response' }).subscribe((value) => {
          this.showSkeletonLoader = false;
          this.ngxService.stop();
          resolve(value);
        }, (error) => {
          this.error(error);
        });
      } else if (method === 'patch') {
        this.ngxService.start();
        this.Http.patch(this.API_URL + url, data, { headers, observe: 'response' }).subscribe((value) => {
          this.showSkeletonLoader = false;
          this.ngxService.stop();
          resolve(value);
        }, (error) => {
          this.error(error);
        });
      } else if (method === 'delete') {
        const modalRef = this.modalService.show(DiscardSavePageComponent,
          {
            initialState: {
              message: this.messages.ERROR.ALERT.DELETE + ' ' + type + '?',
              buttonName: this.messages.ERROR.ALERT.DELETE_BTN,
              cancelButtonName: this.messages.ERROR.ALERT.DELETE_CANCEL_BTN
            }, class: 'common-modal modal-dialog-centered'
          });
        modalRef.content.event.subscribe((res) => {
          if (res === true) {
            this.ngxService.start();
            this.Http.delete(this.API_URL + url, { headers, observe: 'response' }).subscribe((value) => {
              this.showSkeletonLoader = false;
              this.ngxService.stop();
              resolve(value);
            }, (error) => {
              this.error(error);
            });
          }
        });
      }
    });

  }

  callQBApi(url, data, method) {
    let headers = new HttpHeaders({ 'content-type': 'application/json', 'QB-Token': this.localStorageService.getToken('qbToken') });
    if (method == 'get') {
      return new Promise((resolve, reject) => {
        this.ngxService.start();
        this.Http.get(this.QB_URL + url, { headers, params: data, observe: 'response' }).subscribe((value) => {
          this.ngxService.stop();
          resolve(value);
        }, (error) => {
          this.error(error);
        });
      });
    }
  }

  /*****************************************************************************************
 @PURPOSE      : Call API returns observable (currently this function is used to video upload progress watch -> observe: 'events')
 @PARAMETERS   : API url and data
 @RETURN       : Returns progress event and reponse
 /*****************************************************************************************/
  callApiObservable(url, data) {
    const headers = new HttpHeaders({ Authorization: this.localStorageService.getToken('accessToken') });
    return this.Http.post(this.API_URL + url, data, {
      headers, reportProgress: true,
      observe: 'events'
    }).pipe(map((rsp) => rsp));
  }
  /*****************************************************************************************/

  /*****************************************************************************************
  @PURPOSE      : To Show session LogOut popup
  @PARAMETERS   : NA
  @RETURN       : NA
  /*****************************************************************************************/
  sessionLogOut() {
    if (this.localStorageService.getToken('accessToken')) {
      this.localStorageService.clearToken();
      this.router.navigate([this.urlConstant.HOMEPAGE]);
      this.showerrorservice.displayToastr('error', 'Session Logout');
    }
  }
  /****************************************************************************************/

  /*****************************************************************************************
  @PURPOSE      : To Show error on status 401, 422 or any other error
  @PARAMETERS   : NA
  @RETURN       : NA
  /*****************************************************************************************/
  error(error) {
    this.ngxService.stop();
    if (error.status === 401) {
      this.toastr.error(error.error.error.message);
      if (this.count == 0) {
        this.count = this.count + 1;
        this.sessionLogOut();
      }
    } else if (error.status === 404) {
      this.toastr.error(error.error.error.message);
      if (this.count == 0) {
        this.count = this.count + 1;
        this.sessionLogOut();
      }
    } else {
      if (error.error.error.message) {
        this.toastr.error(error.error.error.message);
      } else {
        this.toastr.error(this.messages.ERROR.OTHERS.NO_RESPONSE);
      }
    }
  }
  /*****************************************************************************************/

  hideSidebar = false;
  toggleSidebar() {
    this.hideSidebar = !this.hideSidebar;
  }

  getNotificationList() {
    this.callApi(this.apiConstant.HEADER_NOTIFICATION_LIST, '', 'get', false, false).then((success) => {
      if (success.status == statusCode.SUCCESS) {
        this.notificationList = [];
        success.body.data.forEach(element => {
          if (!element.isSeen) {
            this.notificationList.push(element);
          }
        });
        if (this.notificationList.length > 0) {
          this.notificationList.forEach((recent) => {
            if (!recent.isSeen) {
              this.notificationCount = this.notificationCount + 1;
              this.recenttemplateValue = []
              if (recent.type == '1' || recent.type == '2' || recent.type == '3') {
                recent.route = this.urlConstant.MEETING_DETAILS + '/' + recent.itemId;
                recent.notType = 'Meeting';
              } else if (recent.type == '4') {
                if (recent.itemType == this.identityType.investor) {
                  recent.route = this.urlConstant.INVESTOR_DETAIL + '/' + 0 + '/' + recent.itemId;
                } else if (recent.itemType == this.identityType.serviceProvider) {
                  recent.route = this.urlConstant.SP_DETAILS + '/' + 0 + '/' + recent.itemId;
                } else {
                  recent.route = this.urlConstant.INV_OPP_DETAILS + '/' + 0 + '/' + recent.itemId;
                }
                recent.notType = 'Page';
              }
              else if (recent.type == '8' || recent.type == '9' || recent.type == '10') {
                recent.notType = 'Team member';
              } else if (recent.type == '11') {
                recent.route = this.urlConstant.MY_PROFILE + 2;
              } else if (recent.type == '12') {
                recent.route = this.urlConstant.DOCUMENT_LIST + '/' + 1;
                recent.notType = 'Document';
              } else if (recent.type == '15') {
                recent.route = this.urlConstant.JOB_LIST + '/' + 4;
                recent.notType = 'job';
              } else if (recent.type == '16' || recent.type == '19' || recent.type == '20') {
                recent.route = this.urlConstant.JOB_POSTING_DETAILS + '/' + recent.itemId;
                recent.query = { route: 1 };
                recent.notType = 'job';
              } else if (recent.type == '17' || recent.type == '18' || recent.type == '21') {
                recent.route = this.urlConstant.BROWSE_JOB_DETAILS + '/' + recent.itemId;
                recent.query = { route: ((recent.type == '17' || recent.type == '18' || recent.type == '21') && recent.templateValue[1].name != "rejected") ? 5 : 4 };
                recent.notType = 'job';
              } else if (recent.type == '22' || recent.type == '24' || recent.type == '25' || recent.type == '26') {
                recent.route = this.urlConstant.BROWSE_JOB_DETAILS + '/' + recent.itemId;
                recent.query = { route: 2 };
                recent.notType = 'job';
              } else if (recent.type == '23') {
                recent.route = this.urlConstant.JOB_POSTING_DETAILS + '/' + recent.itemId;
                recent.query = { route: 2 };
                recent.notType = 'job';
              } else if (recent.type == '27' || recent.type == '28' || recent.type == '29') {
                recent.route = this.urlConstant.DISPUTES_DETAIL + recent.itemId;
                recent.notType = 'Dispute';
              } else if (recent.type == '30') {
                if (recent.ratedTo == 'provider') {
                  recent.route = this.urlConstant.BROWSE_JOB_DETAILS + '/' + recent.itemId;
                } else {
                  recent.route = this.urlConstant.JOB_POSTING_DETAILS + '/' + recent.itemId;
                }
                recent.query = { route: 3 };
                recent.notType = 'job';
              } else if (recent.type == '31') {
                recent.route = this.urlConstant.BROWSE_JOB_DETAILS + '/' + recent.itemId;
                recent.query = { route: 4 };
                recent.notType = 'job';
              } else if (recent.type == '32') {
                recent.route = this.urlConstant.JOB_POSTING_DETAILS + '/' + recent.itemId;
                recent.query = { route: 2 };
                recent.notType = 'job';
              } else if (recent.type == '33') {
                recent.route = this.urlConstant.VERIFY_PAGE + '/' + recent.itemId;
                recent.notType = 'Identity Verify';
              } else if (recent.type == '34') {
                recent.route = this.urlConstant.VERIFY_ACCREDITATION + '/' + recent.itemId;
                recent.notType = 'Accreditation Verify';
              } else if (recent.type == '35') {
                recent.route = this.urlConstant.BROWSE_JOB_DETAILS + '/' + recent.itemId;
                recent.query = { route: 2 };
                recent.notType = 'job';
              }
              recent.templateValue.forEach(element => {
                this.recenttemplateValue.push(element.name)
              });
              this.recenttemplateValue.forEach((substrng) => {
                if (recent.original.includes(substrng)) {
                  recent.original = recent.original.replace(new RegExp(substrng, 'gi'), match => {
                    return '<a' + ' class="primary-text">' + match + '</a>';
                  });
                }
              })
            }
          })
        } else {
          this.notificationCount = 0;
        }
      }
    })
  }
}
