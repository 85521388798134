<div class="short-profile-modal modal-card">
  <form [formGroup]="profileForm" autocomplete="off">
    <button class="popup-close-btn btn" (click)="bsModalRef.hide()">
      <span class="icon-icon-close"></span>
    </button>
    <h3>
      Tell Us About Yourself
    </h3>
    <div class="short-profile-imgbox">
      <div class="avatar-upload">
        <div class="avatar-edit">
          <input [hidden]="true" type='file' (click)="$event.target.value=null" id="imageUpload" accept=".png, .jpg, .jpeg"
            (change)="fileChangeEvent($event)" id="profile" />
          <label for="imageUpload"><a class="icon-icon-camera" (click)="openFile($event)"></a></label>
        </div>
        <div class="avatar-preview">
          <picture>
            <img id="imagePreview" class="img-fluid" [src]="profileForm.controls.photo.value ?
          imgUrl + profileForm.controls.photo.value: 
          '../../../../assets/images/gitfunded.svg'" (error)="img.src = '../../../../assets/images/gitfunded.svg'" #img
              alt="" width="120" height="120">
          </picture>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>First Name </label>
      <input type="text" class="form-control" formControlName="firstName" placeholder="First Name" maxlength="20">
      <span class="text-danger"
        *ngIf="(profileForm.controls.firstName.touched || submitted) && (profileForm.controls.firstName.errors?.required || profileForm.controls.firstName.errors?.pattern || profileForm.controls.firstName.errors?.whiteSpace) ">
        {{messages.ERROR.REQUIRED.firstName}}
      </span>
    </div>
    <div class="form-group">
      <label>Last Name </label>
      <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name" maxlength="20">
      <span class="text-danger"
        *ngIf="(profileForm.controls.lastName.touched || submitted) && (profileForm.controls.lastName.errors?.required || profileForm.controls.lastName.errors?.pattern || profileForm.controls.lastName.errors?.whiteSpace)">
        {{messages.ERROR.REQUIRED.lastName}}
      </span>
    </div>
    <div class="btnbox">
      <button type="submit" class="btn btn-primary" (click) = "onSubmit()">Save</button>
    </div>
  </form>
</div>