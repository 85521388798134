<div class="save-changes-notification">
    <div class="modal-card sm-modal-card">
        <button class="popup-close-btn btn" (click)="discardSavePage('discard')">
            <span class="icon-icon-close"></span>
        </button>
        <ng-container *ngIf = "planDetails">
            <div>
                <h4 class="text-primary d-flex justify-content-center mb-3">Plan : {{planDetails.name}}</h4>
                <h6 class="d-flex justify-content-center">{{planDetails.price ? 'Amount' : 'Credit'}}: {{planDetails.price ? '$' : ''}} {{planDetails.price ? planDetails.price : planDetails.priceCredits}} {{planDetails.price ? 'USD' : 'Credits/Month'}}</h6>    
            </div>
        </ng-container>
        <p style="font-size: x-large;">
            {{message ? message : 'Would you like to save the changes?'}}
        </p>
        <p *ngIf = "additionalMsg">
            {{additionalMsg}}
        </p>
        <p *ngIf = "additionalMsg2" class = "text-danger d-flex justify-content-center"> {{additionalMsg2}}</p>
        <form>
            <div class="btnbox justify-content-between">
                <button type="button" class="btn btn-primary  ml-3 mr-5" (click)="discardSavePage('save')">{{ buttonName
                    ? buttonName :'Save'}}</button>
                <button type="button" class="btn btn-primary border-btn ml-5 mr-3" *ngIf = "showCancelBtn"
                    (click)="discardSavePage('discard')">{{cancelButtonName ? cancelButtonName :'Discard'}}</button>
            </div>
        </form>
    </div>
</div>