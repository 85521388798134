<footer>  
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-8 col-sm-12">
          <div class="info">
            <a href="javascript:void(0);" class="ftr-logo">
              <img src="../../../../assets/images/footer-logo.svg" alt="Logo" width="275" height="40" />
            </a>
            <p>
              G’t Funded is a multi-layered digital pitching platform disrupting the pre-IPO equity financing industry. We are changing the way in which private companies pitch for investment and the way in which investors invest. We aim to be the top investor networking tool globally with the largest database of investor mandates across all markets.
            </p>
            <div class="follow-us-wrap">
              <h5>Follow Us:</h5>
              <ul class="social-media">
                <li>
                  <a href="javascript:void(0);" (click) = "gotoURL('fb')">
                    <span class="icon-icon-facebook"></span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" (click) = "gotoURL('twitter')">
                    <span class="icon-icon-twitter"></span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" (click) = "gotoURL('web')">
                    <span class="icon-icon-linkedin"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-4 col-sm-12">
          <div class="ftr-nav">
            <h5>Other Links</h5>
            <ul>
              <li>
                <a href="javascript:void(0);" [routerLink]="[urlConstant.CMS, cmsType.aboutUs]">
                  ABOUT US
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" [routerLink]="[urlConstant.CMS, cmsType.help]">
                  HELP
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" [routerLink]="[urlConstant.CMS, cmsType.howItWorks]">
                  HOW IT WORKS?
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" [routerLink]="[urlConstant.CMS, cmsType.legal]">
                  LEGAL
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="javascript:void(0);" [routerLink]="[urlConstant.CMS, cmsType.privacy]">
                  PRIVACY POLICY
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" [routerLink]="[urlConstant.CMS, cmsType.terms]">
                  TERMS & CONDITIONS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" [routerLink]="[urlConstant.CMS, cmsType.contact]">
                  CONTACT US
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-8">
          <div class="ftr-nav">
            <ul>
              <li>
                  <a class="location-link" href="javascript:void(0);" routerLink="{{ urlConstant.HOMEPAGE }}"><span class="location">Home</span>
                  </a>    
              </li>
              <li><a href="javascript:;" (click) = "navigateTo('INV_OPP')">INVESTMENT OPPORTUNITIES</a></li>
              <li><a href="javascript:;" (click) = "navigateTo('INVESTORS')">INVESTORS</a></li>
              <li><a href="javascript:;" (click) = "navigateTo('SERVICE_PROVIDER')">SERVICE PROVIDER</a></li>
              <li><a href="javascript:;" (click) = "navigateTo('NETWORK')">NETWORK</a></li>
              <li><a href="javascript:;" (click) = "navigateTo('JOBS')">JOBS</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 col-lg-4">
          <p class="copy-rights">
            © 2021 <a href="#">G't</a> All rights reserved.
          </p>
        </div>
      </div>
      
    </div>
  </div>  
</footer>