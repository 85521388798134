export const statusCode = {
    SUCCESS: 200,
    INVALID: 406
};

export const privacyList = [
    { _id: 'public', name: 'Public', description: 'Visible to everyone', class: 'icon-website' },
    { _id: 'connection', name: 'Connection Only', description: 'Visible only to your connections', class: 'icon-icon-connections' },
    { _id: 'private', name: 'Private', description: 'Visible only to you', class: 'icon-icon-lock' }
];

export const identityType = {
    startup: 'startup',
    individual: 'individual',
    company: 'company',
    investor: 'investor',
    serviceProvider: 'serviceProvider',
    pitch: 'pitch',
    user: 'user',
    feed: 'feed',
    me: 'me'
};

export const investorType = {
    angel: 'Angel',
    ventureCapital: 'Venture Capital',
    privateEquity: 'Private Equity',
    investmentBanker: 'Investment Banker',
    familyOffice: 'Family Office',
    other: 'Other'
};

export const videoSort = [
    { _id: '-createdAt', name: 'Most Recent' },
    { _id: '+createdAt', name: 'Least Recent' },
    { _id: '+title', name: 'Title [A-Z]' },
    { _id: '-title', name: 'Title [Z-A]' },
];

export const dateConfig = {
    monthInput: 'MM/YYYY',
    minMonth: 'month',
    yearInput: 'YYYY',
    minYear: 'year',
    apiDate: 'yyyy-MM-dd',
    meetingDate: 'yyy-MM-dd:HH:mm:ss',
    apiYear: 'yyyy'
};

export const displayDate = {
    generalDate: 'MMM dd, yyyy',
    monthYear: 'MMM yyyy',
    meetingTime: 'hh:mm a',
    meetingCalendarDate: 'dd MMMM yyyy',
    spDate: 'dd MMM y',
    portfolioDate: 'dd/mm/yyyy',
    docDate: 'MM/dd/yyyy'
};

export const networkFilter = [
    { _id: '2', value: 'Connected', isChecked: false },
    { _id: '0', value: 'Not Connected', isChecked: false },
    { _id: '1', value: 'Pending', isChecked: false }
];

export const reminder = [
    { _id: '', value: 'None', class: 'icon-icon-block' },
    { _id: '0m', value: 'At the time of the meeting' },
    { _id: '5m', value: '5 minutes before' },
    { _id: '10m', value: '10 minutes before' },
    { _id: '15m', value: '15 minutes before' },
    { _id: '30m', value: '30 minutes before' },
    { _id: '1h', value: '1 hour before' },
    { _id: '2h', value: '2 hours before' },
    { _id: '1d', value: '1 day before' },
    { _id: '2d', value: '2 days before' },
    { _id: '1w', value: '1 week before' },
];

export const meetingFilter = [
    { _id: 'all', value: 'All' },
    { _id: 'me', value: 'Me' },
    { _id: 'other', value: 'Other' },
];

export const cardColors = [
    { value: 1, class: 'text-primary' },
    { value: 2, class: 'text-danger' },
    { value: 3, class: 'text-success' },
    { value: 4, class: 'text-yellow' },
    { value: 5, class: 'text-light-gray' },
];

export const cardLogo = {
    americanExpress: 'American Express',
    cup: 'UnionPay',
    discoverDiners: 'Discover',
    jcb: 'JCB',
    mastercard: 'MasterCard',
    visa: 'Visa',
    unknown: 'Unknown'
};

export const privacy = [
    { _id: 'all', value: 'All Users' },
    { _id: 'connection', value: 'Your Connections Only' },
    { _id: 'none', value: 'None' },
];

export const profilePrivacy = [
    { _id: 'all', value: 'My profile picture, full name, current position, experiences, education' },
    { _id: 'partial', value: 'Partial Anonymous - full name, current position, experiences, education only' },
    { _id: 'anonymous', value: 'Completely anonymous' },
];

export const accreditationType = {
    individualIncome: 'individualIncome',
    individualNetWorth: 'individualNetWorth',
    entityRepresentative: 'entityRepresentative',
    trustAndSophisticated: 'trustAndSophisticated'
};

export const documentFilter = [
    { value: 'startup', label: 'Startup' },
    { value: 'investor', label: 'Investor' },
    { value: 'serviceProvider', label: 'Service Provider' },
    { value: 'individual', label: 'Network' },
];

export const encryptionKey = '(G+KbPeSgVkYp3s6v9y$B&E)H@McQfTj';
export const encryptionIV = '-JaNdRgUkXp2s5v8';


export const msgType = {
    personal: 'personal',
    job: 'job'
}

export const msgStatus = {
    pending: 'Pending',
    accepted: 'Accepted',
    rejected: 'Rejected'
}

export const msgFilter = [
    { value: 'startup', label: 'Company' },
    { value: 'investor', label: 'Investor' },
    { value: 'serviceProvider', label: 'Service Provider' },
];

export const subscriptionType = {
    freemium: 'core',
    freemiumName: 'Core',
    startup: 'startup_essential',
    startupName: 'Startup Essential',
    premium: 'premium',
    premiumName: 'Premium'
}

export const creditType = {
    credit: 'credit',
    chatCredit: 'chat_credit',
    connectionReq: 'connection_req'
}

export const creditFilter = [
    { _id: '', value: 'All' },
    { _id: 'Purchase', value: 'Purchase' },
    { _id: 'Spent', value: 'Spent' },
]

export const cmsType = {
    aboutUs: 'about_us',
    help: 'help',
    howItWorks: 'how_it_works',
    legal: 'legal',
    privacy: 'privacy_policy',
    terms: 'terms_conditions',
    contact: 'contact_us'
}

export const blockIdentityType = {
    startup: 'Startup',
    individual: 'Individual',
    investor: 'Investor',
    serviceProvider: 'Service Provider'
};

export const paymentType = {
    subscription: 'subscription',
    oneTimePackage: 'oneTimePackage',
    job: 'job',
    promotionalPackage: 'promotionalPackage',
    addOnPackage: 'addOnPackage'
}

export const oneTimePackageType = {
    storage: 'storage',
    noAds: 'no_ads_in_video',
    credit: 'credit',
    connReq: 'connection_req',
    chatCredit: 'chat_credit'
}

export const addOnPackageType = {
    storage: 'storage',
    noAds: 'no_ads_in_video',
}

export const promotionalPackageType = {
    video: 'video',
    videoLabel: 'Video',
    page: 'page',
    pageLabel: 'Page',
    job: 'job',
    jobLabel: 'Job'
}

export const promotionType = {
    click: 'click',
    impression: 'impression'
}

export const stripeAuthStatus = {
    requiresAction: 'requires_action',
    reqPayMethod: 'requires_payment_method'

}

export const subscriptionFilter = {
    textFilter: 'text_filter',
    locationFilter: 'location_filter',
    multipleFilter: 'multiple_filter'
}

export const stripeVerifyUpdateType = {
    accountOnboarding: 'account_onboarding',
    accountUpdate: 'account_update'
}

export const kB = 1024;

export const duration = [
    { name: 'Hours', value: 'hours' },
    { name: 'Days', value: 'days' },
    { name: 'Weeks', value: 'weeks' },
    { name: 'Months', value: 'months' },
    { name: 'Years', value: 'years' },
  ];