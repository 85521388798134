import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDate'
})
export class TimeDatePipe implements PipeTransform {

    transform(value: string) {
        if (value) {
            var _value = Number(value);
        
            var dif = Math.floor( ( (Date.now() - _value) / 1000 ) / 86400 );
            
            if ( dif < 3 ){
                 return convertToReqDate(value);
            }else{
                var datePipe = new DatePipe("en-US");
                value = datePipe.transform(value, 'MMM dd, yyyy');
                return value;
            }
        }
     }

}

function convertToReqDate(time: string) {
    var datePipe = new DatePipe("en-US");
    var date = new Date(time),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        daydiff = Math.floor(diff / 43200);

    if (isNaN(daydiff) || daydiff < 0)
        return '';

    return daydiff == 0 && (
        // diff < 60 && "Just now" ||
        // diff < 120 && "1 minute ago" ||
        // diff < 3600 && Math.floor(diff / 60) + " minutes ago" ||
        // diff < 7200 && "1 hour ago" ||
        diff < 43200 && datePipe.transform(time, 'hh:mm a')) ||
        daydiff == 1 && "Yesterday"
}
