import { DialogService } from 'src/app/common/services/service/dialog.service';
import { VideoProcessingService } from './service/video-processing.service';
import { CustomValidationService } from './service/custom-validation.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// service
import { CommonService } from './service/common.service';
import { LocalStorageService } from './service/local-storage.service';
import { MetaDataService } from './service/meta-data.service';
import { ShowErrorService } from './service/show-error.service';
import { RestrictKeyPressService } from './service/restrict-key-press.service';
import { LogoutService } from './service/logout.service';
import { BackhomeService } from './service/backhome.service';
import { ScrollWindowService } from './service/scroll-window.service';
// TS Files
import { ErrorMessages } from './TS-files/errorMessages';
import { SharedServices } from './service/shared.service';
import { QBHelper } from './TS-files/qbHelper';
import { MessageService } from './service/message.service';
import { QBUIHelper } from './TS-files/qbUIHelper';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    CommonService,
    BackhomeService,
    ScrollWindowService,
    LocalStorageService,
    MetaDataService,
    ShowErrorService,
    ErrorMessages,
    RestrictKeyPressService,
    LogoutService,
    CustomValidationService,
    VideoProcessingService,
    SharedServices,
    QBHelper,
    QBUIHelper,
    DialogService,
    MessageService
  ]
})
export class ServiceModule { }
