<div class="login-signup-form modal-card">
  <button class="popup-close-btn btn" (click)="bsModalRef.hide()">
    <span class="icon-icon-close"></span>
  </button>
  <h3>
    Create Account
  </h3>
  <p>
    Let’s get you all set up so you can verify your account and begin setting up profile.
  </p>
  <form class="form" [formGroup]="registerForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="form-group">
      <label>Email ID or Mobile Number</label>
      <input type="text" class="form-control" formControlName="emailId" placeholder="Email ID or Mobile Number">
      <span class="text-danger"
        *ngIf="(registerForm.controls.emailId.touched || submitted) && registerForm.controls.emailId.errors?.required">
        {{messages.ERROR.REQUIRED.emailId}}
      </span>
      <span class="text-danger"
        *ngIf="(registerForm.controls.emailId.touched || submitted) && registerForm.controls.emailId.errors?.invalidInput">
        {{messages.ERROR.PATTERN.emailId}}
      </span>
    </div>
    <div class="form-group">
      <label>Password</label>
      <div class="input-with-icon">
        <input [type]="passwordType ? 'text' : 'password'" id="password-field" type="password" class="form-control"
          formControlName="password" placeholder="Password" maxlength="16">
        <span [tooltip]="passwordType ? 'Hide Password' : 'Show Password'" toggle="#password-field"
          class="bx bx-hide field-icon toggle-password"
          [ngClass]="{'bx-bx-hide': !passwordType, 'icon-icon-eye': passwordType}"
          (click)="toggleFieldTextType()"></span>
      </div>
      <span class="text-danger"
        *ngIf="(registerForm.controls.password.touched || submitted) && registerForm.controls.password.errors?.required">
        {{messages.ERROR.REQUIRED.newPassword}}
      </span>
      <span class="text-danger"
        *ngIf="(registerForm.controls.password.touched || submitted) && registerForm.controls.password.errors?.invalidPassword">
        {{messages.ERROR.PATTERN.newPassword}}
      </span>
    </div>
    <div class="form-group">
      <label>Referral Code</label>
      <input type="text" formControlName="referralCode" class="form-control" placeholder="Referral Code (Optional)"
        [readonly]="referCode">
    </div>
    <div class="custom-checkbox">
      <label for="acceptTerms">
        <input type="checkbox" id="acceptTerms" [checked]="accepted" (change)="accepted = !accepted">
        <span></span>
        I accept <a href="javascript:void(0);" class="primary-link"
          [routerLink]="[urlConstant.CMS, cmsType.terms]">Terms & Condition</a>
      </label> <br>
      <span class="text-danger" *ngIf="tcError && !accepted">{{messages.ERROR.OTHERS.TC}}</span>
    </div>
    <div class="btnbox">
      <button class="btn btn-primary">Sign Up</button>
    </div>
  </form>
  <p class="m-0">
    Or Sign up with
  </p>
  <app-social-signup [type]="'register'"></app-social-signup>
</div>
<div class="info">
  <div class="signup-info info-wrap">
    <h3>Already have an account?</h3>
    <p>
      Sign in to access your reserved area.
    </p>
    <button (click)="login()" class="btn btn-default">Sign In</button>
  </div>
</div>