import { Component, OnInit } from '@angular/core';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styles: [
  ]
})
export class ShareComponent implements OnInit {

  urlConstant = URLConstants;
  gtfundedUrl = environment.share_url;
  id;
  pageUrl;
  videoUrl;
  type;
  url;
  description;
  route;

  constructor(public router: Router, public modalRef: BsModalRef, public activatedRoute: ActivatedRoute, public localStorage: LocalStorageService) { }

  ngOnInit(): void {
    if (this.type == 'job') {
      this.url = this.gtfundedUrl + this.urlConstant.BROWSE_JOB_DETAILS + '/' + this.id + '?route=' + this.route;
      this.description = this.localStorage.getToken('firstName') + ' ' + this.localStorage.getToken('lastName') + ' has shared a job with you';
    } else if (this.type == 'page') {
      this.url = this.gtfundedUrl + this.pageUrl;
      this.description = this.localStorage.getToken('firstName') + ' ' + this.localStorage.getToken('lastName') + ' has shared a page with you';
    } else {
      this.url = this.gtfundedUrl + '/' + this.videoUrl;
      this.description = this.localStorage.getToken('firstName') + ' ' + this.localStorage.getToken('lastName') + ' has shared a video with you';
    }
  }

  purchase() {
    this.router.navigate([this.urlConstant.CREDITS_SETTING]);
    this.modalRef.hide();
  }
}
