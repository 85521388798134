import { cmsType } from './../../../common/constants/constant';
import { CoverVideoPopupComponent } from 'src/app/shared/modals/cover-video-popup/cover-video-popup.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: [],
})
export class FooterComponent implements OnInit {
  urlConstant = URLConstants;
  cmsType = cmsType;
  isUserLoggenId = false;
  constructor(
    private localStorageService: LocalStorageService,
    private modalService: BsModalService,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.localStorageService.getToken('accessToken')) {
      this.isUserLoggenId = true;
    }
  }

  navigateTo(route) {
    if (this.isUserLoggenId) {
      switch (route) {
        case 'INV_OPP' : {this.router.navigate([this.urlConstant.HOME]); break; }
        case 'INVESTORS' : {this.router.navigate([this.urlConstant.INVESTOR_LIST]); break; }
        case 'SERVICE_PROVIDER' : {this.router.navigate([this.urlConstant.SP_LIST]); break; }
        case 'NETWORK' : {this.router.navigate([this.urlConstant.PEOPLE_LIST]); break; }
        case 'JOBS' : {this.router.navigate([this.urlConstant.JOB_LIST]); break; }
      }
    } else {
      this.modalService.show(CoverVideoPopupComponent,
        {backdrop: 'static', initialState: {isIntro: true}, class: 'introduction-wrap modal-dialog-centered' });
    }
  }

  gotoURL(socialMedia) {
    switch (socialMedia) {
      case 'fb' : { window.open('https://www.facebook.com/Gt-Funded-INC-102765948612688', '_blank'); break; }
      case 'twitter' : { window.open('https://twitter.com/', '_blank'); break; }
      case 'web' : { window.open('https://www.linkedin.com/company/gtfunded', '_blank'); break; }
    }
  }

}
