import { ReportComponent } from './../report/report.component';
import { Component, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { displayDate, oneTimePackageType, paymentType, promotionalPackageType, statusCode } from 'src/app/common/constants/constant';
import { messages } from 'src/app/common/constants/messages';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { CommonService } from 'src/app/common/services/service/common.service';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { environment } from 'src/environments/environment';
import { DiscardSavePageComponent } from 'src/app/shared/modals/discard-save-page/discard-save-page.component';
import { identityType } from './../../../common/constants/constant';
import { SelectFavCollectionComponent } from '../select-fav-collection/select-fav-collection.component';
import { InsuffiecientWarnComponent } from '../insuffiecient-warn/insuffiecient-warn.component';
import { ShareComponent } from 'src/app/auth-modules/jobs/jobs-shared/share/share.component';

interface VideoPlayer extends HTMLVideoElement {
  requestPictureInPicture(): any;
}

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styles: []
})
export class VideoPopupComponent implements OnInit {

  urlConstant = URLConstants;
  apiConstant = APIConstants;
  messages = messages;
  statusCode = statusCode;
  imgUrl = environment.imgUrl;
  identityType = identityType;
  dateFormat = displayDate;
  promotionalPackageType = promotionalPackageType;
  paymentType = paymentType;
  oneTimePackageType = oneTimePackageType;
  showCommentDesc = true;
  id;
  loggedInUserId: string;
  photo;
  myComment: string;
  videoDetail: any = {};
  commentList = [];
  totalComments: number;
  commentPageNo: number;
  showCustomCtrl = false;
  subscriptionFeatures;
  public event: EventEmitter<any> = new EventEmitter();
  public deleteEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  constructor(
    public bsModalRef: BsModalRef,
    private commonService: CommonService,
    private localStorageService: LocalStorageService,
    private showErrorService: ShowErrorService,
    private router: Router,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.loggedInUserId = this.localStorageService.getToken('userId');
    this.photo = this.localStorageService.getToken('photo');
    this.getVideoDetails();
    this.getComments();
    this.subscriptionFeatures = JSON.parse(this.localStorageService.getToken('subscriptionFeatures'));
  }

  /*********************************************************
  @PURPOSE: get Video details api
  *********************************************************/
  getVideoDetails() {
    this.commonService.callApi(this.apiConstant.GET_FEED + this.id, {}, 'get', false, false, false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        this.videoDetail = success.body.data;
      }
    });
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: view video in Picture-in-Picture mode
  *********************************************************/
  viewPictureInPicture() {
    const video: VideoPlayer = this.videoPlayer.nativeElement;
    this.bsModalRef.hide();
    this.event.emit(this.videoDetail);
    video.requestPictureInPicture();
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: add full screen class
  *********************************************************/
  addFullScreen() {
    const video = document.getElementById('videoblock').classList;
    video.add('fullscreen');
    this.exitEvent();
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: remove class on full screen exit
  *********************************************************/
  exitEvent() {
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);

    function exitHandler() {
      if (!document['webkitIsFullScreen'] && !document['mozFullScreen'] && !document['msFullscreenElement']) {
        const video = document.getElementById('videoblock').classList;
        video.remove('fullscreen');
      }
    }
  }


  /*********************************************************
  @PURPOSE: add/remove like/dislike and update count
  @PARAM: object : video/comment/reply object
          flag : like->true, dislike->false
          addFlag: have to add/remove like/dislike
          objectType: video/comment
  *********************************************************/
  like(object, flag, addFlag, objectType) {
    const params = {
      objectType,
      objectId: object._id,
      isLike: flag
    };
    this.commonService.callApi(addFlag ? this.apiConstant.LIKE : this.apiConstant.REMOVE_LIKE, params, 'post', false, false, false)
      .then((success) => {
        if (success.status === this.statusCode.SUCCESS) {
          const response = success.body.data;
          object.dislikeTotal = response.dislikeTotal;
          object.isUserDislikes = response.isUserDislikes;
          object.isUserLikes = response.isUserLikes;
          object.likeTotal = response.likeTotal;
        }
      });
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: add to favorites
  *********************************************************/
  addToFav(video) {
    if (!video.isFavorite) {
      const modelRef = this.modalService.show(SelectFavCollectionComponent, {
        backdrop: 'static',
        initialState: { itemId: video._id, itemType: 'video' }, class: 'select-participants co-info select-favorite-collection'
      });
      modelRef.content.event.subscribe(() => {
        video.isFavorite = true;
      });
    } else {
      this.removeFav(video);
    }
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: remove favorites
  *********************************************************/
  removeFav(video) {
    const modalRef = this.modalService.show(DiscardSavePageComponent,
      {
        initialState: {
          message: this.messages.ERROR.ALERT.REMOVE_FAV,
          buttonName: this.messages.ERROR.ALERT.REMOVE_FAV_BTN,
          cancelButtonName: this.messages.ERROR.ALERT.REMOVE_FAV_CANCEL_BTN
        }, class: 'common-modal modal-dialog-centered'
      });
    modalRef.content.event.subscribe((res) => {
      if (res === true) {
        const params = {
          itemId: video._id,
          itemType: 'video',
          doFavorite: false
        };
        this.commonService.callApi(this.apiConstant.DO_FAV, params, 'post', false, false, false).then((success) => {
          if (success.status === this.statusCode.SUCCESS) {
            this.showErrorService.popToast('success', success.body.extra_meta.message);
            video.isFavorite = false;
          }
        });
      }
    });
  }
  /*********************************************************/

  /*********************************************************
 @PURPOSE: call delete video api
 *********************************************************/
  deleteVideo(id) {
    this.commonService.callApi(this.apiConstant.MY_VIDEOS + '/' + id, {}, 'delete', false, false, false, 'this video').then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        this.showErrorService.popToast('success', success.body.extra_meta.message);
        this.deleteEvent.emit();
        this.bsModalRef.hide();
      }
    });
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: navigate to video detail screen for editing
  *********************************************************/
  editVideo(id) {
    this.bsModalRef.hide();
    this.router.navigate([this.urlConstant.VIDEO_DETAILS, 'edit', id]);
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: promote video
  *********************************************************/
  promoteVideo(id) {
    this.bsModalRef.hide();
    this.router.navigate([this.urlConstant.MY_VIDEOS + '/' + this.paymentType.promotionalPackage, this.promotionalPackageType.video, this.promotionalPackageType.videoLabel, id]);
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: remove promote video
  *********************************************************/
  removePromote(video) {
    const modalRef = this.modalService.show(DiscardSavePageComponent,
      {
        initialState: {
          message: this.messages.ERROR.ALERT.REMOVE_PROMOTE_MSG + ' ' + this.promotionalPackageType.video + '?',
          buttonName: this.messages.ERROR.ALERT.REMOVE_PROMOTE_BTN,
          cancelButtonName: this.messages.ERROR.ALERT.REMOVE_PROMOTE_CANCEL_BTN
        }, class: 'common-modal modal-dialog-centered'
      });
    modalRef.content.event.subscribe((res) => {
      if (res === true) {
        const params = {
          itemId: video._id,
          itemType: this.promotionalPackageType.video
        }
        this.commonService.callApi(this.apiConstant.REMOVE_PROMOTE, params, 'post', false, false, false).then((success) => {
          if (success.status === this.statusCode.SUCCESS) {
            this.showErrorService.popToast('success', success.body.extra_meta.message);
            video.isPromoted = false;
          }
        });
      }
    })
  }
  /*********************************************************/

  navigateToOwnerDetail(video) {
    const type = video.identityType;
    const id = video.identityId;
    switch (type) {
      case this.identityType.investor: { this.router.navigate([this.urlConstant.INVESTOR_DETAIL, 0, id]); break; }
      case this.identityType.serviceProvider: { this.router.navigate([this.urlConstant.SP_DETAILS, 0, id]); break; }
      case this.identityType.startup: { this.router.navigate([this.urlConstant.INV_OPP_DETAILS, 0, id]); break; }
      case this.identityType.individual: { this.router.navigate([this.urlConstant.PEOPLE_DETAILS, 0, id]); break; }
    }
    this.bsModalRef.hide();
  }

  /*********************************************************
  @PURPOSE: open comments
  *********************************************************/
  openComments() {
    const comment = document.getElementById('content').classList;
    if (comment.contains('comments-fullview')) {
      comment.remove('comments-fullview');
    } else {
      comment.add('comments-fullview');
      this.getComments();
    }
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: get comments through api and load more pages if page param is true
  *********************************************************/
  getComments(page?) {
    const data = {
      videoId: this.id,
      page: page ? this.commentPageNo + 1 : 1
    };
    this.commonService.callApi(this.apiConstant.COMMENTS, data, 'get', false, false, false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        this.commentList = page ? this.commentList.concat(success.body.data) : success.body.data;
        this.totalComments = success.body.extra_meta.total;
        this.commentPageNo = success.body.extra_meta.current_page;
      }
    });
  }
  /*********************************************************/

  public noWhitespaceValidator(value) {
    const re = new RegExp(/^(\s+\S+\s*)*(?!\s).*$/);
    return re.test(value);
  }

  /*********************************************************
  @PURPOSE: add comment
  *********************************************************/
  addComment() {
    if (this.myComment && this.noWhitespaceValidator(this.myComment)) {
      const data = {
        comment: this.myComment,
        videoId: this.id
      };
      this.commonService.callApi(this.apiConstant.COMMENTS, data, 'post', false, false, false).then((success) => {
        if (success.status === this.statusCode.SUCCESS) {
          this.showErrorService.popToast('success', this.messages.SUCCESS.COMMENT);
          this.myComment = null;
          this.getVideoDetails();
          this.getComments();
          // document.getElementById('content').classList.contains('comments-fullview') ? this.getComments() : this.getVideoDetails();
        }
      });
    } else {
      this.showErrorService.popToast('error', this.messages.ERROR.REQUIRED.comment);
    }
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: show edit input to edit comment/reply
  *********************************************************/
  showEditInput(comment) {
    comment.edit = true;
    comment.text = comment.comment;
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: edit comment/reply
  *********************************************************/
  editComment(comment) {
    const param = {
      comment: comment.text
    };
    this.commonService.callApi(this.apiConstant.COMMENTS + '/' + comment._id, param, 'patch', false, false, false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        this.showErrorService.popToast('success', success.body.extra_meta.message);
        comment.edit = false;
        this.getComments();
      }
    });
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: delete comment/reply
  in case its reply, then it will have parent comment and it should be updated and displayed
  *********************************************************/
  deleteComment(id, parentComment?) {
    this.commonService.callApi(this.apiConstant.COMMENTS + '/' + id, {}, 'delete', false, false, false, parentComment ? 'this reply' : 'this comment').then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        this.showErrorService.popToast('success', success.body.extra_meta.message);
        this.getVideoDetails();
        this.getComments();
        if (parentComment) { this.getReplies(parentComment); }
      }
    });
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: get replies for specific comment and load more replies if page is true
  *********************************************************/
  getReplies(comment, page?) {
    const data = {
      videoId: this.id,
      page: page ? comment.currentPage + 1 : 1,
      parentId: comment._id
    };
    this.commonService.callApi(this.apiConstant.COMMENTS, data, 'get', false, false, false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        comment.replyList = page ? comment.replyList.concat(success.body.data) : success.body.data;
        comment.showReply = true;
        comment.totalReply = success.body.extra_meta.total;
        comment.currentPage = success.body.extra_meta.current_page;
        const index = this.commentList.findIndex(x => x._id === comment._id);
        this.commentList[index] = comment;
      }
    });
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: add reply
  *********************************************************/
  addReply(comment) {
    if (comment && this.noWhitespaceValidator(comment.reply)) {
      const data = {
        comment: comment.reply,
        videoId: this.id,
        parentId: comment._id
      };
      this.commonService.callApi(this.apiConstant.COMMENTS, data, 'post', false, false, false).then((success) => {
        if (success.status === this.statusCode.SUCCESS) {
          this.showErrorService.popToast('success', this.messages.SUCCESS.COMMENT);
          comment.reply = null;
          this.getVideoDetails();
          this.getComments();
          setTimeout(() => { this.getReplies(comment); }, 0);
        }
      });
    } else {
      this.showErrorService.popToast('error', this.messages.ERROR.REQUIRED.comment);
    }
  }
  /*********************************************************/

  reportComment(id) {
    this.modalService.show(ReportComponent, { backdrop: 'static', initialState: { itemId: id, itemType: 'comment' }, class: 'common-modal modal-dialog-centered edit-about-modal' });
  }

  closeModal() {
    this.bsModalRef.hide();
    this.event.emit(this.videoDetail);
  }

  /*********************************************************
    @PURPOSE: Follow page
    *********************************************************/
  followPage(identityId: string, identitytype: string, doFollow: boolean) {
    this.followUnfollowPage(identityId, identitytype, doFollow);
  }

  /*********************************************************/


  /*********************************************************
  @PURPOSE: UnFollow page
  *********************************************************/
  unfollowPage(identityId: string, identitytype: string, doFollow: boolean) {
    const modalRef = this.modalService.show(DiscardSavePageComponent,
      {
        initialState: {
          message: this.messages.ERROR.ALERT.UNFOLLOW,
          buttonName: this.messages.ERROR.ALERT.UNFOLLOW_BTN,
          cancelButtonName: this.messages.ERROR.ALERT.UNFOLLOW_CANCEL_BTN
        }, class: 'common-modal modal-dialog-centered'
      });
    modalRef.content.event.subscribe((res) => {
      if (res === true) {
        this.followUnfollowPage(identityId, identitytype, doFollow)
      }
    });
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: Calling API for Follow and unFollowing the page
  *********************************************************/
  followUnfollowPage(identityId: string, identitytype: string, doFollow: boolean) {
    const body = {
      "identityId": identityId,
      "identityType": identitytype,
      "doFollow": doFollow
    };

    this.commonService.callApi(this.apiConstant.PAGE_FOLLOW, body, 'post', false, false, false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        this.getVideoDetails()
      }
    });
  }

  /*********************************************************/

  /*********************************************************
@PURPOSE: send and remove connection request
*********************************************************/
  sendRemoveRequest(userId: string, doConnect: boolean, isConnectedStatus: number) {
    if (isConnectedStatus == 0) {
      this.addRemoveRequest(userId, doConnect)
    } else if (isConnectedStatus == 1) {
      const modalRef = this.modalService.show(DiscardSavePageComponent,
        {
          initialState: {
            message: this.messages.ERROR.ALERT.REMOVE_CONNECTION_REQUEST,
            buttonName: this.messages.ERROR.ALERT.REMOVE_CONNECTION_BTN,
            cancelButtonName: this.messages.ERROR.ALERT.REMOVE_CONNECTION_CANCEL_BTN
          }, class: 'common-modal modal-dialog-centered'
        });
      modalRef.content.event.subscribe((res) => {
        if (res === true) {
          this.addRemoveRequest(userId, doConnect)
        }
      });

    } else if (isConnectedStatus == 2) {
      const modalRef = this.modalService.show(DiscardSavePageComponent,
        {
          initialState: {
            message: this.messages.ERROR.ALERT.REMOVE_CONNECTION,
            buttonName: this.messages.ERROR.ALERT.REMOVE_CONNECTION_BTN,
            cancelButtonName: this.messages.ERROR.ALERT.REMOVE_CONNECTION_CANCEL_BTN
          }, class: 'common-modal modal-dialog-centered'
        });
      modalRef.content.event.subscribe((res) => {
        if (res === true) {
          this.addRemoveRequest(userId, doConnect)
        }
      });
    }


  }
  /*********************************************************/


  /*********************************************************
  @PURPOSE: Calling API to add and remove connection request
  *********************************************************/
  addRemoveRequest(userId: string, doConnect: boolean) {
    if (this.checkCredit(doConnect)) {
      const body = {
        "userId": userId,
        "doConnect": doConnect
      };
      this.commonService.callApi(this.apiConstant.CONNECTION_REQUEST, body, 'post', false, false, false).then((success) => {
        if (success.status === this.statusCode.SUCCESS) {
          this.getVideoDetails();
          if (doConnect) {
            let connectionRequests = success.body.extra_meta.connectionRequests;
            this.subscriptionFeatures.features.connectionRequests = connectionRequests;
            this.localStorageService.setToken('subscriptionFeatures', JSON.stringify(this.subscriptionFeatures));
          }
        }
      });
    }
  }
  /*********************************************************/

  checkCredit(doConnect) {
    if (!doConnect) {
      return true
    } else {
      if (!this.subscriptionFeatures.features.connectionRequests) {
        const modalRef = this.modalService.show(InsuffiecientWarnComponent,
          {
            initialState: {
              title: this.messages.ERROR.ALERT.INSUF_CONN_TITLE,
              message: this.messages.ERROR.ALERT.INSUF_CONN_MSG,
              buttonName: this.messages.ERROR.ALERT.INSUF_CONN_BTN
            }, class: 'common-modal modal-dialog-centered'
          });
        modalRef.content.event.subscribe((res) => {
          if (res === true) {
            this.router.navigate([this.urlConstant.SETTINGS + '/' + this.paymentType.oneTimePackage, this.oneTimePackageType.connReq, 'Connection']);
          }
        });
      } else {
        return true
      }
    }
  }

  shareVideo(video) {
    let videoUrl = 'home/home-feed/' + video._id;
    this.modalService.show(ShareComponent, { initialState: { videoUrl, type: 'video' }, class: 'edit-milestone-modal' })
  }
}
