<div #domPortalContent *ngIf="showWindow" class="video-upload-wrap">
    <kendo-window class = "visible" title="Uploading Video" (close)="openClose()" [resizable]="false" [width]="400">
        <div>
            <ul>
                <li *ngFor="let file of files">
                    <ng-container *ngIf = "file.showInWindow">
                        <div class="info-btn-wrap">
                            <span>{{file?.file.name}}</span>
                            <button (click)="goToDetail(file)" *ngIf="file.status"
                                class="btn btn-sm btn-primary btn-update-details">Update Details</button>
                        </div>
                        <progressbar [value]="file.uploadProgress" [max]="100">{{file.status ? 'Uploaded' : 'Uploading'}} &nbsp;
                            <!-- {{file.uploadProgress?file.uploadProgress:0}} % -->
                        </progressbar>
                    </ng-container>
                </li>
            </ul>
        </div>
    </kendo-window>
</div>