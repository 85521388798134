<div class="forgot-password-modal-card modal-card sm-modal-card">
    <button class="popup-close-btn btn" (click)="bsModalRef.hide()">
        <span class="icon-icon-close"></span>
    </button>
    <h3>
        Email ID or Mobile Number
    </h3>
    <p>
        Email ID or Mobile Number is mandatory to create your account and this information is not retrieved from your
        social media profile due to your privacy settings.Please enter and verify it manually.
    </p>
    <form class="form" [formGroup]="emailMobileform" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="form-group">
            <label>Email ID or Mobile Number</label>
            <input type="text" class="form-control" formControlName="emailId" placeholder="Email ID or Mobile Number">
            <span class="text-danger"
                *ngIf="(emailMobileform.controls.emailId.touched || submitted) && emailMobileform.controls.emailId.errors?.required">
                {{messages.ERROR.REQUIRED.emailId}}
            </span>
            <span class="text-danger"
                *ngIf="(emailMobileform.controls.emailId.touched || submitted) && emailMobileform.controls.emailId.errors?.invalidInput">
                {{messages.ERROR.PATTERN.emailId}}
            </span>
        </div>
        <div class="btnbox">
            <button class="btn btn-primary">Send</button>
        </div>
    </form>
</div>