import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VideoProcessingService } from 'src/app/common/services/service/video-processing.service';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styles: [
  ]
})
export class ImageCropperComponent implements OnInit {

  // image
  public file: any;
  imageChangedEvent;
  croppedImage = '';
  public sliceSize: any;
  public imageURL = '';

  public event: EventEmitter<any> = new EventEmitter();


  constructor(private modalService: BsModalService, public modalRef: BsModalRef, private videoprocessingService: VideoProcessingService,) { }

  ngOnInit(): void {
  }

  imageCropped(event) {
    this.croppedImage = event.base64;

    const ImageURL = event.base64;
    // Split the base64 string in data and contentType
    const block = ImageURL.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

    // Convert it to a blob to upload
    this.videoprocessingService.b64toBlob(realData, contentType).then((file: File) => {
      this.file = file;

    });

  }

  imageLoaded() { /* show cropper */ }

  loadImageFailed() { /* show message */ }

  submitcroppedImage() {
    this.event.emit(this.file);
    this.modalRef.hide();
  }

}
