import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { msgType } from 'src/app/common/constants/constant';
import { DialogService } from 'src/app/common/services/service/dialog.service';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { MessageService } from 'src/app/common/services/service/message.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { QBHelper } from 'src/app/common/services/TS-files/qbHelper';
import { qbConst } from 'src/assets/config/qb.config';
import { environment } from 'src/environments/environment';
import { CoverVideoPopupComponent } from '../../modals/cover-video-popup/cover-video-popup.component';
import { ViewPdfComponent } from '../../modals/view-pdf/view-pdf.component';

@Component({
  selector: 'app-common-message-chat',
  templateUrl: './common-message-chat.component.html',
  styles: []
})
export class CommonMessageChatComponent implements AfterViewInit {

  qbConst = qbConst;
  imgUrl = environment.imgUrl;
  msgType = msgType;


  @Input() message: any = [];
  @Input() selectedChatOverview;
  @Input() dialog;
  @ViewChild('element') el: ElementRef;

  // dialog;
  qbId;

  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private localStorageService: LocalStorageService,
    private modalService: BsModalService,
    private qbHelper: QBHelper,
    private showErrorService: ShowErrorService
  ) {
    this.qbId = this.localStorageService.getToken('qbId');
  }

  ngAfterViewInit() {
    if (this.message.visibilityEvent) {
      this.el.nativeElement['dataset'].messageId = this.message._id;
      this.el.nativeElement['dataset'].userId = this.message.sender_id;
      this.el.nativeElement['dataset'].dialogId = this.message.chat_dialog_id;
      this.messageService.intersectionObserver.observe(this.el.nativeElement);
    }
  }

  visibility(e) {
    this.dialogService.dialogs[e.detail.dialogId].unread_messages_count--;
    this.dialogService.dialogsEvent.emit(this.dialogService.dialogs);
    this.messageService.intersectionObserver.unobserve(this.el.nativeElement);
    this.messageService.messages = this.messageService.messages.map(message => {
      if (message._id === e.detail.messageId) {
        message.visibilityEvent = false;
      }
      return message;
    });
  }

  loadImagesEvent(e) {
    let img: any, container: Element, imgPos: number, scrollHeight: number;
    img = e.target;
    container = document.querySelector('.msg-chatbox');
    // @ts-ignore
    imgPos = container.offsetHeight + container.scrollTop - img.offsetTop;
    scrollHeight = container.scrollTop + img.offsetHeight;

    img.classList.add('loaded');

    if (imgPos >= 0) {
      container.scrollTop = scrollHeight + 5;
    }
  }

  openAttachment(attachment, isMultiple?, initialSlide?) {
    switch (isMultiple ? attachment[0].type : attachment.type) {
      case this.qbConst.ATTACHMENT.VIDEO_TYPE: {
        this.modalService.show(CoverVideoPopupComponent,
          { initialState: { data: { coverVideo: attachment.src }, isAtt: true, isChat: true }, class: 'introduction-wrap modal-dialog-centered' });
        break;
      }
      case this.qbConst.ATTACHMENT.IMG_TYPE: {
        this.modalService.show(CoverVideoPopupComponent,
          { initialState: { data: { coverVideo: isMultiple ? attachment : attachment.src }, isImg: true, isAtt: true, isChat: true, isMultiple: isMultiple ? true: false, initialSlide: isMultiple ? initialSlide : 0  }, class: 'introduction-wrap modal-dialog-centered' });
        break;
      }
      case this.qbConst.ATTACHMENT.PDF_TYPE: {
        this.modalService.show(ViewPdfComponent, { initialState: { pdf: attachment.src, isChat: true }, class: 'introduction-wrap modal-dialog-centered' });
        break;
      }
    }
  }

  downloadPdf(attachment) {
    this.qbHelper.qbDownload(attachment.id).
      then((data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);

        window.open(fileURL);
        var a = document.createElement('a');
        a.href = fileURL;
        a.download = attachment.name;
        document.body.appendChild(a);
        a.click();
      }).
      catch(err => {
        this.showErrorService.popToast('error', err);
      });
  }
}
