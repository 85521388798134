<div class="modal-card modal-sm rating-pop-up-wrap" style="margin-top: 182px;">
    <div class="text-center rating-pop-up">
        <button class="popup-close-btn btn" style="margin-right: -32px" (click)="modalRef.hide()"><span
                class="icon-icon-close"></span></button>
    </div>
    <div class="text-center mt-3">
        <h4 class="primary-text fw-sb">Share {{(type == 'job') ? 'Job' : (type == 'video') ? 'Video' : 'Page'}}</h4><br>
        <share-buttons [theme]="'material-dark'"
            [include]="['facebook','twitter','whatsapp','linkedin','email','reddit','messenger','copy']" [show]="9"
            [size]="1" [autoSetMeta]="false" [url]="url" [description]="description">
        </share-buttons>
    </div>
</div>