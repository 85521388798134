import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { statusCode } from 'src/app/common/constants/constant';
import { messages } from 'src/app/common/constants/messages';
import { CommonService } from 'src/app/common/services/service/common.service';
import { CustomValidationService } from 'src/app/common/services/service/custom-validation.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styles: []
})
export class ReportComponent implements OnInit {

  apiConstant = APIConstants;
  statusCode = statusCode;
  messages = messages;

  reportForm: FormGroup;
  submitted = false;
  itemId;
  itemType;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private commonService: CommonService,
    public showErrorService: ShowErrorService,
    private customValidationService: CustomValidationService
  ) { }

  ngOnInit(): void {
    this.reportForm = this.fb.group({
      reason: ['', [Validators.required, Validators.maxLength(500), this.customValidationService.validateEmptyField]]
    });
  }

  /*********************************************************
  @PURPOSE: save reason
  *********************************************************/
  report() {
    if (this.reportForm.valid) {
      const data = {
        itemId: this.itemId,
        itemType: this.itemType,
        reason: this.reportForm.controls.reason.value
      };
      this.commonService.callApi(this.apiConstant.REPORT, data, 'post', false, false, false).then((success) => {
        if (success.status === this.statusCode.SUCCESS) {
          this.showErrorService.popToast('success', success.body.extra_meta.message);
          this.bsModalRef.hide();
        }
      });
    } else {
      this.submitted = true;
    }
  }
  /*********************************************************/
}
