import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Routing
import { AppRoutingModule } from './app-routing.module';

// Shared
import { SharedModule } from './shared/shared.module';

// Plugins
import { SocialLoginModule, AuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';

// Services
import { ServiceModule } from './common/services/service.module';

// Component
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { NgxUiLoaderConfig, NgxUiLoaderModule, SPINNER } from "ngx-ui-loader";
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

const config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('3594301170794195')
    // 421711045553340   508820923608421 client 3594301170794195
  }
]);
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#0e378c",
  bgsType: SPINNER.doubleBounce,
  blur: 3,
  fgsColor: "#0e378c",
  fgsSize: 100,
  fgsType: SPINNER.ballSpinFadeRotating,
  pbColor: "#0e378c",
  pbThickness: 6,
};

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    SharedModule,
    ServiceModule,
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SocialLoginModule,
    CommonModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AutocompleteLibModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
