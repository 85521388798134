import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { environment } from './../../../../environments/environment';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { SharedServices } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class VideoProcessingService {

  apiConstant = APIConstants;
  imgUrl = environment.imgUrl;

  file;
  video;
  sliceSize;

  public videoFile: any;
  public videofileext = "";
  public videofilename = "";


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private commonService: CommonService,
    private showErrorService: ShowErrorService,
    private sharedService: SharedServices
  ) { }

  public setVideo(vid) {
    this.video = vid;
  }

  public getVideo() {
    return this.video;
  }

  public promptForVideo(): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      // make file input element in memory
      const fileInput: HTMLInputElement = this.document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'video/mp4';
      fileInput.addEventListener('error', event => {
        reject(event.error);
      });
      fileInput.addEventListener('change', event => {
        resolve(fileInput.files[0]);
      });
      // prompt for video file
      fileInput.click();
    });
  }

  public generateThumbnail(videoFile: Blob): Promise<string> {
    const video: HTMLVideoElement = this.document.createElement('video');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    return new Promise<string>((resolve, reject) => {
      canvas.addEventListener('error', reject);
      video.addEventListener('error', reject);
      video.addEventListener('canplay', event => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        resolve(canvas.toDataURL());
      });
      if (videoFile.type) {
        video.setAttribute('type', videoFile.type);
      }
      video.preload = 'auto';
      video.src = window.URL.createObjectURL(videoFile);
      video.load();
    });
  }

  async processFile(file, name?) {
    file = await this.getBase64(file).then(data => { return data });
    return this.b64toBlob(file.split(';')[1].split(',')[1], file.split(';')[0].split(':')[1], name ? name : '');
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  b64toBlob(b64Data, contentType, fileName?) {
    contentType = contentType || '';
    const sliceSize = 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    this.file = new File([blob], (fileName ? (fileName + '.') : 'uploaded_file.') + contentType.split('/')[1], { type: contentType, lastModified: Date.now() });
    return new Promise((resolve, reject) => {
      resolve(this.file);
    });
  }

  submitFile(file, hasThumb, type?, blob?, id?) {
    const fd = new FormData();
    if (type == 'video') {
      fd.append('video', file);
      fd.append('thumbnail', blob);
    } else {
      fd.append('file', file);
      if (type == 'docFile') {
        fd.append('parentId', id)
      } else {
        fd.append('hasThumb', hasThumb);
      }
    }
    const method = (type == 'video') ? this.apiConstant.VIDEO_UPLOAD : (id) ? this.apiConstant.DOCUMENTS + '/' + 'upload-file' : this.apiConstant.FILE_UPLOAD;
    return this.commonService.callApi(method, fd, 'post', false, true).then((success) => {
      if (success.status === 200) {
        return success;
      } else {
        this.showErrorService.displayToastr('error', success.message);
      }
    });
  }

  public setVideoFileConfig(file, route?, showInWindow?, startTime?, duration?) {
    this.videoFile = file;
    let splittedF = this.videoFile.name.split(".");
    let fileExt = splittedF[splittedF.length - 1];
    let filename = splittedF.slice(0, -1).join('.');
    this.videofileext = fileExt;
    this.videofilename = filename;
    this.uploadVideo(route, showInWindow, startTime, duration);
  }
  n
  public uploadVideo(route?, showInWindow?, startTime?, duration?) {
    this.sharedService.setNewFile(this.videoFile)
    this.getVideoFile(route, showInWindow, startTime, duration);
  }


  public async getVideoFile(route?, showInWindow?, startTime?, duration?) {
    let filelength = 0
    this.sharedService.getFiles().subscribe(async filesl => {
      filelength = filesl.length
    })
    this.sharedService.getNewFile().subscribe(async res => {
      if (res && res.name) {
        let filen = res.fname || res.name
        let checkedstatus = await this.checkfilestatus(res);

        if (checkedstatus) {
          if (!checkedstatus.shouldUpload) {
            return true
          }

          let uploadedChunksSize = checkedstatus.uploadedChunksSize

          let uploadId = checkedstatus.uploadId
          let fName = checkedstatus.fileName;

          let chunksnumber;

          if ((res.size/1000000) > 50) {
            chunksnumber = 10;
          } else {
              for (let i = 10; i > 1; i--) {
                if (((res.size / i) > 5000000) && (res.size % i) == 0 ) {
                  chunksnumber = i;
                  break;
                }
              }
          }
          const chunks = await this.createFileChunks(res, chunksnumber, uploadedChunksSize);

          let spilittedext = filen.split(".");
          spilittedext.pop();
          spilittedext = spilittedext.join();

          let removingchunk = checkedstatus.uploadedChunks.length + chunks.length > chunksnumber ? checkedstatus.uploadedChunks.pop() : checkedstatus.uploadedChunks

          const fileChunks = chunks.map((chunk, index) => ({
            chunk,
            chunkIndex: removingchunk.length + index,
            chunkSize: chunk.size
          }));

          let skip = 0
          let Mchunk = Math.ceil(res.size / chunksnumber);
          while (uploadedChunksSize > skip) {
            skip += Mchunk
          }

          if (skip > uploadedChunksSize) {
            uploadedChunksSize = skip - Mchunk
          }


          let obj = {
            id: filelength,
            key: spilittedext,
            chunks: fileChunks,
            file: res,
            isSuccess: false,
            isFail: false,
            url: "",
            uploadedChunks: [],
            uploadedChunksSize: uploadedChunksSize,
            fileext: this.videofileext,
            filetype: "",
            fileSize: res.size,
            route: route,
            uploadProgress: 0,
            showInWindow: showInWindow ? showInWindow : false,
            fname: fName,
            uploadId: uploadId,
            startTime: startTime ? startTime : '',
            duration: duration ? duration : ''
          }
          this.videofileext = ""
          this.videofilename = ""
          this.sharedService.setNewFile({})
          this.videoFile = ""
          this.sharedService.setModel(false)
          this.sharedService.setFilePro(obj)
        }
      }
    })
  }

  public async checkfilestatus(file) {

    let period = file.name.lastIndexOf('.');
    let fileName = file.name.substring(0, period);
    let fileExtension = file.name.substring(period + 1);

    let body = { fileName: fileName + '_' + file.lastModified + '.' + fileExtension }
    const response = await this.commonService.callApi(this.apiConstant.VIDEO_STATUS, body, 'post', false, false).then((success) => {
      return success.body;
    })
    return response.data;
  }

  public async createFileChunks(file: File, num: any, size = 0) {
    const fileChunks = [];
    let skip = 0
    let Mchunk = Math.ceil(file.size / num);
    while (size > skip) {
      skip += Mchunk
    }

    if (skip > size) {
      skip = skip - Mchunk
    }

    const chunkSize = Math.ceil(file.size / num);

    while (skip < file.size) {
      fileChunks.push(file.slice(skip, skip + chunkSize));
      skip += chunkSize;
    }
    return fileChunks;
  }
}
