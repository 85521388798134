import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { messages } from 'src/app/common/constants/messages';
import { statusCode } from 'src/app/common/constants/constant';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/common/services/service/common.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { AddCollectionComponent } from 'src/app/auth-modules/profile/modal/add-collection/add-collection.component';

@Component({
  selector: 'app-select-fav-collection',
  templateUrl: './select-fav-collection.component.html',
  styles: []
})
export class SelectFavCollectionComponent implements OnInit {

  urlConstant = URLConstants;
  apiConstant = APIConstants;
  messages = messages;
  statusCode = statusCode;
  imgUrl = environment.imgUrl;

  itemId;
  itemType;
  collectionList = [];
  selectedCol;
  doFavorite = true;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private commonService: CommonService,
    private showErrorService: ShowErrorService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.getCollection();
  }

  getCollection() {
    this.commonService.callApi(this.apiConstant.MY_FAV, {}, 'get', false, false, false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        this.collectionList = success.body.data.list;
      }
    });
  }

  addCollection() {
    const modelRef = this.modalService.show(AddCollectionComponent, {backdrop: 'static', class: 'common-modal modal-dialog-centered'});
    modelRef.content.event.subscribe(() => {
      this.getCollection();
    });
  }

  done() {
    if (this.selectedCol) {
      const params = {
        collectionId: this.selectedCol._id,
        itemId: this.itemId,
        itemType: this.itemType,
        doFavorite: this.doFavorite
      };
      this.commonService.callApi(this.apiConstant.DO_FAV, params, 'post', false, false, false).then((success) => {
        if (success.status === this.statusCode.SUCCESS) {
          this.showErrorService.popToast('success', success.body.extra_meta.message);
          this.bsModalRef.hide();
          this.event.emit();
        }
      });
    } else {
      this.showErrorService.popToast('error', this.messages.ERROR.REQUIRED.collection);
    }
  }
}
