import { LocalStorageService } from './../../../common/services/service/local-storage.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { messages } from './../../../common/constants/messages';
import { URLConstants } from './../../../common/constants/routerLink-constants';
import { ShowErrorService } from './../../../common/services/service/show-error.service';
import { VideoProcessingService } from './../../../common/services/service/video-processing.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedServices } from 'src/app/common/services/service/shared.service';
import { DomPortal } from '@angular/cdk/portal';
import { environment } from 'src/environments/environment';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { Router } from '@angular/router';
import { DiscardSavePageComponent } from '../discard-save-page/discard-save-page.component';

@Component({
  selector: 'app-video-upload-progress',
  templateUrl: './video-upload-progress.component.html',
  styles: []
})
export class VideoUploadProgressComponent implements OnInit {

  apiConstant = APIConstants;
  API_URL = environment.apiUrl;
  urlConstant = URLConstants;
  messages = messages;

  @ViewChild('domPortalContent')
  domPortalContent!: ElementRef<HTMLElement>;
  domPortal!: DomPortal<any>;
  public isOpenModel: boolean = false
  public files: any;
  public setOngoingRequests: any
  public windowTop = 100;
  public windowLeft = 50;
  public opened = false;

  public showWindow = false;

  constructor(
    public sharedService: SharedServices,
    public videoService: VideoProcessingService,
    private showErrorService: ShowErrorService,
    private router: Router,
    private modalService: BsModalService,
    private localStorageService: LocalStorageService
  ) {
    this.getFilePro()
  }

  ngOnInit(): void {
    this.domPortal = new DomPortal(this.domPortalContent);
    this.sharedService.getFiles().subscribe(res => {
      this.files = res
      if (res.length == 1) {
        if (this.files.some(x => x.showInWindow)) { this.showWindow = true; }
        this.uploadSync();
      }
    })
  }

  public getFilePro() {
    this.sharedService.getFilePro().subscribe(res => {
      if (res && res.key) {
        let fileproceed = res
        if (this.files.find((f: any) => f.key == res.key)) {
          this.sharedService.setFilePro({});
        } else {
          this.files.push(fileproceed)
          this.sharedService.setFiles(this.files);
        }

      }
    })
  }

  public async uploadSync() {
    const parentQueue = (file: any, i: any) => {
      if ((file && i == 0 && !file.status) || (this.files[i - 1]?.status && !this.files[i - 1]?.error)) {
        let ctoup = file ? file.chunks
          .filter((chunk: any) => !file.uploadedChunks.includes(`${file.key}-${chunk.chunkIndex}`)) : []
        if (ctoup.length) {
          const childQueue = (chunk: any, j: any) => {
            const formData = new FormData();
            formData.append('fileName', file.fname);
            formData.append('uploadId', file.uploadId);
            formData.append('chunk', chunk?.chunk);
            formData.append('chunkSize', chunk.chunkSize);
            formData.append('partNumber', chunk.chunkIndex + 1);
            formData.append('fileHash', file.key);
            formData.append('chunkHash', `${file.key}-${chunk.chunkIndex}`);

            this.futch({
              url: this.API_URL + this.apiConstant.LARGE_VIDEO_UPLOAD,
              data: formData,
              onUploadProgress: (e: any) => this.handleUploadProgress(e, file.key, ctoup, file.uploadedChunks, file.file, chunk, file.uploadedChunksSize),
            }).then(async result => {
              if (ctoup.length - 1 == j) {
                let resp: any = await this.futch({
                  url: this.API_URL + this.apiConstant.MERGE_VIDEO,
                  headers: { 'content-type': 'application/json', Authorization: this.localStorageService.getToken('accessToken') },
                  data: JSON.stringify({
                    fileName: file.fname,
                    uploadId: file.uploadId,
                    fileHash: file.key,
                    fileext: file.fileext,
                    filetype: file.filetype,
                    lang: file.lang,
                    fileSize: file.fileSize,
                    startTime: file.startTime,
                    duration: file.duration
                  }),
                });
                if (resp['error']) {
                  this.showErrorService.popToast('error', resp['error'].message);
                } else {
                  this.files[i].status = true
                  this.files[i].filePath = resp.data.filePath;
                  if (this.files.length - 1 == i) {
                    // this.files = [];
                    this.fileUploaded(this.files[i]);
                    // props.fileupload({type:"FILE_UPLOAD_DONE"})
                  } else {
                    parentQueue(this.files[i + 1], i + 1)
                  }
                }
              } else {
                childQueue(ctoup[j + 1], j + 1)
              }


            }).catch(err => {
              if (ctoup.length - 1 == j) {
                this.files[i].error = true
                if (this.files.length - 1 == i) {
                  // this.fileUploaded(this.files[i]);
                  // props.fileupload({type:"FILE_UPLOAD_DONE"})
                  // this.files = []
                } else {
                  parentQueue(this.files[i + 1], i + 1)
                }

              } else {
                childQueue(ctoup[j + 1], j + 1)
              }
            })
          }
          childQueue(ctoup[0], 0)
        } else {
          if (this.files.length - 1 == i) {
            this.fileUploaded(this.files[i]);
            // props.fileupload({type:"FILE_UPLOAD_DONE"})
            // this.files = []
          }
        }

      } else {
        if (this.files.length - 1 == i) {
          // props.fileupload({type:"FILE_UPLOAD_DONE"})
          // this.files = []
          this.fileUploaded(this.files[i]);
        } else {
          parentQueue(this.files[i + 1], i + 1)
        }
      }
    }
    parentQueue(this.files[0], 0)
  }


  public handleUploadProgress(e: any, fileHash: any, chunks: any, uploadChunks = [], file: any, chunkobj: any, uploadedChunksSize: any) {

    let alreadyuploadedperc = uploadedChunksSize > 0 ? (uploadedChunksSize / file.size) * 100 : 0

    const percentage = (e.loaded / e.total) * 100;

    let iValue = alreadyuploadedperc > 0 ? chunkobj.chunkIndex - uploadChunks.length : chunkobj.chunkIndex

    let prog = alreadyuploadedperc + (percentage / chunks.length) + (chunks.length * iValue)
    this.files.forEach((file: any) => {
      if (file.key === fileHash) {
        file.uploadProgress = prog.toFixed(2);
        this.sharedService.setUploadProgress(file);
      }
    });
    // props.fileupload({type:"FILE_UPLOAD",files:files})
  }

  public futch(option: any) {
    const { url, method = 'POST', headers, data, onUploadProgress } = option;
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = e => onUploadProgress?.(e);

      xhr.onload = () => {
        // remove finished xhr
        this.setOngoingRequests?.((ongoingRequests: any) => ongoingRequests.filter((r: any) => r !== xhr));

        try {
          resolve(JSON.parse(xhr.response));
        } catch (error) {
          resolve(xhr.response);
        }
      };

      xhr.open(method, url);

      if (headers) {
        Object.keys(headers).forEach(key => xhr.setRequestHeader(key, headers[key]));
      }
      xhr.send(data);
      // add xhr to ongoing request list
      this.setOngoingRequests?.((ongoingRequests: any) => [...ongoingRequests, xhr]);
    });
  }


  public openClose() {
    const modalRef = this.modalService.show(DiscardSavePageComponent,
      {
        initialState: {
          message: this.messages.ERROR.ALERT.CLOSE_UPLOAD,
          buttonName: this.messages.ERROR.ALERT.CLOSE_UPLOAD_BTN,
          cancelButtonName: this.messages.ERROR.ALERT.CLOSE_UPLOAD_CANCEL_BTN
        }, class: 'common-modal modal-dialog-centered'
      });
    modalRef.content.event.subscribe((res) => {
      if (res === true) {
        this.sharedService.setModel(false)
        this.showWindow = false;
        this.files = [];
      }
    });
  }

  public fileUploaded(file) {
    this.showErrorService.popToast('success', this.messages.SUCCESS.VIDEO_UPLOAD);

  }

  goToDetail(file) {
    if (this.files.length == 1) {
      this.showWindow = false;
      this.files = [];
    } else {
      this.files.splice(file.id, 1);
    }
    this.videoService.setVideo({ videoPath: file.filePath, size: file.fileSize });
    if (file.route) {
      this.router.navigate([file.route]);
    }
  }

}
