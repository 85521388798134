import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { identityType, msgType, oneTimePackageType, paymentType, statusCode } from 'src/app/common/constants/constant';
import { messages } from 'src/app/common/constants/messages';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { CommonService } from 'src/app/common/services/service/common.service';
import { DialogService } from 'src/app/common/services/service/dialog.service';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { ScrollWindowService } from 'src/app/common/services/service/scroll-window.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { environment } from 'src/environments/environment';
import { InsuffiecientWarnComponent } from '../../modals/insuffiecient-warn/insuffiecient-warn.component';
import { SelectIdentityComponent } from '../../modals/select-identity/select-identity.component';

@Component({
  selector: 'app-common-message-connection',
  templateUrl: './common-message-connection.component.html',
  styles: []
})
export class CommonMessageConnectionComponent implements OnInit {

  @ViewChild('loadingConnectionList') loadingConnectionList: ElementRef;
  statusCode = statusCode;
  apiConstant = APIConstants;
  messages = messages;
  imgUrl = environment.imgUrl;
  urlConstant = URLConstants;
  oneTimePackageType = oneTimePackageType;
  paymentType = paymentType;
  msgType = msgType;
  identityType = identityType;

  connectionConfig = {
    page: 1,
    isRequestList: false,
  };

  lastPage = 1;
  loadMore = true;
  apiLoader = true;
  identityList;
  selectedIdentity;
  loggedInUserId;

  public componentPosition: any;

  connectionList = [];
  subscriptionFeatures;

  showList = false;

  constructor(
    private commonService: CommonService,
    public scrollTop: ScrollWindowService,
    public router: Router,
    private showErrorService: ShowErrorService,
    private modalService: BsModalService,
    @Inject(PLATFORM_ID) private platformId,
    public localStorageService: LocalStorageService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.getConnectionList();
    this.getIdentities();
    this.loggedInUserId = this.localStorageService.getToken('userId');
    this.subscriptionFeatures = JSON.parse(this.localStorageService.getToken('subscriptionFeatures'));
    if (!this.subscriptionFeatures) {
      this.getCurrentSubscription
    }
  }

  getCurrentSubscription() {
    this.commonService.callApi(this.apiConstant.CURRENT_SUB_FEATURE, '', 'get', false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        this.subscriptionFeatures = success.body.data;
        this.localStorageService.setToken('subscriptionFeatures', JSON.stringify(this.subscriptionFeatures));
      }
    })
  }

  /*********************************************************
  @PURPOSE: get loggedin user identities
  *********************************************************/
  getIdentities() {
    this.commonService.callApi(this.apiConstant.SELECT_IDENTITY, {}, 'get', false, false, false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        this.identityList = success.body.data.list;
        this.selectedIdentity = this.identityList.find(x => x.identityType === this.identityType.individual);
      }
    });
  }
  /*********************************************************/

  /******************************************************
  @PURPOSE: Getting Connection listing
  *******************************************************/
  getConnectionList(pagination?) {
    const params = this.connectionConfig;
    params.page = pagination ? this.connectionConfig.page : 1;
    this.commonService.callApi(this.apiConstant.CONNECTION, params, 'get', false, false).then((success) => {
      if (success.status == statusCode.SUCCESS) {
        this.connectionList = pagination ? (this.connectionList.concat(success.body.data)) : success.body.data;
        this.apiLoader = true;
        this.connectionConfig.page = success.body.extra_meta.current_page;
        this.lastPage = success.body.extra_meta.last_page;
      }
    });
  }
  /*********************************************************************/

  selectChatIdentity(sendInvite, person) {
    const modalRef = this.modalService.show(SelectIdentityComponent,
      { backdrop: 'static', initialState: { selectedIdentity: this.selectedIdentity, identityList: this.identityList }, class: 'common-modal select-identity-modal modal-dialog-centered' });
    modalRef.content.event.subscribe((res) => {
      this.selectedIdentity = res.data;
      this.doChat(sendInvite, person)
    });
  }

  checkMsgCredit(sendInvite) {
    if (sendInvite) {
      return true
    } else {
      if (!this.subscriptionFeatures.features.chatCredits) {
        const modalRef = this.modalService.show(InsuffiecientWarnComponent,
          {
            initialState: {
              title: this.messages.ERROR.ALERT.INSUF_CHAT_TITLE,
              message: this.messages.ERROR.ALERT.INSUF_CHAT_MSG,
              buttonName: this.messages.ERROR.ALERT.INSUF_CHAT_BTN
            }, class: 'common-modal modal-dialog-centered'
          });
        modalRef.content.event.subscribe((res) => {
          if (res === true) {
            this.router.navigate([this.urlConstant.SETTINGS + '/' + this.paymentType.oneTimePackage, this.oneTimePackageType.chatCredit, 'Message']);
          }
        });
      } else {
        return true
      }
    }

  }

  doChat(sendInvite, person) {
    if (this.checkMsgCredit(sendInvite)) {
      const params = {
        senderIdentityType: this.selectedIdentity.identityType,
        senderIdentityId: this.selectedIdentity.identityId,
        senderUserId: this.loggedInUserId,
        receiverIdentityType: this.identityType.individual,
        receiverIdentityId: person._id,
        receiverUserId: person._id,
        type: this.msgType.personal,
        sendMessageInvite: sendInvite
      }
      this.commonService.callApi(this.apiConstant.DO_CHAT, params, 'post', false, false, false, false, false, true).then((success) => {
        if (success.status == this.statusCode.SUCCESS) {
          this.dialogService.setDialogWindow(true);
          this.dialogService.currentDialog = success.body.data;
          this.dialogService.currentDialogEvent.emit(success.body.data);
          this.dialogService.$windowDialogDetails.emit(success.body.data);
          if (!sendInvite) {
            let chatCredits = success.body.extra_meta.chatCredits;
            this.subscriptionFeatures.features.chatCredits = chatCredits;
            this.localStorageService.setToken('subscriptionFeatures', JSON.stringify(this.subscriptionFeatures));
            const filter = {
              limit: 100,
              sort_desc: 'updated_at'
            };

            this.dialogService.getDialogs(filter)
              .then((res) => {
                if (res) {
                  res['items'].forEach((chat, index, self) => {
                    if (chat.xmpp_room_jid) {
                      this.dialogService.joinToDialog(chat);
                    }
                    self[index].last_message_date_sent = +chat.last_message_date_sent * 1000;
                  });
                  this.dialogService.setDialogs(res['items']);
                }
              });
          }
        }
      });
    }
  }

  /*********************************************************
  @PURPOSE: Get more data on scroll
  *********************************************************/
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.loadingConnectionList) {
        this.componentPosition = this.loadingConnectionList.nativeElement.offsetTop - 200;
        const scrollPosition = window.pageYOffset;
        const currentPage = this.connectionConfig.page;
        const lastPage = this.lastPage;
        if ((scrollPosition >= this.componentPosition) && this.loadMore) {
          if ((currentPage < lastPage) && this.apiLoader) {
            this.loadMore = false;
            this.apiLoader = false;
            this.connectionConfig.page = this.connectionConfig.page + 1;
            this.getConnectionList();
          }
        } else {
          this.loadMore = true;
        }
      }
    }
  }
  /*********************************************************/
}
