import { Component, EventEmitter, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common/services/service/common.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { statusCode } from 'src/app/common/constants/constant';
import { messages } from 'src/app/common/constants/messages';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import * as moment from 'moment';
import { BackhomeService } from 'src/app/common/services/service/backhome.service';

@Component({
  selector: 'app-discard-save-page',
  templateUrl: './discard-save-page.component.html',
  styles: []
})
export class DiscardSavePageComponent implements OnInit {

  messages = messages;
  apiConstant = APIConstants;
  statusCode = statusCode;
  urlConstant = URLConstants;
  public data;
  public returnDataProcess: any = {};
  public dataprocess: any = {};
  public submitteddata: any = {};
  public method: any;
  public api: any;

  message: string;
  buttonName: string;
  cancelButtonName: string;
  public event: EventEmitter<any> = new EventEmitter();

  planDetails;
  additionalMsg;
  additionalMsg2;
  showCancelBtn = true;

  constructor(private commonService: CommonService, public fb: FormBuilder,
    public showErrorService: ShowErrorService, public modalRef: BsModalRef, public router: Router,
    public activatedRoute: ActivatedRoute, public localstorageservice: LocalStorageService, public homeservice: BackhomeService) { }

  ngOnInit(): void {
  }

  /*********************Discard and Save the Page ******************/
  discardSavePage(type) {
    this.returnDataProcess = {};
    if (type == 'discard') {
      if (this.cancelButtonName == 'No') {
        this.modalRef.hide();
      }
      if (this.message) {
        const modals = document.body.getElementsByTagName('modal-container').length;
        this.modalRef.hide();
        this.event.emit(false);
        // handling scroll when one of the two modals are closed
        setTimeout(() => {
          if (modals == 2) {
            const body = document.getElementsByTagName('body')[0];
            body.classList.add('modal-open');
          }
        }, 800);
      } else {
        this.modalRef.hide();
        if (this.data) {
          if (this.data.browserType == 'browsersp') {
            this.router.navigate([this.urlConstant.SP_DETAILS, 0, this.localstorageservice.getToken('spId')]);
          } else if (this.data.browserType == 'browserinv') {
            this.router.navigate([this.urlConstant.INVESTOR_DETAIL, 0, this.localstorageservice.getToken('invId')]);
          } else if (this.data.browserType == 'browserstartup') {
            this.router.navigate([this.urlConstant.INV_OPP_DETAILS, 0, this.localstorageservice.getToken('startupId')]);
          } else {
            this.router.navigate([this.urlConstant.MY_PAGES]);
          }
        }
      }
    } else if (type == 'save') {
      if (this.message) {
        this.event.emit(true);
        this.modalRef.hide();
      } else {
        if (this.data) {
          if (this.data.formStatus) {
            this.callApi(this.data);
          } else if (this.data.data == true) {
            this.modalRef.hide();
            if (this.data.browserType == 'browsersp') {
              this.router.navigate([this.urlConstant.SP_DETAILS, 0, this.localstorageservice.getToken('spId')]);
            } else if (this.data.browserType == 'browserinv') {
              this.router.navigate([this.urlConstant.INVESTOR_DETAIL, 0, this.localstorageservice.getToken('invId')]);
            } else if (this.data.browserType == 'browserstartup') {
              this.router.navigate([this.urlConstant.INV_OPP_DETAILS, 0, this.localstorageservice.getToken('startupId')]);
            } else {
              this.router.navigate([this.urlConstant.MY_PAGES]);
            }
          } else {
            this.modalRef.hide();
            this.formValid();
          }
        } else {
          this.router.navigate([this.urlConstant.MY_PAGES]);
        }
      }
    }
  }
  /******************************************************************/

  callApi(value) {
    if (value.formStatus) {
      if (value.companiesArr) {
        value.companiesArr.forEach((i) => {
          if (i.rounds) {
            i.rounds.forEach(element => {
              if ((element.exitDate == 'Invalid date') || (element.exitDate == undefined) || (element.exitDate == "")) {
                element.exitDate = '';
              } else {
                element.exitDate = moment(new Date(element.exitDate)).format('YYYY-MM-DD')
              }
              element.investmentDate = moment(new Date(element.investmentDate)).format('YYYY-MM-DD')
            });
          }
        })
      }
      if (value.data) {
        this.returnDataProcess = value.data;
      }
      if (value.companiesArr && (value.lastStep == 3) || (value.lastStep == 4)) {
        this.returnDataProcess['currentInvestment'] = value.companiesArr;
      }
      if (value.params.identityType)
        this.returnDataProcess['identityType'] = value.params.identityType;
      if (value.params.identityId) {
        this.returnDataProcess['identityId'] = value.params.identityId;
      }
      if (value.params.type)
        this.returnDataProcess['type'] = value.params.type;
      this.returnDataProcess['lastStep'] = value.lastStep;
      if (value.subPageType)
        this.returnDataProcess['subPageType'] = value.subPageType
      this.returnDataProcess['pageType'] = (value.params.pageType) ? value.params.pageType : 'company';
      if ((this.localstorageservice.getToken('steptitle') == 'Add Team Members') || (this.localstorageservice.getToken('steptitle') == 'Portfolio') || (this.localstorageservice.getToken('steptitle') == 'Prepackages') || (this.localstorageservice.getToken('steptitle') == 'Social Media Links')) {
        this.localstorageservice.removeToken('submitted');
      }
      if (value.lastStep == 1) {
        if (value.params.identityId) {
          this.api = this.apiConstant.MYPAGES + '/' + value.params.identityId;
          this.method = 'put'
        } else {
          this.api = this.apiConstant.MYPAGES;
          this.method = 'post'
        }
      } else {
        this.api = this.apiConstant.MYPAGES + '/' + this.localstorageservice.getToken('pageId');
        this.method = 'put'
      }
      this.commonService.callApi(this.api, this.returnDataProcess, this.method, false, false).then((success) => {
        if (success.status === statusCode.SUCCESS) {
          this.showErrorService.displayToastr('success', 'Saved successfully')
          this.localstorageservice.removeToken('submitted');
          this.localstorageservice.removeToken('currentstep');
          this.localstorageservice.removeToken('steptitle');
          if (this.data.browserType == 'browsersp') {
            this.router.navigate([this.urlConstant.SP_DETAILS, 0, this.localstorageservice.getToken('spId')]);
          } else if (this.data.browserType == 'browserinv') {
            this.router.navigate([this.urlConstant.INVESTOR_DETAIL, 0, this.localstorageservice.getToken('invId')]);
          } else if (this.data.browserType == 'browserstartup') {
            this.router.navigate([this.urlConstant.INV_OPP_DETAILS, 0, this.localstorageservice.getToken('startupId')]);
          } else {
            this.router.navigate([this.urlConstant.MY_PAGES]);
          }
          this.modalRef.hide();
        }
      })
    } else {
      this.modalRef.hide();
      this.formValid();
    }
  }
  formValid() {
    this.localstorageservice.setToken('submittedvalue', true);
    if (this.localstorageservice.getToken('steptitle') == 'Personal Info' || this.localstorageservice.getToken('steptitle') == 'About Yourself') {
      this.submitteddata['submittedPersonal'] = true;
    } else if (this.localstorageservice.getToken('steptitle') == 'Investment Criteria') {
      this.submitteddata['submittedInvestment'] = true;
    } else if (this.localstorageservice.getToken('steptitle') == 'Investment Details') {
      this.submitteddata['submittedInvestmentDetails'] = true;
    } else if (this.localstorageservice.getToken('steptitle') == 'About Your Company') {
      this.submitteddata['submittedCompany'] = true;
    } else if (this.localstorageservice.getToken('steptitle') == 'Funding Details') {
      this.submitteddata['submittedFunding'] = true;
    } else if (this.localstorageservice.getToken('steptitle') == 'About Your Investment Teaser') {
      this.submitteddata['submittedInvTeaser'] = true;
    }
    this.homeservice.setSubmitted(this.submitteddata)
    this.showErrorService.displayToastr('error', 'Please enter required details properly')
  }
}
