export const APIConstants = {

    FILE_UPLOAD: 'user/fileUpload',
    VIDEO_UPLOAD: 'user/fileVideoUpload',
    FILE_DELETE: 'user/fileDelete',

    VIDEO_STATUS: 'user/filestatus',
    LARGE_VIDEO_UPLOAD: 'user/uploadfiles',
    MERGE_VIDEO: 'user/mergefiles',

    /*** Onboarding Screen APIs */
    REGISTER: 'user/users/register',
    LOGIN: 'user/users/login',
    LOGOUT: 'user/users/logout',
    FORGOT_PASSWORD: 'user/users/forgotPassword',
    VERIFY_OTP: 'user/users/verifyOTP',
    RESEND_OTP: 'user/users/resendOTP',
    RESET_PASSWORD: 'user/users/resetPassword',
    SOCIAL_ID_VERIFY: 'user/users/checkSocialId',
    LINKEDINPROFILE: 'user/getLinkedInProfile',
    UPDATE_SHORT_PROFILE: 'user/users/updateShortProfile',
    GET_USER_PROFILE: 'user/users/profile',
    INTEREST: 'user/user-interests',
    INTRO: 'user/users/intro',
    SUMMARY: 'user/users/summary',
    EXPERIENCE: 'user/experiences',
    EDUCATION: 'user/education',
    LICENSE: 'user/licenses',
    USERS: 'user/users',
    POSITION_DROPDOWN: 'user/experiences/drop-down',
    GET_PAGE_SUGGESTION: 'retrival/business-pages', // for auto suggestion
    REMOVE_FOLLOWERS: 'retrival/page-follower-remove',
    PAGE_FOLLOW: 'retrival/page-follow',
    GET_PAGE_FOLLOWERS: 'retrival/page-followers',
    TEAMMEMBERS_REQUEST: 'retrival/team-member-requests/',
    SELECT_IDENTITY: 'retrival/user-identities',
    GET_PERMITTED_IDENTITY: 'retrival/get-permitted-identities',
    INDUSTRY: 'retrival/industries',
    SUBINDUSTRY: 'retrival/sub-industries',
    INVESTMENT_STAGES: 'retrival/funding-stages',
    MYPAGES: 'retrival/my-pages',
    BUSINESS_PAGES: 'retrival/business-pages',
    SENDINVITE: 'retrival/send-invite',
    TEAMMEMBERS: 'retrival/team-members',
    LANGUAGES: 'retrival/languages',
    SKILLS: 'retrival/skills',
    OTHER_PAGES: 'retrival/page-detail/other-pages',
    STARTUP: 'retrival/startup',
    GET_STARTUP_LIST: 'retrival/get-startups',
    PORTFOLIO: 'retrival/portfolio',
    PREPACKAGES: 'retrival/pre-packages',
    INVESTOR: 'retrival/startup-investor',
    STARTUP_DOCS: 'retrival/startup',
    SP_WORK_LIST: 'retrival/work-history/',
    /** Video */
    GET_MY_VIDEOS: 'user/get-my-videos',
    MY_VIDEOS: 'user/my-videos',
    /** Investor */
    GET_INVESTORS: 'retrival/get-investors',

    FEED: 'retrival/get-feeds',
    GET_FEED: 'retrival/feeds/', // get feed by id
    LIKE: 'retrival/likes/like',
    REMOVE_LIKE: 'retrival/likes/remove-like',
    COMMENTS: 'retrival/comments',
    REPORT: 'retrival/report-entity',
    GET_SP_LIST: 'retrival/get-service-providers',

    GET_NETWORK_LIST: 'user/get-people',
    CONNECTION_REQUEST: 'user/user-connect',
    GLOBAL_SEARCH: 'retrival/global-search-list',

    MEETING: 'user/meeting',
    MEET_CALENDER: 'user/meeting-calendar',
    CHECK_EMAIL_USER: 'user/checkUser',
    LINK_GOOGLE: 'user/meeting/link-google-account',

    CONNECTION: 'user/user-connections',
    ACCEPT_CON: 'user/user-connect-accept',

    TEAM_MEMBER_REQ: 'retrival/team-member-requests',

    MY_FAV: 'user/favorite-collections',
    FAV_DOC_DATA: 'user/favorite-documents',
    DO_FAV: 'retrival/do-user-favorite',

    SUBSCRIPTION_LIST: 'user/subscription/subscription-packages',
    CURRENT_SUB_FEATURE: 'user/subscription/current-features',
    CREDIT: 'user/subscription/credit-transactions',
    CHANGE_EMAIL_MOBILE: 'user/change-email-mobile',
    CHANGE_PASSWORD: 'user/change-password',
    CLOSE_ACCOUNT: 'user/close-account',
    CLOSE_REASON: 'retrival/close-account-reasons',
    CREDIT_CARDS: 'user/credit-cards',
    BANK_ACCOUNT: 'user/bank-accounts',
    BANK_VERIFY: 'user/subscription/account-link',
    COUNTRY: 'retrival/countries',
    CUURENCY: 'retrival/currencies',
    VERIFY_IDENTITY_ACCOUNTS: 'user/verify-identity-accounts',
    VERIFY_PAGE: 'user/verify-identity-request',
    VERIFY_ACCR_ACCOUNTS: 'user/verify-accreditation-accounts',
    VERIFY_ACCREDITATION: 'user/verify-accreditation-request',
    GET_PRIVACY: 'user/privacy-settings',
    PRIVACY: 'user/change-privacy',

    DOCUMENTS: 'user/documents',
    DOC_SEARCH: 'user/documents-search',
    DOC_STORAGE: 'user/documents/storage',
    CREATE_DIR: 'user/documents/create-dir',
    UPLOAD_DOC: 'user/documents/upload-file',
    DOC_SEARCH_IDENTITY: 'user/documents/search-identity',
    SHARE_DOC: 'user/documents/share',
    SHARED_LIST: 'user/documents/shared-list',
    MOV_TO_PUBLIC: 'user/documents/move-to-public',
    SHARED_WITH_ME: 'user/documents/shared-with-me',
    SHARED_WITH_MY_IDENTITY: 'user/documents/shared-with-my-identity',
    SHARED_WITH_MY_IDENTITY_FROM: 'user/documents/shared-with-my-identity-from',
    SHARED_DOC_VIEWED: 'user/documents/shared-doc-viewed',


    NOTIFICATION_LIST: 'user/notifications',
    HEADER_NOTIFICATION_LIST: 'user/notifications/drop-down',
    NOTIFICATION_COUNT: 'user/notifications/count',

    JOBS: 'job/jobs',
    BROWSE_JOBS: 'job/browse-jobs',
    SERVICE_PROVIDER_IDENTITIES: 'job/jobs/create/service-provider-identities',
    APPLY_SERVICE_PROVIDER_IDENTITIES: 'job/jobs/apply/service-provider-identities',
    POSTING_JOBS: 'job/job-postings',
    GET_PERMITTED_IDENTITIES: 'retrival/get-permitted-identities',
    SET_MILESTONES: 'job/jobs/milestones',
    INVITE_JOBS: 'job/jobs/invite-list',
    CHANGE_STATUS_MILESTONES: 'job/jobs/milestones/approve-action',
    JOB_PROPOSALS: 'job/jobs/proposals-list',
    UPLOAD_DELIVERABLE_MILESTONES: 'job/jobs/milestones/upload-deliverables',
    APPROVE_MILESTONE_FILES: 'job/jobs/milestones/approve-files',
    JOB_PAYMNENT_INTENT: 'job/jobs/payment-intent',
    JOB_CONFIRM_PAYMENT: 'job/jobs/confirm-payment',
    INVITE_IDENTITIES: 'job/jobs/invite-identities/',
    SEND_OFFER_JOB: 'job/jobs/send-offer/',

    DO_CHAT: 'user/messages/do-chat',
    BLOCK_LIST: 'user/messages/block-list',
    BLOCK: 'user/messages/block-identity',
    ACCEPT_REJECT_CHAT: 'user/messages/rsvp',
    CHAT_OVERVIEW: 'user/messages/chat-overview',
    GET_IDENTITY: 'user/messages/get-identity-info',   // get identity info of user from message identity

    MSG_LISTING_QB: 'chat/Dialog.json',

    // CMS
    GET_CMS_PAGE: 'user/getCmsPage',

    SUBSCRIPTION_INTENT: 'user/subscription/stripe-subscription-intent',
    SUBSCRIBE_PLAN: 'user/subscription/subscribe-plan',

    ADD_ON_SUBS_INTENT: 'user/subscription/addon-subscription-intent',
    ADD_ON_SUBS_PLAN: 'user/subscription/addon-subscribe-plan',
    CANCEL_ADD_ON: 'user/subscription/cancel-addon-subscription',

    ONE_TIME_PACKAGES: 'user/subscription/one-time-packages',
    STRIPE_PAY_INTENT: 'user/subscription/stripe-payment-intent',
    CONFIRM_PAYMENT: 'user/subscription/confirm-payment',

    PROMOTIONAL_PACKAGE: 'user/subscription/promotional-packages',
    PROMOTE: 'user/subscription/promote',
    REMOVE_PROMOTE: 'user/subscription/promote-remove',
    PROMOTE_ITEM_VIEWED: 'user/subscription/promote-item-viewed'
};
