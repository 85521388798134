import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

// Auth Guard
import { CanLoginActivate, CanAuthActivate } from './common/services/TS-files/auth.gaurd';

const routes: Routes = [
  {
    path: '',
    canActivate: [CanLoginActivate],
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule)
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    loadChildren: () => import('./auth-modules/auth-modules.module').then((m) => m.AuthModulesModule)
  },
  {
    path: 'cms',
    loadChildren: () => import('./cms-modules/cms-modules.module').then((m) => m.CMSModulesModule)
  },
  { path: '**', redirectTo: 'home-page' }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    useHash: false,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule],
  providers: [CanLoginActivate, CanAuthActivate]
})
export class AppRoutingModule { }
