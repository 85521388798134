import { Component, EventEmitter, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common/services/service/common.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { statusCode } from 'src/app/common/constants/constant';
import { messages } from 'src/app/common/constants/messages';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import * as moment from 'moment';
import { BackhomeService } from 'src/app/common/services/service/backhome.service';

@Component({
  selector: 'app-insuffiecient-warn',
  templateUrl: './insuffiecient-warn.component.html',
  styles: []
})
export class InsuffiecientWarnComponent implements OnInit {

  messages = messages;
  urlConstant = URLConstants;

  title: string;
  message: string;
  buttonName: string;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public fb: FormBuilder,
    public showErrorService: ShowErrorService,
    public modalRef: BsModalRef,
    public router: Router,
    public activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {}

  buy() {
    this.modalRef.hide();
    this.event.emit(true);
  }

  close() {
    this.modalRef.hide();
  }


}
