import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationService {

  constructor() { }

  patternValidator(regex): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const pattern = new RegExp(regex);
      const valid = pattern.test(control.value);
      return valid ? null : { invalidPattern: true };
    };
  }

  validateEmail(email) {
    const re = new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i);
    return re.test(email);
  }

  validateMobile(phone) {
    const re = new RegExp(/^\+[0-9]{8,15}$/);
    return re.test(phone);
  }

  validateEmailMobile(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      return (this.validateEmail(control.value) || this.validateMobile(control.value)) ? null : { invalidInput: true };
    };
  }

  /**
  * Password validator using pattern
  * @param form control
  * @output boolean
  */
  passwordPatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      /**
       * Regex to validate password that must contain at least three character categories among the following
       * a-z
       * A-Z
       * 0-9
       * special chars `~:;'\"|\\-!@#$%^?&*()_[\/\\]{},.<>+=
       */
      const regex = new RegExp('^((?=.*[A-Z])(?=.*[a-z])(?=.*\\d)|(?=.*[a-z])(?=.*\\d)(?=.*[`~:;\'"|\\-!@#$%^?&*()_[\/\\]{},.<>+=])|(?=.*[A-Z])(?=.*\\d)(?=.*[`~:;\'"|\\-!@#$%^?&*()_[\/\\]{},.<>+=])|(?=.*[A-Z])(?=.*[a-z])(?=.*[`~:;\'"|\\-!@#$%^?&*()_[\/\\]{},.<>+=])).{8,16}$', 'g');
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  matchPassword(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    };
  }

  validateOldNewPassword(oldPassword: string, newPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[oldPassword];
      const confirmPasswordControl = formGroup.controls[newPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }

      if (passwordControl.value === confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    };
  }

  public noWhitespaceValidator() {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const pattern = new RegExp(/^(\s+\S+\s*)*(?!\s).*$/);
      const valid = pattern.test(control.value);
      return valid ? null : { whiteSpace: true };
    };
  }

  public validateEmptyField(c: FormControl) {
    return c.value && !c.value.trim() ? {
      required: {
        valid: false
      }
    } : null;
  }

  /*** @validate location is valid or not
  * location must be selected from google result and have valid lat-long
  * @input locationControlName
  * @input latlongControlName
  * @output setError
  **/

  validateLocation(locationControlName: string, latlongControlName: string) {
    return (formGroup: FormGroup) => {
      const locationControl = formGroup.controls[locationControlName];
      const latlongControl = formGroup.controls[latlongControlName];
      if (locationControl.value) {
        if (locationControl.errors && !locationControl.errors.locationNotValid) {
          // return if another validator has already found an error on the location field
          return;
        }
        // set error on location field if validation fails
        if (!latlongControl.value) {
          locationControl.setErrors({ locationNotValid: true });
        } else {
          locationControl.setErrors(null);
        }
      }
    }
  }
  validateArrayLocation(locationArr: string) {
    return (formGroup: FormGroup) => {

      setTimeout(() => {
        if (formGroup.controls[locationArr]['controls'][0]['controls'].latlong.errors && !formGroup.controls[locationArr]['controls'][0]['controls'].latlong.errors.locationNotValid) {
          // return if another validator has already found an error on the location field
          return;
        }
        if (!formGroup.controls[locationArr]['controls'][0]['controls'].latlong.value) {
          formGroup.controls[locationArr]['controls'][0]['controls'].latlong.setErrors({ locationNotValid: true });
        } else {
          formGroup.controls[locationArr]['controls'][0]['controls'].latlong.setErrors({ locationNotValid: false });
        }
      }, 500);
    }
  }
  /*********************************************************/
}
