import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class RestrictKeyPressService {

	constructor() { }

	/****************************************************************************
	@PURPOSE      : To restrict or allow some values in input.
	@PARAMETERS   : $event
	@RETURN       : Boolen
	****************************************************************************/
	RestrictSpace(e) {
		if (e.keyCode == 32) {
			return false;
		} else {
			return true;
		}
	}
	AllowUptoHundread(e) {
		if (Number(e.target.value) > 100) {
			return true
		}
	}
	AllowNumbers(e, type?) {
		if (type === 'zero') {
			if (!e.target.value.length && e.key <= 0) {
				return false;
			}
		}
		if (e.which == 39 || e.which == 37 || e.which == 46 || e.which == 17 || e.which == 188) {
			return true;
		}
		if (e.which > 95 && e.which < 106) {
			return true
		}
		if ((e.which > 47 && e.which < 58) && (e.key == '!' || e.key == '@' || e.key == '#' || e.key == '$' || e.key == '%' || e.key == '^' || e.key == '&' || e.key == '*' || e.key == '(' || e.key == ')')) {
			return false;
		}
		let input;
		if (e.metaKey || e.ctrlKey) {
			return true;
		}
		if (e.which === 32) {
			return false;
		}
		if (e.which === 0) {
			return true;
		}
		if (e.which < 33) {
			return true;
		}
		if (e.which === 43 || e.which === 45) {
			return true;
		}
		input = String.fromCharCode(e.which);
		return !!/[\d\s]/.test(input);
	}

	AllowAlphaNewmeric(e) {
		if ((e.keyCode > 64 && e.keyCode < 91) || (e.keyCode > 96 && e.keyCode < 123) || e.keyCode == 8 || e.keyCode == 37 || e.keyCode == 39 || (e.keyCode > 47 && e.keyCode < 58)) {
			return true;
		} else {
			return false;
		}
	}
	AllowChar(e, type?) {
		if ((e.keyCode > 64 && e.keyCode < 91) || (e.keyCode > 96 && e.keyCode < 123) || e.keyCode == 8 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 32) {
			return true;
		} else {
			return false;
		}
	}
	/****************************************************************************/
}
