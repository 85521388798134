import { messages } from './../../../common/constants/messages';
import { statusCode } from './../../../common/constants/constant';
import { VerifyOtpComponent } from './../verify-otp/verify-otp.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { APIConstants } from './../../../common/constants/callAPI-constants';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { CommonService } from 'src/app/common/services/service/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidationService } from 'src/app/common/services/service/custom-validation.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styles: []
})
export class ForgetPasswordComponent implements OnInit {

  apiConstant = APIConstants;
  statusCode = statusCode;
  messages = messages;

  forgetPassform: FormGroup;
  submitted = false;
  mobile: any;
  emailId: any;

  constructor(
    private commonService: CommonService,
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private customValidator: CustomValidationService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.forgetPassform = this.fb.group({
      emailId: ['', [Validators.required, Validators.maxLength(50), this.customValidator.validateEmailMobile()]]
    });
  }

  /*************************************************************
  @Purpose     : Validate on Submit and if valid then call login
  /*************************************************************/
  onSubmit() {
    if (this.forgetPassform.valid) {
      this.mobile = this.customValidator.validateMobile(this.forgetPassform.controls.emailId.value) ?
        this.forgetPassform.controls.emailId.value : '';
      this.emailId = this.customValidator.validateEmail(this.forgetPassform.controls.emailId.value) ?
        this.forgetPassform.controls.emailId.value : '';
      const data = {
        emailId: this.emailId,
        mobile: this.mobile
      };
      this.forgotPassword(data);
    } else {
      this.submitted = true;
    }
  }

  /*************************************************************
  @Purpose     : Call Forget Password API
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  forgotPassword(data) {
    this.commonService.callApi(this.apiConstant.FORGOT_PASSWORD, data, 'post', true, false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        const response = success.body.data;
        this.bsModalRef.hide();
        this.modalService.show(VerifyOtpComponent, {
          backdrop: 'static',
          initialState: {
            userId: response.userId,
            verificationCode: response.verificationCode,
            emailIdMobile: response.otpSentTo === 'email' ? this.emailId : this.mobile,
            fromForgotPass: true
          }
        });
      }
    });
  }
  /*************************************************************/

}
