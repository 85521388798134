import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { EventEmitter, Injectable } from '@angular/core';
import { QBHelper } from '../TS-files/qbHelper';
import { BehaviorSubject, Observable } from 'rxjs';

declare var QB: any;

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  public currentDialog: any = {};
  public dialogs: any = {};
  dialogsEvent: EventEmitter<any> = new EventEmitter();
  currentDialogEvent: EventEmitter<any> = new EventEmitter();

  public $showDialog = new BehaviorSubject<boolean>(false)
  public $windowDialogDetails : EventEmitter<any> = new EventEmitter()

  constructor(
    private qbHelper: QBHelper,
    private localStorageService: LocalStorageService,
    private showErrorService: ShowErrorService
  ) {
  }

  // get dialogs
  public getDialogs(args): Promise<any> {
    const self = this;
    return new Promise((resolve, reject) => {
      this.qbHelper.checkSession().then(() => {
        QB.chat.dialog.list(args, (err, resDialogs) => {
          if (err) {
            self.showErrorService.popToast('error', err);
            reject(err);
          } else {
            resolve(resDialogs);
          }
        });
      })
    });
  }

  getDialogById(id): Promise<any> {
    const self = this;
    return new Promise(function (resolve, reject) {
      QB.chat.dialog.list({ '_id': id }, function (err, res) {
        if (err) {
          self.showErrorService.popToast('error', err);
          reject(err);
        }
        const dialog = res.items[0];
        if (dialog) {
          resolve(dialog);
        }
      });
    });
  }


  public setDialogs(chats): any {
    const qbId = this.localStorageService.getToken('qbId');
    chats.forEach(item => {
      if (item.data) {
        if (item.user_id == qbId) {
          item.displayName = item.data.memberIdentityName;
          item.displayImg = item.data.memberIdentityPhoto;
          item.displayIdentity = item.data.ownerIdentityType;
        } else {
          item.displayName = item.data.ownerIdentityName;
          item.displayImg = item.data.ownerIdentityPhoto;
          item.displayIdentity = item.data.memberIdentityType;
        }
      }
    });
    this.dialogs = chats.reduce((obj, item) => {
      obj[item._id] = item;
      return obj;
    }, {});
    this.dialogsEvent.emit(this.dialogs);
  }

  public joinToDialog(dialog): Promise<any> {
    const qbId = this.localStorageService.getToken('qbId');
    return new Promise((resolve, reject) => {
      const jidOrUserId = dialog.xmpp_room_jid || dialog._id;
      this.qbHelper.checkSession().then(() => {
        QB.chat.muc.join(jidOrUserId, function (resultStanza) {
          for (let i = 0; i < resultStanza.childNodes.length; i++) {
            const elItem = resultStanza.childNodes.item(i);
            if (elItem.tagName === 'error') {
              return reject();
            }
          }
        });
        QB.chat.muc.listOnlineUsers(jidOrUserId, (resultStanza) => {
          const onlineStatus = resultStanza.some((id) => {
            return id != qbId;
          })
          resolve(onlineStatus);
        });
      });
    });
  }

  public setDialogParams(message) {
    const
      self = this,
      tmpObj = {};
    tmpObj[message.chat_dialog_id] = self.dialogs[message.chat_dialog_id];
    tmpObj[message.chat_dialog_id].last_message = message.message;
    tmpObj[message.chat_dialog_id].last_message_date_sent = message.date_sent;
    tmpObj[message.chat_dialog_id].last_message_id = message._id;
    tmpObj[message.chat_dialog_id].last_message_user_id = message.sender_id;
    if (!message.selfReaded) {
      tmpObj[message.chat_dialog_id].unread_messages_count++;
    }
    delete self.dialogs[message.chat_dialog_id];
    self.dialogs = Object.assign(tmpObj, self.dialogs);
    self.dialogsEvent.emit(self.dialogs);
  }

  setDialogWindow(value: boolean): void {
    this.$showDialog.next(value);
  }

  getDialogWindow(): Observable<any> {
    return this.$showDialog.asObservable();
  }

  setDialogWindowDetails(value: boolean): void {
    this.$windowDialogDetails.next(value);
  }

  getDialogWindowDetails(): Observable<any> {
    return this.$windowDialogDetails.asObservable();
  }

}
