<div class="introduction-modal shared-with-pdf">
    <div class="close-ic">
        <a href="javascript:void(0);" (click)="bsModalRef.hide()">
            <span class="icon-icon-close"></span>
        </a>
    </div>

    <div class="content-header">
        <h3>{{name}}</h3>
    </div>

    <div class="upload-img">
        <iframe [src]="viewPdf" title="upload-img"></iframe>
    </div>

</div>
 