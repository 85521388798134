export const environment = {
    production: true,
    environmentName: 'staging',
    port: 4043,
    apiUrl: 'https://unicornapi.inic.cloud/',
    imgUrl: 'https://d34gjrzkm20pbx.cloudfront.net/staging/',
    linkedin_redirect_url: 'https://unicorn.inic.cloud/home-page',
    linkedin_clientid: '86c5dt9s8y0rw0',
    share_url: 'https://unicorn.inic.cloud',
    qbAppId: 91827,
    qbAuthKey: 'Cn-5QSz8Xfhw5wq',
    qbAuthSecret: 'N9FcWppxSGWfyWA',
    qbAccountId: 126240,
    qbAccountKey: '1xBmM5z-x4S3dqpxtD6H',
    qbPassword: 'Unicorn@123',
    stripe_pub_key: 'pk_test_51JWhqELRizX9LtadZmrwJxsLOK8OWiA7ewvlPnDvrPF50wJ6JYJHjfXhivwQBkX7P1VXcu7qOkey3SSoTkgzU08u003U0h4OBd',
    stripe_secret_key: 'sk_test_51JWhqELRizX9Ltad1xflnwqYYZKweEhE7dIHyuOUS2lQykaUvhRdxF2Rr0eRdisGttKW4mhEapA0OvcpXXJzUprS00KzMWt1XQ',
    bank_redirect_url: 'https://unicorn.inic.cloud/settings/bank-account',
    google_calendar_client_id: '367539001722-cdk6bb63en3cfusmq5ukegtqvod41mmr.apps.googleusercontent.com',
    publicVapidKey: 'BDSqbzY50kcy_Kw-YbmdxV-F-0E8cFTxNKqdjjAuNa18m5alV293MZV6OzHVgnOenzgTGuwgBz1ObBnXpwQdZBw',
    privateVapidKey: 'AtQ3xdSxYTUsuR9Mhvv5zslteHY152e4J0iWjQTGcI4'
};
