import { messages } from './../../../common/constants/messages';
import { statusCode } from './../../../common/constants/constant';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { APIConstants } from './../../../common/constants/callAPI-constants';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidationService } from 'src/app/common/services/service/custom-validation.service';

@Component({
  selector: 'app-email-mobile',
  templateUrl: './email-mobile.component.html',
  styles: [
  ]
})
export class EmailMobileComponent implements OnInit {

  apiConstant = APIConstants;
  statusCode = statusCode;
  messages = messages;

  emailMobileform: FormGroup;
  submitted = false;
  mobile: any;
  emailId: any;
  login;
  register;
  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private customValidator: CustomValidationService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.emailMobileform = this.fb.group({
      emailId: ['', [Validators.required, Validators.maxLength(50), this.customValidator.validateEmailMobile()]]
    });
  }

  /*************************************************************
  @Purpose     : Validate on Submit and if valid then call login
  /*************************************************************/
  onSubmit() {
    if (this.emailMobileform.valid) {
      this.mobile = this.customValidator.validateMobile(this.emailMobileform.controls.emailId.value) ?
        this.emailMobileform.controls.emailId.value : '';
      this.emailId = this.customValidator.validateEmail(this.emailMobileform.controls.emailId.value) ?
        this.emailMobileform.controls.emailId.value : '';
      this.modalService.onHidden.emit({ emailId: this.emailId, mobile: this.mobile, login: this.login, register: this.register });
      this.bsModalRef.hide();
    } else {
      this.submitted = true;
    }
  }
}
