import { environment } from "src/environments/environment";

export const qbEndpoints = {
    endpoints: {
        'api_endpoint': 'https://api.quickblox.com/',
        'chat_endpoint': 'chat.quickblox.com',
        'turnserver_endpoint': 'turnserver.quickblox.com'
    },
    chatProtocol: {
        active: 2
    },
    notifications: {
        enabled: 0
    },
    streamManagement: {
        enable: true
    },
    debug: {
        mode: 0,
        file: null
    },
    on: {
        sessionExpired: function (e) {
        }
    },
};

export const qbAccount = {
    appId: environment.qbAppId,
    authKey: environment.qbAuthKey,
    authSecret: environment.qbAuthSecret,
    accountKey: environment.qbAccountKey,
    qbPassword: environment.qbPassword
};

export const qbConst = {
    ATTACHMENT: {
        BODY: '',
        IMG_TYPE: 'image',
        PDF_TYPE: 'file',
        VIDEO_TYPE: 'video',
        SIZE: 10 * 1000000
    },
}