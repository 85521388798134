import { blockIdentityType, statusCode } from './../../../common/constants/constant';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';

@Component({
  selector: 'app-select-identity',
  templateUrl: './select-identity.component.html',
  styles: []
})
export class SelectIdentityComponent implements OnInit {

  apiConstant = APIConstants;
  statusCode = statusCode;
  imgUrl = environment.imgUrl;
  identityType = blockIdentityType;

  identityList;
  selectedIdentity;
  isApplyJob = false;
  notSP = false;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef, public showErrorService: ShowErrorService
  ) { }

  ngOnInit(): void {
    this.selectedIdentity = this.selectedIdentity ? this.selectedIdentity : this.identityList[0];
  }

  submitIdenity() {
    if (this.isApplyJob && this.selectedIdentity.length == 0) {
      this.notSP = true;
    } else {
      this.bsModalRef.hide();
      this.event.emit({ data: this.selectedIdentity });
    }
  }
}
