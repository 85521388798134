<header *ngIf="linkedinHeader">
    <div class="container-fluid">
        <div class="nav-wrap">
            <div class="header-logo">
                <a href="javascript:void(0);">
                    <img src="../../../../assets/images/logo.svg" alt="Logo" width="172" height="25" />
                </a>
            </div>
        </div>
    </div>
</header>
<header *ngIf="publicHeader">
    <div class="container-fluid">
        <div class="nav-wrap">
            <div class="header-logo">
                <a href="javascript:void(0);">
                    <img src="../../../../assets/images/logo.svg" alt="Logo" width="172" height="25" />
                </a>
            </div>
            <div id="menu" class="menu">
                <div class="close-btnbox">
                    <a class="icon-icon-close-black" (click)="close()"></a>
                </div>
                <ul>
                    <li>
                        <a class="location-link" href="javascript:void(0);"
                            routerLink="{{ urlConstant.HOMEPAGE }}"><span class="location">Home</span>
                        </a>
                    </li>
                    <li><a href="javascript:;" routerLink="" (click)="openIntro()">INVESTMENT OPPORTUNITIES</a></li>
                    <li><a href="javascript:;" routerLink="" (click)="openIntro()">INVESTORS</a></li>
                    <li><a href="javascript:;" routerLink="" (click)="openIntro()">SERVICE PROVIDER</a></li>
                    <li><a href="javascript:;" routerLink="" (click)="openIntro()">NETWORK</a></li>
                    <li><a href="javascript:;" routerLink="" (click)="openIntro()">JOBS</a></li>
                </ul>
            </div>
            <div class="hdr-login-wrap">
                <a href="javascript:void(0);" routerLink="" (click)="openSignIn()">Sign In</a>
                <a href="javascript:void(0);" routerLink="" (click)="openSignUp()" class="btn btn-primary">Sign
                    up<span></span></a>
                <button class="btn befor-login-menu-btn" (click)="onMenuClick()">
                    <span class="icon-icon-nav"></span>
                </button>
            </div>

        </div>
    </div>
</header>

<header *ngIf="authHeader" class="nav-header">
    <div class="container-fluid">
        <div class="nav-login-wrap">
            <div class="header-logo">
                <div class="nav-icon">
                    <a href="javascript:void(0);" (click)="openSidebar()">
                        <span class="icon-icon-nav"></span>
                    </a>
                </div>
                <a href="javascript:void(0);" class="logo-nav" [routerLink]="[urlConstant.HOME]">
                    <img src="../../../../assets/images/logo.svg" alt="Logo" width="172" height="25" />
                </a>
            </div>
            <div class="header-search">
                <ng-select [(ngModel)]="searchText" placeholder="Search" [searchable]='true' [items]="searchList"
                    bindValue="identity" bindLabel="name" [editableSearchTerm]='true' [clearSearchOnAdd]='true'
                    [clearAllText]='' (keyup)="getSearchData($event)" (change)="onSearchSelect()"
                    (clear)="clearSearch()" (blur)="onBlur()" #searchEl>
                    <ng-template ng-option-tmp let-item="item">
                        <div class="img-box">
                            <img [src]="item.logo ? imgUrl + item.logo : '../../../../assets/images/gitfunded.svg'"
                                (error)="img3.src = '../../../../assets/images/gitfunded.svg'" #img3 alt="Profile">
                        </div>
                        {{item.name}}
                    </ng-template>
                </ng-select>
                <span class="icon-icon-search"></span>
            </div>
            <div class="header-right">
                <div class="header-mobile-search box-icon">
                    <div class="search-icon">
                        <a href="javascript:void(0);" (click)="toggleSearch()"><span
                                class="icon-icon-search"></span></a>
                    </div>
                    <div class="search-box">
                        <ng-select [(ngModel)]="searchText" placeholder="Search" [searchable]='true'
                            [items]="searchList" bindValue="identity" bindLabel="name" [editableSearchTerm]='true'
                            [clearSearchOnAdd]='true' [clearAllText]='' (keyup)="getSearchData($event)"
                            (change)="onSearchSelect(true)" (clear)="clearSearch()" (blur)="onBlur()" #searchElMob>
                            <ng-template ng-option-tmp let-item="item">
                                <div class="img-box">
                                    <img [src]="item.logo ? imgUrl + item.logo : '../../../../assets/images/gitfunded.svg'"
                                        (error)="img3.src = '../../../../assets/images/gitfunded.svg'" #img3
                                        alt="Profile">
                                </div>
                                {{item.name}}
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="video-box box-icon">
                    <a href="javascript:void(0);" [routerLink]="[urlConstant.MY_VIDEOS]">
                        <span class="icon-icon-add-video"></span>
                    </a>
                </div>
                <div class="notification-box box-icon">
                    <div class="btn-group" dropdown>
                        <a href="javascript:void(0);" id="my-notification" dropdownToggle class="notification-dropdown"
                            aria-controls="dropdown-notification">
                            <span class="icon-icon-notification" (click)="commonService.getNotificationList()"></span>
                            <em class="red-dots" *ngIf="commonService.notificationCount>0"></em>
                        </a>
                        <div id="dropdown-notification" *dropdownMenu
                            class="dropdown-menu dropdown-menu-lg  dropdown-menu-right" role="menu"
                            aria-labelledby="my-notification">
                            <div class="dropdown-header">
                                <h4>Notification</h4>
                                <a routerLinkActive="router-link-active"
                                    *ngIf="notificationRoute != urlConstant.NOTIFICATION_LIST"
                                    [routerLink]="[urlConstant.NOTIFICATION_LIST]">View
                                    All</a>
                            </div>
                            <div class="dropdown-notification-list" *ngIf="commonService.notificationList.length>0">
                                <a href="javascript:void(0);" class="dropdown-list-item"
                                    *ngFor="let i of commonService.notificationList">
                                    <div class="notification-info">
                                        <p style="cursor: pointer;" (click)="notificationChangeStatus(i.uuid,i.route,i)"
                                            [innerHtml]="i.original"></p>
                                        <span class="msg-time">{{(i.notificationType == 'Recent') ?
                                            moment(i.createdAt).format('LT') : moment(i.createdAt).fromNow()}}</span>
                                    </div>
                                </a>
                            </div>
                            <div class="dropdown-notification-list ml-5 mb-2"
                                *ngIf="commonService.notificationList.length==0">
                                No Notifications Found
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chat-box box-icon">
                    <div class="btn-group" dropdown #dropdown="bs-dropdown" (click)="goToMsg()">
                        <a href="javascript:void(0);" id="my-chat" dropdownToggle class="chat-dropdown"
                            aria-controls="dropdown-mychat">
                            <span class="icon-icon-msg"></span>
                            <em class="red-dots" *ngIf="chats.length"></em>
                        </a>
                        <div id="dropdown-mychat" *dropdownMenu
                            class="dropdown-menu dropdown-menu-lg  dropdown-menu-right" role="menu"
                            aria-labelledby="my-chat">
                            <div class="dropdown-header">
                                <h4>Messages</h4>
                                <a href="javascript:void(0);" [routerLink]="[urlConstant.MY_MESSAGES]">View All</a>
                            </div>
                            <div class="dropdown-chat-list">
                                <ng-container *ngFor="let chat of chats">
                                    <a href="javascript:void(0);" class="dropdown-list-item" (click)="goToDialog(chat)">
                                        <div class="profile-img">
                                            <img [src]="chat.displayImg ? imgUrl + chat.displayImg : '../../../../assets/images/gitfunded.svg'"
                                                (error)="imgM.src = '../../../../assets/images/gitfunded.svg'" #imgM
                                                [alt]="chat.displayName">
                                        </div>
                                        <div class="chat-info">
                                            <h5 class="text-ellipsis-2line">
                                                <span class="user-name" *ngIf="chat.data?.type != msgType.job">{{chat.displayName}}</span>
                                                <!-- <em class="black-dot" *ngIf="chat.data?.type == msgType.job"></em> -->
                                                {{chat.data?.type == msgType.job ? chat.data.jobTitle : ''}}
                                            </h5>
                                            <p class="text-ellipsis-1line">{{chat.last_message}}</p>
                                            <span class="msg-time" *ngIf="chat.last_message_date_sent">{{chat.last_message_date_sent | timeDate}}</span>
                                        </div>
                                        <div class="alert-msg"  *ngIf="chat.unread_messages_count > 0">
                                            <span>{{chat.unread_messages_count}}</span>
                                        </div>
                                    </a>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="user-profile">
                    <div class="btn-group" dropdown>
                        <a href="javascript:void(0);" id="my-profile" dropdownToggle class="profile-dropdown"
                            aria-controls="dropdown-myprofile">
                            <img [src]="localStorageService.getToken('photo') ? localStorageService.getToken('photo') : '../../../../assets/images/gitfunded.svg'"
                                (error)="img1.src = '../../../../assets/images/gitfunded.svg'" #img1 alt="Profile">
                            <span class="icon-icon-arrow-down"></span>
                        </a>
                        <ul id="dropdown-myprofile" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                            aria-labelledby="my-profile">
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.MY_PROFILE + '0']">My Profile</a></li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.MY_PAGES]">My Pages</a></li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.MY_PROFILE + '4']">My Favorites</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.MY_PROFILE + '2']">My Connections</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.MY_VIDEOS]">My Videos</a></li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.MEETING_LIST]">My Meetings</a></li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.DOCUMENT_LIST, 0]">My
                                    Documents</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    (click)="logout()">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- <app-sidebar *ngIf = "showSidebar" [showSidebar] = "showSidebar"
        (closed)="openSidebar()"></app-sidebar> -->
        <!-- <app-sidebar *ngIf = "commonService.hideSidebar"></app-sidebar> -->
        <!-- Start : Sidebar  -->
        <div class="siderbar-wrap">
            <div class="sidebar-menu-wrap">
                <div class="sidebar-inner">
                    <div class="logo-wrap">
                        <a href="javascript:void(0);" [routerLink]="[urlConstant.HOME]" (click)="closeSidebar()">
                            <img src="../../../../assets/images/logo.svg" alt="Logo" width="172" height="25" />
                        </a>
                        <a class="sidebar-close" (click)="closeSidebar()">
                            <span class="icon-icon-close-black"></span>
                        </a>
                    </div>
                    <ul class="sidebar-nav">
                        <li>
                            <a href="javascript:void(0);" [routerLink]="[urlConstant.HOME]" (click)="closeSidebar()">
                                <span class="icon-icon-home"></span> Home
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" [routerLink]="[urlConstant.INV_OPP_LIST]"
                                (click)="closeSidebar()">
                                <span class="icon-icon-opportunities"></span> Investment Opportunities
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" [routerLink]="[urlConstant.INVESTOR_LIST]"
                                (click)="closeSidebar()">
                                <span class="icon-icon-investor"></span> Investors
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" [routerLink]="[urlConstant.SP_LIST]" (click)="closeSidebar()">
                                <span class="icon-icon-service-provider"></span> Service Providers
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" [routerLink]="[urlConstant.PEOPLE_LIST]"
                                (click)="closeSidebar()">
                                <span class="icon-icon-connections"></span> Network
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" [routerLink]="[urlConstant.JOB_LIST,0]"
                                (click)="closeSidebar()">
                                <span class="icon-Icon-suitcase"></span> Jobs
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" [routerLink]="[urlConstant.MEETING_LIST]"
                                (click)="closeSidebar()">
                                <span class="icon-icon-meeting"></span> Meetings
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" (click)="closeSidebar()"
                                [routerLink]="[urlConstant.DOCUMENT_LIST, 0]">
                                <span class="icon-icon-document"></span> My Documents
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="user-profile-nav">
                    <a href="javascript:void(0);" class="profile-info" [routerLink]="[urlConstant.MY_PROFILE + '0']"
                        (click)="closeSidebar()">
                        <div class="profile-img">
                            <img [src]="localStorageService.getToken('photo') ? localStorageService.getToken('photo') : '../../../../assets/images/gitfunded.svg'"
                                (error)="img2.src = '../../../../assets/images/gitfunded.svg'" #img2 alt="Profile"
                                width="80" height="80">
                        </div>
                        <div class="user-info">
                            <h4>{{localStorageService.getToken('firstName')}}
                                {{localStorageService.getToken('lastName')}}</h4>
                            <p>{{localStorageService.getToken('emailId')}}</p>
                            <p>{{localStorageService.getToken('mobile')}}</p>
                        </div>
                    </a>
                    <div class="profile-setting">
                        <a href="javascript:void(0);"
                            (click)="openSetting()">
                            <span class="icon-icon-setting"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="sidebar-settings-wrap">
                <div class="sidebar-inner">
                    <div class="setting-header">
                        <a href="javascript:void(0);" (click)="closeSetting()">
                            <span class="icon-Icon-arrow-left"></span> Settings
                        </a>
                    </div>
                    <div class="setting-menu">
                        <a id="settingTop" href="javascript:void(0);" class="setting-item"
                            [routerLink]="[urlConstant.SUBSCRIPTION_SETTING]" (click)="closeSetting(); closeSidebar()">
                            <div class="setting-info">
                                <div class="setting-icon">
                                    <span class="icon-icon-subscription"></span>
                                </div>
                                <div class="setting-title">
                                    <h4>Subscription</h4>
                                    <p>Manage your subscriptions</p>
                                </div>
                            </div>
                            <div class="setting-view">
                                <span class="icon-icon-right-angle-arrow"></span>
                            </div>
                        </a>
                        <a href="javascript:void(0);" class="setting-item"
                            [routerLink]="[urlConstant.ADD_ON, 'Additional']" (click)="closeSetting(); closeSidebar()">
                            <div class="setting-info">
                                <div class="setting-icon">
                                    <span class="icon-icon-subscription"></span>
                                </div>
                                <div class="setting-title">
                                    <h4>Additional Packages</h4>
                                    <p>Manage your additional packages</p>
                                </div>
                            </div>
                            <div class="setting-view">
                                <span class="icon-icon-right-angle-arrow"></span>
                            </div>
                        </a>
                        <a href="javascript:void(0);" class="setting-item" [routerLink]="[urlConstant.CREDITS_SETTING]"
                            (click)="closeSetting(); closeSidebar()">
                            <div class="setting-info">
                                <div class="setting-icon">
                                    <span class="icon-icon-credits"></span>
                                </div>
                                <div class="setting-title">
                                    <h4>Credits</h4>
                                    <p>Manage your credits</p>
                                </div>
                            </div>
                            <div class="setting-view">
                                <span class="icon-icon-right-angle-arrow"></span>
                            </div>
                        </a>
                        <div class="tool-trip-wrapper verify-your-page">
                            <a href="javascript:void(0);" class="setting-item" [routerLink]="[urlConstant.VERIFY_PAGE]"
                                (click)="closeSetting(); closeSidebar()">
                                <div class="setting-info">
                                    <div class="setting-icon">
                                        <span class="icon-icon-verify"></span>
                                    </div>
                                    <div class="setting-title">
                                        <div class="tooltip-box">
                                            <h4>Verify Your Page <ng-template #tolTemplate1>We recommend our users to
                                                    self-verify their identity in order to build safety and trust. Users
                                                    can
                                                    self-verify their personal identity and residency, and also the
                                                    identity
                                                    and residency of business page that they create. <br /><br />We will
                                                    review the uploaded documents usually within 48 hours. A successful
                                                    review will add a “Verified” tag to your profile and/ or the
                                                    business
                                                    page(s).
                                                    <br /> <br />
                                                    Please note that for a “Verified” tag, your profile (or business
                                                    page
                                                    profile) must match the verification information as per the
                                                    documents
                                                    provided.
                                                </ng-template>

                                            </h4>
                                        </div>
                                        <p>Move verify your page and accreditation below credits</p>
                                    </div>
                                </div>
                                <div class="setting-view">
                                    <span class="icon-icon-right-angle-arrow"></span>
                                </div>
                            </a>
                            <span class="icon-icon-info tooltip-icon" [tooltip]="tolTemplate1" placement="left"
                                triggers="click" #pop1="bs-tooltip" (click)="pop2.hide()"></span>
                        </div>
                        <div class="tool-trip-wrapper">
                            <a href="javascript:void(0);" class="setting-item"
                                [routerLink]="[urlConstant.VERIFY_ACCREDITATION]"
                                (click)="closeSetting(); closeSidebar()">
                                <div class="setting-info">
                                    <div class="setting-icon">
                                        <span class="icon-icon-verify-thik"></span>
                                    </div>
                                    <div class="setting-title">
                                        <div class="tooltip-box">
                                            <h4>Verify Your Accreditation <ng-template #tolTemplate2>Under the federal
                                                    securities laws, any offer or sale of a security must either be
                                                    registered with the SEC or meet an exemption. Regulation D under the
                                                    Securities Act provides a number of exemptions from the registration
                                                    requirements, allowing some companies to offer and sell their
                                                    securities
                                                    without having to register the offering with the SEC. For additional
                                                    details please visit
                                                    <a href="https://www.investor.gov/introduction-investing/investing-basics/glossary/regulation-d-offerings"
                                                        class="primary-link" target="_blank" rel=noopener
                                                        rel="https://www.investor.gov/introduction-investing/investing-basics/glossary/regulation-d-offerings">https://www.investor.gov/introduction-investing/investing-basics/glossary/regulation-d-offerings</a>
                                                    <br /> <br />
                                                    An Accredited Investor is one that the US Securities and Exchange
                                                    Commission (SEC) deems to be sophisticated enough to protect
                                                    themselves
                                                    in making investment decisions and therefore do not require certain
                                                    additional protections under certain securities laws. Accredited
                                                    Investors are able to invest in certain private securities offerings
                                                    and
                                                    are usually the investors who invest in startups and private
                                                    companies.
                                                    For additional details please visit
                                                    <a class="primary-link"
                                                        href="https://www.investor.gov/introduction-investing/investing-basics/glossary/accredited-investors"
                                                        target="_blank" rel=noopener
                                                        rel="https://www.investor.gov/introduction-investing/investing-basics/glossary/accredited-investors">
                                                        https://www.investor.gov/introduction-investing/investing-basics/glossary/accredited-investors</a>
                                                </ng-template>

                                            </h4>
                                        </div>
                                        <p>Verify Your Accreditation</p>
                                    </div>
                                </div>
                                <div class="setting-view">
                                    <span class="icon-icon-right-angle-arrow"></span>
                                </div>
                            </a>
                            <span class="icon-icon-info tooltip-icon" [tooltip]="tolTemplate2" placement="left"
                                triggers="click" #pop2="bs-tooltip" (click)="pop1.hide()"></span>
                        </div>
                        <a href="javascript:void(0);" class="setting-item" [routerLink]="[urlConstant.PRIVACY]"
                            (click)="closeSetting(); closeSidebar()">
                            <div class="setting-info">
                                <div class="setting-icon">
                                    <span class="icon-icon-document"></span>
                                </div>
                                <div class="setting-title">
                                    <h4>Privacy</h4>
                                    <p>Set your account privacy</p>
                                </div>
                            </div>
                            <div class="setting-view">
                                <span class="icon-icon-right-angle-arrow"></span>
                            </div>
                        </a>
                        <a href="javascript:void(0);" class="setting-item" [routerLink]="[urlConstant.CREDIT_CARD]"
                            (click)="closeSetting(); closeSidebar()">
                            <div class="setting-info">
                                <div class="setting-icon">
                                    <span class="icon-icon-credit-card"></span>
                                </div>
                                <div class="setting-title">
                                    <h4>Credit Cards</h4>
                                    <p>Manage your credit cards for payments</p>
                                </div>
                            </div>
                            <div class="setting-view">
                                <span class="icon-icon-right-angle-arrow"></span>
                            </div>
                        </a>
                        <a href="javascript:void(0);" class="setting-item" [routerLink]="[urlConstant.BANK_ACCOUNT]"
                            (click)="closeSetting(); closeSidebar()">
                            <div class="setting-info">
                                <div class="setting-icon">
                                    <span class="icon-bank-account"></span>
                                </div>
                                <div class="setting-title">
                                    <h4>Bank Account</h4>
                                    <p>Add your bank account details to receive funds</p>
                                </div>
                            </div>
                            <div class="setting-view">
                                <span class="icon-icon-right-angle-arrow"></span>
                            </div>
                        </a>
                        <a href="javascript:void(0);" class="setting-item" [routerLink]="[urlConstant.EMAIL_ID_SETTING]"
                            (click)="closeSetting(); closeSidebar()">
                            <div class="setting-info">
                                <div class="setting-icon">
                                    <span class="icon-icon-email"></span>
                                </div>
                                <div class="setting-title">
                                    <h4>Email ID</h4>
                                    <p>Change your Email ID</p>
                                </div>
                            </div>
                            <div class="setting-view">
                                <span class="icon-icon-right-angle-arrow"></span>
                            </div>
                        </a>
                        <a href="javascript:void(0);" class="setting-item"
                            [routerLink]="[urlConstant.MOBILE_NO_SETTING]" (click)="closeSetting(); closeSidebar()">
                            <div class="setting-info">
                                <div class="setting-icon">
                                    <span class="icon-icon-mobile"></span>
                                </div>
                                <div class="setting-title">
                                    <h4>Mobile Number</h4>
                                    <p>Change your Mobile Number</p>
                                </div>
                            </div>
                            <div class="setting-view">
                                <span class="icon-icon-right-angle-arrow"></span>
                            </div>
                        </a>
                        <a href="javascript:void(0);" class="setting-item"
                            *ngIf="localStorageService.getToken('hasPassword')"
                            [routerLink]="[urlConstant.PASSWORD_SETTING]" (click)="closeSetting(); closeSidebar()">
                            <div class="setting-info">
                                <div class="setting-icon">
                                    <span class="icon-icon-lock"></span>
                                </div>
                                <div class="setting-title">
                                    <h4>Password</h4>
                                    <p>Change your Password</p>
                                </div>
                            </div>
                            <div class="setting-view">
                                <span class="icon-icon-right-angle-arrow"></span>
                            </div>
                        </a>
                        <a href="javascript:void(0);" class="setting-item" [routerLink]="[urlConstant.REFER_AND_EARN]"
                            (click)="closeSetting(); closeSidebar()">
                            <div class="setting-info">
                                <div class="setting-icon">
                                    <span class="icon-icon-refer"></span>
                                </div>
                                <div class="setting-title">
                                    <h4>Refer & Earn</h4>
                                    <p>Refer this App and Earn</p>
                                </div>
                            </div>
                            <div class="setting-view">
                                <span class="icon-icon-right-angle-arrow"></span>
                            </div>
                        </a>
                        <a href="javascript:void(0);" class="setting-item"
                            [routerLink]="[urlConstant.IDENTITY_BLOCK_LIST]" (click)="closeSetting(); closeSidebar()">
                            <div class="setting-info">
                                <div class="setting-icon">
                                    <span class="icon-icon-block"></span>
                                </div>
                                <div class="setting-title">
                                    <h4>Block List</h4>
                                    <p>Your Block List</p>
                                </div>
                            </div>
                            <div class="setting-view">
                                <span class="icon-icon-right-angle-arrow"></span>
                            </div>
                        </a>
                        <a href="javascript:void(0);" class="setting-item" [routerLink]="[urlConstant.CLOSE_ACCOUNT]"
                            (click)="closeSetting(); closeSidebar()">
                            <div class="setting-info">
                                <div class="setting-icon">
                                    <span class="icon-icon-delete"></span>
                                </div>
                                <div class="setting-title">
                                    <h4>Close Account</h4>
                                    <p>Delete your Account</p>
                                </div>
                            </div>
                            <div class="setting-view">
                                <span class="icon-icon-right-angle-arrow"></span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlay-full"></div>
    </div>
</header>

<header *ngIf="authSubHeader" class="nav-header">
    <div class="container-fluid">
        <div class="nav-login-wrap">
            <div class="header-logo">
                <a href="javascript:void(0);" class="logo-nav" [routerLink]="[urlConstant.HOME]">
                    <img src="../../../../assets/images/logo.svg" alt="Logo" width="172" height="25" />
                </a>
            </div>

            <div class="header-right">
                <a href="javascript:;" class="primary-link back-to-home-btn"
                    (click)="openBackHomeModal(home,pages)"><span class="icon-Icon-arrow-left"></span>
                    Back to
                    Home</a>
                <div class="user-profile">
                    <div class="btn-group" dropdown>
                        <a href="javascript:void(0);" id="my-profile" dropdownToggle class="profile-dropdown"
                            aria-controls="dropdown-myprofile">
                            <img [src]="localStorageService.getToken('photo') ? localStorageService.getToken('photo') : '../../../../assets/images/gitfunded.svg'"
                                (error)="img4.src = '../../../../assets/images/gitfunded.svg'" #img4 alt="Profile">
                            <span class="icon-icon-arrow-down"></span>
                        </a>
                        <ul id="dropdown-myprofile" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                            aria-labelledby="my-profile">
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.MY_PROFILE + '0']">My Profile</a></li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.MY_PAGES]">My Pages</a></li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.MY_PROFILE + '4']">My Favorites</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.MY_PROFILE + '2']">My Connections</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.MY_VIDEOS]">My Videos</a></li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.MEETING_LIST]">My Meetings</a></li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    [routerLink]="[urlConstant.DOCUMENT_LIST, 0]">My
                                    Documents</a>
                            </li>
                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);"
                                    (click)="logout()">Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>