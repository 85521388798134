<div class="save-changes-notification">
    <div class="modal-card sm-modal-card">
        <button class="popup-close-btn btn" (click)="close()">
            <span class="icon-icon-close"></span>
        </button>
        <ng-container>
            <div>
                <h5 style="text-align: center">{{title}}</h5>
            </div>
        </ng-container>
        <p style="font-size: large;">
            {{message}}
        </p>
        <form>
            <div class="btnbox justify-content-between">
                <button type="button" class="btn btn-primary  ml-3 mr-5" (click)="buy()">{{buttonName}}</button>
            </div>
        </form>
    </div>
</div>