<div class="modal-card popup-box-shadow">
  <button class="popup-close-btn btn">
    <span class="icon-icon-close" (click)="bsModalRef.hide()"></span>
  </button>
  <h3>
    {{collectionName ? 'Edit' : 'Add'}} Collection
  </h3>
  <form [formGroup]="collectionForm" autocomplete="off">
    <div class="form-group">
      <label>Collection Name <sup>*</sup></label>
      <input type="text" formControlName="collectionName" class="form-control" placeholder="Collection Name">
      <span class="text-danger"
        *ngIf="(collectionForm.controls.collectionName.touched || submitted) && collectionForm.controls.collectionName.errors?.required">
        {{messages.ERROR.REQUIRED.collectionName}}
      </span>
      <span class="text-danger"
        *ngIf="(collectionForm.controls.collectionName.touched || submitted) && collectionForm.controls.collectionName.errors?.whiteSpace">
        {{messages.ERROR.REQUIRED.collectionName}}
      </span>
    </div>
    <div class="btnbox justify-content-center">
      <button type="submit" class="btn btn-primary btn-lg" (click) = "saveCollection()">{{collectionName ? 'Edit' : 'Create'}}</button>
    </div>
  </form>
</div>