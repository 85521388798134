<div class="login-signup-form modal-card">
  <button class="popup-close-btn btn" (click)="bsModalRef.hide()">
    <span class="icon-icon-close"></span>
  </button>
  <h3>
    Welcome, Sign in to Continue!
  </h3>
  <p>
    Sign in to access your reserved area.
  </p>
  <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="form-group">
      <label>Email ID or Mobile Number</label>
      <input type="text" class="form-control" formControlName="emailId" placeholder="Email ID or Mobile Number">
      <span class="text-danger"
        *ngIf="(loginForm.controls.emailId.touched || submitted) && loginForm.controls.emailId.errors?.required">
        {{messages.ERROR.REQUIRED.emailId}}
      </span>
      <span class="text-danger"
        *ngIf="(loginForm.controls.emailId.touched || submitted) && loginForm.controls.emailId.errors?.invalidInput">
        {{messages.ERROR.PATTERN.emailId}}
      </span>
    </div>
    <div class="form-group">
      <label>Password</label>
      <div class="input-with-icon">
        <input [type]="passwordType ? 'text' : 'password'" id="password-field" type="password" class="form-control"
          formControlName="password" placeholder="Password">
        <span [tooltip]="passwordType ? 'Hide Password' : 'Show Password'" toggle="#password-field"
          class="bx bx-hide field-icon toggle-password"
          [ngClass]="{'bx-bx-hide': !passwordType, 'icon-icon-eye': passwordType}"
          (click)="toggleFieldTextType()"></span>
      </div>
      <span class="text-danger"
        *ngIf="(loginForm.controls.password.touched || submitted) && loginForm.controls.password.errors?.required">
        {{messages.ERROR.REQUIRED.newPassword}}
      </span>
      <span class="text-danger"
        *ngIf="(loginForm.controls.password.touched || submitted) && loginForm.controls.password.errors?.minlength">
        {{messages.ERROR.MINLENGTH.newPassword}}
      </span>
    </div>
    <div class="forgot-link primary-link" (click)="forgetPass()">Forgot Your Password?</div>
    <div class="btnbox">
      <button class="btn btn-primary">Sign In</button>
    </div>
  </form>
  <p class="m-0">
    Or Sign in with
  </p>
  <app-social-signup [type]="'login'"></app-social-signup>
</div>
<div class="info">
  <div class="signup-info info-wrap">
    <h3>Don’t have an account yet?</h3>
    <p>
      Let’s get you all set up so you can verify your account and begin setting up profile.
    </p>
    <button (click)="register()" class="btn btn-default">Sign Up</button>
  </div>
</div>