<div (visibility)="visibility($event)" #element>
    <div class="received-msg-card blockmessage-card" [ngClass]="message.sender_id == qbId ? 'sentmsg' : ''">
        <div class="msg-outer left-msg">
            <div class="msg-text-box receive-msg">
                <div class="profile-img">
                    <ng-container *ngIf="dialog.data.type == msgType.personal">
                        <ng-container *ngIf="message.sender_id == qbId">
                            <img [src]="selectedChatOverview.fromIdentity.photo ? imgUrl +selectedChatOverview.fromIdentity.photo: '../../../../assets/images/gitfunded.svg'"
                                alt="" width="36" height="36">
                        </ng-container>
                        <ng-container *ngIf="message.sender_id != qbId">
                            <img [src]="selectedChatOverview.toIdentity.photo ? imgUrl +selectedChatOverview.toIdentity.photo: '../../../../assets/images/gitfunded.svg'"
                                alt="" width="36" height="36">
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="dialog.data.type == msgType.job">
                        <ng-container *ngIf="message.sender_id == dialog.data.memberQuickBloxId">
                            <img [src]="dialog.data.memberIdentityPhoto ? imgUrl + dialog.data.memberIdentityPhoto: '../../../../assets/images/gitfunded.svg'"
                                alt="" width="36" height="36">
                        </ng-container>
                        <ng-container *ngIf="message.sender_id == dialog.data.ownerQuickBloxId">
                            <img [src]="dialog.data.ownerIdentityPhoto ? imgUrl + dialog.data.ownerIdentityPhoto: '../../../../assets/images/gitfunded.svg'"
                                alt="" width="36" height="36">
                        </ng-container>
                        <ng-container *ngIf="message.sender_id == dialog.data.teamMemberQuickBloxId">
                            <img [src]="dialog.data.teamMemberIdentityPhoto ? imgUrl + dialog.data.teamMemberIdentityPhoto: '../../../../assets/images/gitfunded.svg'"
                                alt="" width="36" height="36">
                        </ng-container>
                    </ng-container>
                </div>
                <div class="msg">
                    <p *ngIf="message.message && message.message !== qbConst.ATTACHMENT.BODY">
                        {{message.message}}
                    </p>
                    <ng-container *ngIf="message.attachments && message.attachments.length">
                        <ng-container *ngIf="message.attachments.length < 4">
                            <ng-container *ngFor="let att of message.attachments">
                                <ng-container *ngIf="att.type == qbConst.ATTACHMENT.PDF_TYPE">
                                    <a href="javascript:void(0);" class="uploaded-file-wrap">
                                        <div class="file" (click)="openAttachment(att)">
                                            <div class="iconbox">
                                                <span class="icon-icon-pdf"></span>
                                            </div>
                                            <p>{{att.name}}</p>
                                        </div>
                                        <button type="submit" class="btn download-btn" (click)="downloadPdf(att)">
                                            <span class="icon-download-icon"></span>
                                        </button>
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="att.type == qbConst.ATTACHMENT.VIDEO_TYPE">
                                    <div class="videobox-imgbox">
                                        <video [src]="att.src"></video>
                                        <div class="video-overlay">
                                            <button type="button" class="btn" (click)="openAttachment(att)">
                                                <span class="icon-icon-play"></span>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="att.type == qbConst.ATTACHMENT.IMG_TYPE">
                                    <div class="uploaded-imgbox">
                                        <div class="imgbox" (click)="openAttachment(att)">
                                            <img [alt]="att.name" [src]="att.src">
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="message.attachments.length >= 4 ">
                            <div class="uploaded-imgbox multipost">
                                <div class="imgbox" (click)="openAttachment(message.attachments, true, 0)">
                                    <img [alt]="message.attachments[0].name" [src]="message.attachments[0].src">
                                </div>
                                <div class="imgbox" (click)="openAttachment(message.attachments, true, 1)">
                                    <img [alt]="message.attachments[1].name" [src]="message.attachments[1].src">
                                </div>
                                <div class="imgbox" (click)="openAttachment(message.attachments, true, 2)">
                                    <img [alt]="message.attachments[2].name" [src]="message.attachments[2].src">
                                </div>
                                <div class="imgbox imgbox-group" (click)="openAttachment(message.attachments, true, 3)">
                                    <img [alt]="message.attachments[3].name" [src]="message.attachments[3].src">
                                    <div class="img-count" *ngIf="message.attachments.length > 4 ">
                                        <span class="icon-icon-plush"></span>
                                        {{message.attachments.length - 4}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </ng-container>
                    <span class="time">
                        {{message.date_sent | date:'hh:mm a'}}
                        <!-- <span *ngIf="message.status == 'sent'" class="icon-icon-tick ml-2"></span>
                  <span *ngIf="message.status == 'delivered'" class="icon-duble-tick ml-2"></span>
                  <span *ngIf="message.status == 'read'" class="icon-duble-tick-read ml-2"></span> -->
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>