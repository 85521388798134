import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styles: []
})
export class ViewPdfComponent implements OnInit {
  imgUrl = environment.imgUrl;

  pdf;
  viewPdf;
  name;
  isChat = false;

  constructor(
    public bsModalRef: BsModalRef,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    const url = this.isChat ? (this.pdf) : (this.imgUrl + this.pdf)
    this.viewPdf =  this.sanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/viewer?url=' + url + '&embedded=true');
  }

}
