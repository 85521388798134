<div class="verify-otp-modal-card modal-card sm-modal-card">
  <form>
    <button class="popup-close-btn btn" (click)="closeVerifyOtp()">
      <span class="icon-icon-close"></span>
    </button>
    <div class="imgbox">
      <picture>
        <img class="img-fluid" src="../../../../assets/images/verify-otp-img.svg" alt="Verify OTP" width="280"
          height="280">
      </picture>
    </div>
    <h3>
      Verify OTP
    </h3>
    <p>
      We have sent 6 digit OTP to
      <strong class="d-block">{{emailIdMobile}}</strong>
    </p>
    <div class="form-group input-group-otp">
      <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
    </div>
    <span class="text-danger" *ngIf="errorOtp && otp.length != 6">{{messages.ERROR.REQUIRED.otp}}</span>
    <div class="btnbox">
      <button (click)="verifyOtp()" class="btn btn-primary">Verify OTP</button>
    </div>
    <p> <span *ngIf="showTimer"> Resend in 00 : {{timeLeft > 9 ? timeLeft : ('0' + timeLeft)}}</span></p>
    <p *ngIf="!showTimer">
      Didn't receive OTP yet? <a href="javascript:;" class="primary-link" (click)="resendOtp()">Resend OTP</a>
    </p>
  </form>
</div>