<div class="forgot-password-modal-card modal-card sm-modal-card">
  <button class="popup-close-btn btn" (click)="bsModalRef.hide()">
    <span class="icon-icon-close"></span>
  </button>
  <div class="imgbox">
    <picture>
      <img class="img-fluid" src="../../../../assets/images/forgot-password-img.svg" alt="Forgot Password" width="280"
        height="280">
    </picture>
  </div>
  <h3>
    Forgot Your Password?
  </h3>
  <p>
    Enter your registered Email ID or Mobile Number in order to receive the OTP to reset your password.
  </p>
  <form class="form" [formGroup]="forgetPassform" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="form-group">
      <label>Email ID or Mobile Number</label>
      <input type="text" class="form-control" formControlName="emailId" placeholder="Email ID or Mobile Number">
      <span class="text-danger"
        *ngIf="(forgetPassform.controls.emailId.touched || submitted) && forgetPassform.controls.emailId.errors?.required">
        {{messages.ERROR.REQUIRED.emailId}}
      </span>
      <span class="text-danger"
        *ngIf="(forgetPassform.controls.emailId.touched || submitted) && forgetPassform.controls.emailId.errors?.invalidInput">
        {{messages.ERROR.PATTERN.emailId}}
      </span>
    </div>
    <div class="btnbox">
      <button class="btn btn-primary">Send</button>
    </div>
  </form>
</div>