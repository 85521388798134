import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { APIConstants } from 'src/app/common/constants/callAPI-constants';
import { blockIdentityType, msgStatus, msgType, oneTimePackageType, paymentType, statusCode, subscriptionType } from 'src/app/common/constants/constant';
import { messages } from 'src/app/common/constants/messages';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { CommonService } from 'src/app/common/services/service/common.service';
import { DialogService } from 'src/app/common/services/service/dialog.service';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { MessageService } from 'src/app/common/services/service/message.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { VideoProcessingService } from 'src/app/common/services/service/video-processing.service';
import { QBHelper } from 'src/app/common/services/TS-files/qbHelper';
import { QBUIHelper } from 'src/app/common/services/TS-files/qbUIHelper';
import { qbConst } from 'src/assets/config/qb.config';
import { environment } from 'src/environments/environment';
import { DiscardSavePageComponent } from '../../modals/discard-save-page/discard-save-page.component';

@Component({
  selector: 'app-common-message-dialog',
  templateUrl: './common-message-dialog.component.html',
  styles: []
})
export class CommonMessageDialogComponent implements OnInit {

  urlConstant = URLConstants;
  apiConstant = APIConstants;
  statusCode = statusCode;
  imgUrl = environment.imgUrl;
  msgType = msgType;
  msgStatus = msgStatus;
  message = messages;
  qbConst = qbConst;
  blockIdentityType = blockIdentityType;
  oneTimePackageType = oneTimePackageType;
  paymentType = paymentType;
  subscriptionType = subscriptionType;

  showDialog = false;
  dialog;

  // @ViewChild('field') field: ElementRef;

  chatName: string;
  messages: any = [];
  messageField = '';
  attachments: any = [];

  qbId;
  selectedChatOverview;
  textMsg;
  subscriptionFeatures;

  constructor(
    private dialogService: DialogService,
    private qbHelper: QBHelper,
    private messageService: MessageService,
    private commonService: CommonService,
    private localStorageService: LocalStorageService,
    private modalService: BsModalService,
    private showErrorService: ShowErrorService,
    private router: Router,
    private videoService: VideoProcessingService,
    private sanitizer: DomSanitizer
  ) {
    this.qbId = this.localStorageService.getToken('qbId');
    this.dialogService.getDialogWindow().subscribe((res) => {
      if (res) {
        this.showDialog = res;
      }
    })
    this.dialogService.$windowDialogDetails.subscribe((res) => {
      if (res) {
        this.dialog = res;
        this.getChatOverview(this.dialog);
        this.messageField = '';
        // this.field.nativeElement.focus();
      }
    })
    this.dialogService.joinToDialog(this.dialog).then((result) => {
      this.dialog.onlineStatus = result;
    });
    this.dialogService.currentDialogEvent.subscribe((dilog: Object) => {
      this.dialog = dilog;
    });
    this.messageService.messagesEvent.subscribe((msgs: Object) => {
      this.messages = Object.keys(msgs).map(function (key) {
        return [key, msgs[key]];
      });
      this.setAttachmentLayout();
    });
  }

  ngOnInit() {
    this.getCurrentSubscription()
  }

  getCurrentSubscription() {
    this.commonService.callApi(this.apiConstant.CURRENT_SUB_FEATURE, '', 'get', false).then((success) => {
      if (success.status === this.statusCode.SUCCESS) {
        this.subscriptionFeatures = success.body.data;
        this.localStorageService.setToken('subscriptionFeatures', JSON.stringify(this.subscriptionFeatures));
      }
    })
  }

  getChatOverview(chat) {
    let fromIdentityId, toIdentityId;
    if (chat.user_id == this.qbId) {
      fromIdentityId = chat.data.ownerIdentityId;
      toIdentityId = chat.data.memberIdentityId;
    } else {
      fromIdentityId = chat.data.memberIdentityId;
      toIdentityId = chat.data.ownerIdentityId;
    }
    const params = { fromIdentityId, toIdentityId };
    this.commonService.callApi(this.apiConstant.CHAT_OVERVIEW, params, 'post', false).then((success) => {
      if (success.status == this.statusCode.SUCCESS) {
        this.selectedChatOverview = success.body.data;
        this.selectedChatOverview._id = chat._id;
        // if (success.body.data.connectionData.status == this.msgStatus.accepted) {
        this.getChatHistory();
        // }
      }
    })
  }

  acceptReject(status) {
    let msg, btnName, cancelBtnName;
    if (status == this.msgStatus.accepted) {
      msg = this.message.ERROR.ALERT.ACCEPT_MSG;
      btnName = this.message.ERROR.ALERT.ACCEPT_MSG_BTN;
      cancelBtnName = this.message.ERROR.ALERT.ACCEPT_MSG_CANCEL_BTN;
    } else {
      msg = this.message.ERROR.ALERT.REJECT_MSG;
      btnName = this.message.ERROR.ALERT.REJECT_MSG_BTN;
      cancelBtnName = this.message.ERROR.ALERT.REJECT_MSG_CANCEL_BTN;
    }
    const modalRef = this.modalService.show(DiscardSavePageComponent,
      {
        initialState: {
          message: msg,
          buttonName: btnName,
          cancelButtonName: cancelBtnName
        }, class: 'common-modal modal-dialog-centered'
      });
    modalRef.content.event.subscribe((res) => {
      if (res === true) {
        const params = {
          fromIdentityId: this.selectedChatOverview.fromIdentity.identityId,
          toIdentityId: this.selectedChatOverview.toIdentity.identityId,
          status,
          quickBloxGroupId: this.selectedChatOverview._id
        }
        this.commonService.callApi(this.apiConstant.ACCEPT_REJECT_CHAT, params, 'post', false).then((success) => {
          if (success.status == this.statusCode.SUCCESS) {
            if (status == this.msgStatus.accepted) {
              this.getChatOverview(this.dialog);
            } else {
              // this.selectedChat = null;
              this.selectedChatOverview = null;
              // this.getMessages();
            }
          }
        })
      }
    });
  }

  block(status) {
    let msg, btnName, cancelBtnName;
    if (status == 'block') {
      msg = this.message.ERROR.ALERT.BLOCK_MSG;
      btnName = this.message.ERROR.ALERT.BLOCK_MSG_BTN;
      cancelBtnName = this.message.ERROR.ALERT.BLOCK_MSG_CANCEL_BTN;
    } else {
      msg = this.message.ERROR.ALERT.UNBLOCK_MSG;
      btnName = this.message.ERROR.ALERT.UNBLOCK_MSG_BTN;
      cancelBtnName = this.message.ERROR.ALERT.UNBLOCK_MSG_CANCEL_BTN;
    }
    const modalRef = this.modalService.show(DiscardSavePageComponent,
      {
        initialState: {
          message: msg,
          buttonName: btnName,
          cancelButtonName: cancelBtnName
        }, class: 'common-modal modal-dialog-centered'
      });
    modalRef.content.event.subscribe((res) => {
      if (res === true) {
        const params = {
          fromIdentityId: this.selectedChatOverview.fromIdentity.identityId,
          toIdentityId: this.selectedChatOverview.toIdentity.identityId
        }
        this.commonService.callApi(this.apiConstant.BLOCK, params, 'put', false).then((success) => {
          if (success.status == this.statusCode.SUCCESS) {
            this.getChatOverview(this.dialog);
          }
        })
      }
    });
  }

  getChatHistory() {
    const
      self = this,
      params = {
        chat_dialog_id: this.dialog._id,
        sort_desc: 'date_sent',
        limit: 50,
        skip: 0,
        mark_as_read: 0
      };
    self.dialogService.currentDialog.full = false;
    this.messageService.getMessages(params)
      .then((res) => {
        if (res.items.length < 50) {
          self.dialogService.currentDialog.full = true;
        }
        this.messageService.setMessages(res.items.reverse(), 'bottom');
      })
      .catch((err) => {
        this.showErrorService.popToast('error', err);
      });
  }

  loadMoreMessages(e) {
    const
      self = this,
      elem = e.currentTarget;
    if (this.dialogService.currentDialog.full !== undefined && this.dialogService.currentDialog.full) {
      delete elem.dataset.loading;
      return false;
    }
    if (elem.scrollTop < 150 && !elem.dataset.loading) {
      elem.dataset.loading = true;
      const params = {
        chat_dialog_id: this.dialog._id,
        sort_desc: 'date_sent',
        limit: 50,
        skip: this.messageService.messages.length,
        mark_as_read: 0
      };
      this.messageService.getMessages(params)
        .then((res) => {
          if (res.items.length < 50) {
            self.dialogService.currentDialog.full = true;
          }
          self.messageService.setMessages(
            res.items.reverse().concat(self.messageService.messages), 'top');
          delete elem.dataset.loading;
        })
        .catch((err) => {
          this.showErrorService.popToast('error', err);
        });
    }
  }

  openFile(event) {
    event.preventDefault();
    const element = document.getElementById('attach_btn');
    element.click();
  }

  prepareToUpload(e) {
    const self = this,
      files = e.currentTarget.files,
      dialogId = this.dialogService.currentDialog._id;
    for (let i = 0; i < files.length; i++) {
      self.uploadFilesAndGetIds(files[i], dialogId);
    }
    e.currentTarget.value = null;
  }

  uploadFilesAndGetIds(file, dialogId) {
    if (file.size >= this.qbConst.ATTACHMENT.SIZE) {
      return this.showErrorService.popToast('error', this.message.ERROR.OTHERS.FILE_SIZE)
    }
    let sendType, thumbnail;
    const type = file.type.split('/')[0];
    if (type == 'image') {
      sendType = this.qbConst.ATTACHMENT.IMG_TYPE
    } else if (type == 'video') {
      sendType = this.qbConst.ATTACHMENT.VIDEO_TYPE;
      this.videoService.generateThumbnail(file).then((success) => {
        this.videoService.b64toBlob(success.split(';')[1].split(',')[1], success.split(';')[0].split(':')[1]).then((file) => {
          thumbnail = file
        })
      });
    } else {
      sendType = this.qbConst.ATTACHMENT.PDF_TYPE
    }
    setTimeout(() => {
      this.attachments = [{
        id: 'isLoading',
        src: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)),
        type: sendType,
        thumbnail: thumbnail ? this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(thumbnail)) : null,
        name: file.name
      }];
      const self = this;
      this.qbHelper.qbCreateAndUpload(file).
        then(response => {
          self.attachments = [];
          // self.attachments.push({ id: response.uid, type: sendType, name: file.name }) ;
          const attachments = [{ id: response.uid, type: sendType, name: file.name }];
          self.sendMessage(this.qbConst.ATTACHMENT.BODY, attachments);
        }).catch(err => {
          self.attachments = [];
          this.showErrorService.popToast('error', err.details);
        });
    }, 200)
  }

  onSubmit() {
    if (this.messageField) {
      this.sendMessage(this.messageField);
      this.messageField = null;
    } else if (this.attachments.length) {
      this.sendMessage(this.qbConst.ATTACHMENT.BODY, this.attachments);
    }
  }

  sendMessage(body, attachments: any = false) {
    if (!attachments && body && !body.trim()) {
      return;
    }
    const
      self = this,
      msg = {
        type: 'groupchat',
        body: body,
        extension: {
          save_to_history: 1,
          dialog_id: self.dialog._id
        },
        markable: 1
      };
    if (attachments) {
      msg.extension['attachments'] = attachments;
    }
    const
      message = self.messageService.sendMessage(self.dialog, msg),
      newMessage = self.messageService.fillNewMessageParams(Number(self.qbId), message);
    self.dialogService.setDialogParams(newMessage);
    self.messageService.messages.push(newMessage);
    self.messageService.addMessageToDatesIds(newMessage);
    self.messageService.messagesEvent.emit(self.messageService.datesIds);
    // if (attachments) { self.attachments = []; }
    setTimeout(() => {
      QBUIHelper.scrollTo(document.querySelector('.msg-chatbox'), 'bottom');
    }, 200);
  }

  onKeydown(e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      this.onSubmit();
      return false;
    }
  }

  gotoUserDetail() {
    const type = this.selectedChatOverview.toIdentity.type, id = this.selectedChatOverview.toIdentity.identityId;
    switch (type) {
      case this.blockIdentityType.investor: { this.router.navigate([this.urlConstant.INVESTOR_DETAIL, 0, id]); break; }
      case this.blockIdentityType.serviceProvider: { this.router.navigate([this.urlConstant.SP_DETAILS, 0, id]); break; }
      case this.blockIdentityType.startup: { this.router.navigate([this.urlConstant.INV_OPP_DETAILS, 0, id]); break; }
      default: { this.router.navigate([this.urlConstant.PEOPLE_DETAILS, 0, id]); break; }
    }
  }

  buy() {
    this.router.navigate([this.urlConstant.SETTINGS + '/' + this.paymentType.oneTimePackage, this.oneTimePackageType.chatCredit, 'Message']);
  }

  upgrade() {
    this.router.navigate([this.urlConstant.COMPARE_PLANS]);
  }

  goToJobDetail() {
    if (this.qbId == this.dialog.data.memberQuickBloxId || this.qbId == this.dialog.data.teamMemberQuickBloxId) {
      this.router.navigate([this.urlConstant.BROWSE_JOB_DETAILS, this.dialog.data.jobId]);
    } else if (this.qbId == this.dialog.data.ownerQuickBloxId) {
      this.router.navigate([this.urlConstant.JOB_POSTING_DETAILS, this.dialog.data.jobId]);
    }
  }

  setAttachmentLayout() {
    this.messages.forEach(element => {
      let dayMsgs = element[1];
      let currentIndex = dayMsgs.length - 1;
      let gridImageCount = 0;
      for (let index = currentIndex; index >= 0; index--) {
        const currentMsg = dayMsgs[index]

        if (index != 0) {
          const prevMsg = dayMsgs[index - 1]
          if (dayMsgs[index].attachments.length && dayMsgs[index - 1].attachments.length) {
            const currentAttachment = dayMsgs[index].attachments[0];
            const prevAttachment = dayMsgs[index - 1].attachments[0];
            if (currentMsg.sender_id == prevMsg.sender_id && this.qbConst.ATTACHMENT.IMG_TYPE == currentAttachment?.type
              && this.qbConst.ATTACHMENT.IMG_TYPE == prevAttachment?.type) {
              gridImageCount++
            } else {
              if (gridImageCount >= 3) {
                var tempIndex = currentIndex
                for (let i = 0; i < gridImageCount; i++) {
                  tempIndex--
                  dayMsgs[currentIndex].attachments.push(dayMsgs[tempIndex].attachments[0]);
                }
                tempIndex = currentIndex
                for (let i = 0; i < gridImageCount; i++) {
                  tempIndex--
                  dayMsgs.splice(tempIndex, 1)
                }
              }
              currentIndex = index - 1
              gridImageCount = 0
              continue
            }
          } else {
            currentIndex = index - 1
            gridImageCount = 0;
            continue
          }
        } else {
          if (gridImageCount >= 3) {
            var tempIndex = currentIndex
            for (let i = 0; i < gridImageCount; i++) {
              tempIndex--
              dayMsgs[currentIndex].attachments.push(dayMsgs[tempIndex].attachments[0]);
            }
            tempIndex = currentIndex
            for (let i = 0; i < gridImageCount; i++) {
              tempIndex--
              dayMsgs.splice(tempIndex, 1)
            }
          }
          currentIndex = index - 1
          gridImageCount = 0;
          continue
        }
      }
    });
  }

  close() {
    this.showDialog = false;
    this.dialogService.setDialogWindow(false);
  }

}
