<div class="identity-modal-card modal-card">
  <button class="popup-close-btn btn" (click)="bsModalRef.hide()">
    <span class="icon-icon-close"></span>
  </button>
  <h3>
    {{(isApplyJob) ? 'Select Service Provider' : 'Select Account'}}
  </h3>
  <div class="profile-group-wrap">
    <a class="media profile-media" [ngClass]="(selectedIdentity.identity === identity.identity) ? 'selected' : ''"
      *ngFor="let identity of identityList" (click)="(selectedIdentity = identity);(notSP=false)">
      <div class="imgbox">
        <img class="img-fluid" [src]="identity.photo ? imgUrl + identity.photo: 
          '../../../../assets/images/gitfunded.svg'" (error)="img.src = '../../../../assets/images/gitfunded.svg'" #img
          alt="">
      </div>
      <div class="media-body">
        <h5 class="mt-0 mb-0 primary-text">{{identity.name}}</h5>
        <p>{{(isApplyJob) ? (identity.identityType ? identityType.serviceProvider : '-') : (identity.about ?
          identity.about : '-')}}</p>
        <span class="selected-icon"><img src="../../../../assets/images/single-tick.svg" alt="" width="18"
            height="13"></span>
      </div>
    </a>
    <span class="text-danger" *ngIf="notSP">
      Please select service provider
    </span>
  </div>
  <div class="btnbox">
    <a class="btn btn-primary" (click)="submitIdenity()">Done</a>
  </div>
</div>