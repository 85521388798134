<div class="home-feed-modal">
    <div class="close-ic" (click)="closeModal()">
        <a href="javascript:void(0);">
            <span class="icon-icon-close"></span>
        </a>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-8">
            <div class="video-wrap">
                <div class="video-block" id="videoblock">
                    <vg-player>
                        <vg-controls [vgAutohide]="true" [vgAutohideTime]="1">
                            <vg-play-pause></vg-play-pause>
                            <vg-scrub-bar [vgSlider]="true" class="d-flex">
                                <vg-scrub-bar-current-time [vgSlider]="true">
                                </vg-scrub-bar-current-time>
                                <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                            </vg-scrub-bar>

                            <div class="custom-play-pause-time d-flex">
                                <vg-time-display vgProperty="current" vgFormat="hh:mm:ss">
                                </vg-time-display>
                                <span class="white-line"></span>
                                <vg-time-display vgProperty="total" vgFormat="hh:mm:ss"></vg-time-display>
                            </div>
                            <div class="custom-controls-icons d-flex align-items-center">
                                <vg-mute></vg-mute>
                                <vg-volume></vg-volume>
                                <span class="icon-maximize" (click)="viewPictureInPicture()"></span>
                                <vg-fullscreen (click)="addFullScreen()"></vg-fullscreen>
                            </div>
                        </vg-controls>
                        <vg-overlay-play [vgFor]="'videofeed'"></vg-overlay-play>
                        <vg-buffering [vgFor]="'videofeed'"></vg-buffering>
                        <video [vgMedia]="videoPlayer" #videoPlayer [id]="'videofeed'" class="video-feed"
                            [poster]="videoDetail.videoThumb ? imgUrl + videoDetail.videoThumb : '../../../../assets/images/gitfunded.svg'"
                            preload="auto" [src]="imgUrl + videoDetail.videoPath" autoplay>
                        </video>
                    </vg-player>
                    <div class="dropdown-box">
                        <div class="btn-group" dropdown>
                            <a href="javascript:void(0);" id="my-feed" dropdownToggle class="feed-dropdown"
                                aria-controls="dropdown-feed">
                                <span class="icon-dots"></span>
                            </a>
                            <ul id="dropdown-feed" *dropdownMenu class="dropdown-menu dropdown-menu-right show"
                                role="menu" aria-labelledby="my-feed">
                                <li role="menuitem"
                                    (click)="videoDetail?.isPromoted ? removePromote(videoDetail) : promoteVideo(videoDetail?._id)">
                                    <a href="javascript:void(0);" class="dropdown-item"
                                        *ngIf="loggedInUserId === videoDetail?.userId"><span
                                            [ngClass]="(videoDetail?.isPromoted ?'icon-icon-promote-remove':'icon-icon-promote')"></span>{{videoDetail?.isPromoted
                                        ? 'Stop Promotion' : 'Promote Your Video'}}</a>
                                </li>
                                <li role="menuitem"><a href="javascript:void(0);" class="dropdown-item"
                                        *ngIf="loggedInUserId === videoDetail?.userId"
                                        (click)="editVideo(videoDetail?._id)"><span
                                            class="icon-icon-edit"></span>Edit</a>
                                </li>
                                <li role="menuitem"><a href="javascript:void(0);" class="dropdown-item"
                                        *ngIf="loggedInUserId === videoDetail?.userId"
                                        (click)="deleteVideo(videoDetail?._id)"><span
                                            class="icon-icon-delete"></span>Delete</a></li>
                                <li role="menuitem"><a href="javascript:void(0);" (click)="shareVideo(videoDetail)"
                                        class="dropdown-item"><span class="icon-icon-share"></span>Share</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="content-wrap" id="content">
                <div class="video-title">
                    <h3>{{videoDetail?.title}}</h3>
                </div>
                <div class="tag-info" *ngIf="videoDetail?.identityType === 'serviceProvider'">
                    <div class="tag-item bg-primary">${{videoDetail?.identityInfo.hourlyRate | shortNumber}}
                        /hour</div>
                    <div class="tag-item bg-light-gray">{{videoDetail?.identityInfo.type}}</div>
                </div>
                <div class="tag-info" *ngIf="videoDetail?.identityType === 'investor'">
                    <div class="tag-item bg-success">
                        ${{videoDetail?.identityInfo.investmentRangeFrom | shortNumber}} -
                        ${{videoDetail?.identityInfo.investmentRangeTo | shortNumber}}</div>
                    <div class="tag-item bg-primary">{{videoDetail?.identityInfo.type}}</div>
                </div>
                <div class="tag-info" *ngIf="videoDetail?.identityType === 'startup'">
                    <div class="tag-item bg-danger">${{videoDetail?.identityInfo.currentRoundAmountReceived |
                        shortNumber}} / ${{videoDetail?.identityInfo.currentRoundAmount | shortNumber}}</div>
                    <div class="tag-item bg-primary series-tag"><span
                            class="series">{{videoDetail?.identityInfo.currentFundingRound}}
                        </span> <span class="dollar">${{videoDetail?.identityInfo.amountPerLot |
                            shortNumber}}</span></div>
                </div>
                <div class="description">
                    <p>{{videoDetail?.description}}</p>
                </div>
                <div class="video-status">
                    <div class="in-info-wrap">
                        <p><em class="gray-dot"></em>
                            <span
                                *ngFor="let industry of videoDetail?.industries | slice : 0: 2; let i = index;">{{industry}}
                                <span *ngIf="(videoDetail?.industries.length > 1) && (i== 0)">
                                    <em class="gray-dot"></em> </span>
                            </span>
                            <a><span *ngIf="videoDetail?.industries?.length > 2" [tooltip]="tolTemplate"
                                    triggers="hover" class="text-primary">&nbsp;
                                    ...See More</span></a>
                            <ng-template #tolTemplate>
                                <em class="gray-dot"></em>
                                <span *ngFor="let industry of videoDetail?.industries; let i = index;">{{industry}}
                                    <span
                                        *ngIf="(videoDetail?.industries.length > 1) && (i<videoDetail?.industries.length-1)">
                                        <em class="gray-dot"></em> </span>
                                </span>
                            </ng-template>
                        </p>
                        <p><em class="gray-dot"></em>
                            <span
                                *ngFor="let subInd of videoDetail?.subIndustries | slice : 0: 2; let i = index;">{{subInd}}
                                <span *ngIf="(videoDetail?.subIndustries.length > 1) && (i == 0)">
                                    <em class="gray-dot"></em></span>
                            </span>
                            <a><span *ngIf="videoDetail?.subIndustries?.length > 2" [tooltip]="tolTemplate1"
                                    triggers="hover" class="text-primary">&nbsp;
                                    ...See More</span></a>
                            <ng-template #tolTemplate1>
                                <em class="gray-dot"></em>
                                <span *ngFor="let industry of videoDetail?.subIndustries; let i = index;">{{industry}}
                                    <span
                                        *ngIf="(videoDetail?.subIndustries.length > 1) && (i<videoDetail?.subIndustries.length-1)">
                                        <em class="gray-dot"></em> </span>
                                </span>
                            </ng-template>
                        </p>
                        <p>
                            <em class="gray-dot"></em>{{videoDetail?.createdAt | date: dateFormat.generalDate}}<em
                                class="gray-dot"></em>{{videoDetail?.viewTotal | shortNumber}} {{videoDetail?.viewTotal
                            > 1
                            ? 'Views' : 'View'}}
                        </p>
                    </div>
                </div>
                <div class="video-action">
                    <ul>
                        <li><a href="javascript:;" [ngClass]="videoDetail?.isUserLikes ? 'selected' : ''"
                                (click)="videoDetail?.isUserLikes ? like(videoDetail, true, false, 'feed') : like(videoDetail, true, true, 'feed')">
                                <span
                                    [ngClass]="videoDetail?.isUserLikes ? 'icon-icon-like-selected' : 'icon-icon-like'"></span>
                                {{videoDetail?.likeTotal | shortNumber}}</a></li>
                        <li><a href="javascript:;" [ngClass]="videoDetail?.isUserDislikes ? 'selected' : ''"
                                (click)="videoDetail?.isUserDislikes ? like(videoDetail, false, false, 'feed') : like(videoDetail, false, true, 'feed')">
                                <span
                                    [ngClass]="videoDetail?.isUserDislikes ? 'icon-icon-unlike-selected' : 'icon-icon-unlike'"></span>
                                {{videoDetail?.dislikeTotal | shortNumber}}</a></li>
                    </ul>
                    <div class="favorite">
                        <a href="javascript:;" class="share-ic" (click)="shareVideo(videoDetail)"><span
                                class="icon-icon-share"></span></a>
                        <a href="javascript:;" (click)="addToFav(videoDetail)"><span
                                [ngClass]="videoDetail?.isFavorite ?'icon-icon-favorites-selected':'icon-icon-favorites'"></span></a>
                    </div>
                </div>
                <div class="follow">
                    <div class="follow-info">
                        <div class="profile-img">
                            <img [src]="(videoDetail?.identityInfo?.photo) ? imgUrl + (videoDetail?.identityInfo?.photo) : '../../../../assets/images/gitfunded.svg'"
                                (error)="img.src = '../../../../assets/images/gitfunded.svg'" #img [alt]="">
                        </div>
                        <div class="profile-detail">
                            <a>
                                <h5 (click)="navigateToOwnerDetail(videoDetail)">{{videoDetail?.identityInfo?.name}}
                                </h5>
                            </a>
                            <span *ngIf="videoDetail?.identityInfo?.verificationFlag == 1" class="icon-Icon-green-tick"
                                [tooltip]="tolTemplate4"></span>
                            <span *ngIf="videoDetail?.identityInfo?.verificationFlag == 2"
                                class="icon-Icon-green-tick icon-blue" [tooltip]="tolTemplate5"></span>
                            <ng-template #tolTemplate4>{{messages.VERIFY_MSG}}</ng-template>
                            <ng-template #tolTemplate5>{{messages.ACCR_MSG}}</ng-template>
                            <ng-container *ngIf="!(videoDetail.identityType === identityType.individual)">
                                <span
                                    *ngFor="let industry of videoDetail?.identityInfo?.industries | slice : 0 : 2; let i = index;">{{industry}}
                                    <span *ngIf="((videoDetail?.identityInfo?.industries).length > 1) && (i == 0)">
                                        , </span>
                                </span>
                                <a><span *ngIf="videoDetail?.identityInfo?.industries.length > 2"
                                        [tooltip]="tolTemplate2" triggers="hover" class="text-primary">&nbsp;
                                        ...See More</span></a>
                                <ng-template #tolTemplate2>
                                    <span
                                        *ngFor="let industry of videoDetail?.identityInfo?.industries; let i = index;">{{industry}}
                                        <span
                                            *ngIf="(videoDetail?.identityInfo?.industries.length > 1) && (i<videoDetail?.identityInfo?.industries.length-1)">
                                            , </span>
                                    </span>
                                </ng-template>
                            </ng-container>
                            <p>{{(videoDetail?.identityInfo?.totalConnection) | shortNumber}}
                                {{videoDetail?.identityType
                                ===
                                'individual'
                                ? (videoDetail?.identityInfo?.totalConnection > 1 ? 'Connections' : 'Connection') :
                                (videoDetail?.identityInfo?.totalConnection > 1 ? 'Followers' : 'Follower')}}</p>

                        </div>
                    </div>
                    <div class="follow-btn">
                        <!-- <a href="#" class="btn btn-sm btn-primary"><span class="icon-icon-plush"></span>
                            {{videoDetail?.identityType === 'individual' ? 'Connect' : 'Follow'}}</a> -->


                        <ng-container
                            *ngIf="(videoDetail.identityType === identityType.individual  && loggedInUserId !== videoDetail.identityId); else connectionElseButton">
                            <ng-container *ngIf="videoDetail.identityInfo?.isConnected === 0">
                                <a href="javascript:void(0);"
                                    (click)="sendRemoveRequest(videoDetail.identityId, true, videoDetail.identityInfo?.isConnected)"
                                    class="btn btn-sm btn-primary">
                                    <span class="icon-icon-add-network"></span>
                                    Connect
                                </a>
                            </ng-container>
                            <ng-container
                                *ngIf="videoDetail.identityInfo?.isConnected === 1||videoDetail.identityInfo?.isConnected === 2">
                                <a href="javascript:void(0);"
                                    (click)="sendRemoveRequest(videoDetail.identityId, false, videoDetail.identityInfo?.isConnected)"
                                    class="btn btn-sm btn-primary">
                                    <span
                                        [ngClass]="videoDetail.identityInfo?.isConnected === 1 ?'icon-Group':'icon-icon-one-user'"></span>
                                    {{videoDetail.identityInfo?.isConnected === 1 ?'Pending':'Connected'}}
                                </a>
                            </ng-container>

                        </ng-container>

                        <ng-template #connectionElseButton>
                            <ng-container *ngIf="!(videoDetail.userId == loggedInUserId)">
                                <a *ngIf="videoDetail.identityInfo?.isFollowing===false"
                                    (click)="followPage(videoDetail.identityId, videoDetail.identityType, true)"
                                    href="javascript:void(0);" class="btn btn-sm btn-primary">
                                    <span class="icon-icon-plush"></span>
                                    Follow
                                </a>
                                <a *ngIf="videoDetail.identityInfo?.isFollowing===true"
                                    (click)="unfollowPage(videoDetail.identityId, videoDetail.identityType, false)"
                                    href="javascript:void(0);" class="btn btn-sm btn-tertiary">
                                    Following
                                </a>
                            </ng-container>
                        </ng-template>
                    </div>
                </div>
                <div class="comment-box">
                    <div class="comment-header" (click)="openComments()">
                        <a href="javascript:;" (click)="showCommentDesc = !showCommentDesc">
                            <div class="comment-text">
                                <span class="icon-icon-comment"></span> {{videoDetail?.commentTotal | shortNumber}}
                                {{videoDetail?.commentTotal > 1 ? 'Comments' : 'Comment'}}
                            </div>
                            <div class="toogle-arrow">
                                <span class="icon-Icon-down-arrow-block" (onClick)="openComments()"></span>
                            </div>
                        </a>
                    </div>
                    <div class="comments-view signal-commetns-view">
                        <div class="comments-item" *ngIf="commentList?.length > 0 && commentList[0]">
                            <div class="profile-img">
                                <img [src]="commentList[0].userInfo.photo ? imgUrl + commentList[0].userInfo.photo : '../../../../assets/images/gitfunded.svg'"
                                    (error)="img0.src = '../../../../assets/images/gitfunded.svg'" #img0 alt=""
                                    width="40" height="40">
                            </div>
                            <div class="comments-detail">
                                <h5>{{commentList[0].userInfo.name}} | {{commentList[0].createdAt | date:
                                    dateFormat.generalDate}}</h5>
                                <p>{{commentList[0].comment}}</p>
                                <div class="video-action">
                                    <ul>
                                        <li><a href="javascript:;"
                                                [ngClass]="commentList[0].isUserLikes ? 'selected' : ''"
                                                (click)="commentList[0].isUserLikes ? like(commentList[0], true, false, 'comment') : like(commentList[0], true, true, 'comment')"><span
                                                    [ngClass]="commentList[0].isUserLikes ? 'icon-icon-like-selected' : 'icon-icon-like'"></span>
                                                {{commentList[0].likeTotal}}</a>
                                        </li>
                                        <li><a href="javascript:;"
                                                [ngClass]="commentList[0].isUserDislikes ? 'selected' : ''"
                                                (click)="commentList[0].isUserDislikes ? like(commentList[0], false, false, 'comment') : like(commentList[0], false, true, 'comment')"><span
                                                    [ngClass]="commentList[0].isUserDislikes ? 'icon-icon-unlike-selected' : 'icon-icon-unlike'"></span>
                                                {{commentList[0].dislikeTotal}}</a></li>
                                        <li><a href="javascript:;"
                                                (click)="commentList[0].showReply ? (commentList[0].showReply = false) :getReplies(commentList[0])"><span
                                                    class="icon-icon-comment"></span>
                                                {{commentList[0].commentTotal}}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="dropdown-box">
                                <div class="btn-group" dropdown>
                                    <a href="javascript:void(0);" id="my-feed" dropdownToggle class="feed-dropdown"
                                        aria-controls="dropdown-feed">
                                        <span class="icon-dots"></span>
                                    </a>
                                    <ul id="dropdown-feed" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="my-feed">
                                        <li role="menuitem" *ngIf="loggedInUserId === commentList[0].userInfo.id">
                                            <a class="dropdown-item" href="javascript:void(0);"
                                                (click)="showEditInput(commentList[0])"><span
                                                    class="icon-icon-edit"></span>Edit</a>
                                        </li>
                                        <li role="menuitem" *ngIf="loggedInUserId === commentList[0].userInfo.id"><a
                                                class="dropdown-item" href="javascript:void(0);"
                                                (click)="deleteComment(commentList[0]._id)"><span
                                                    class="icon-icon-delete"></span>Delete</a></li>
                                        <li role="menuitem" *ngIf="!(loggedInUserId === commentList[0].userInfo.id)"><a
                                                class="dropdown-item" href="javascript:void(0);"
                                                (click)="reportComment(commentList[0]._id)"><span
                                                    class="icon-flags"></span>Report</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="reply-box" *ngIf="commentList[0].showReply">
                                <div class="add-comments-in-replay">
                                    <div class="profile-img">
                                        <img [src]="photo ? photo :'../../../../assets/images/gitfunded.svg'"
                                            (error)="img3.src = '../../../../assets/images/gitfunded.svg'" #img3
                                            alt="Profile" width="40" height="40">
                                    </div>
                                    <div class="comment-input">
                                        <input type="text" placeholder="Add your reply.."
                                            [(ngModel)]="commentList[0].reply"
                                            (keydown.enter)="addReply(commentList[0])">
                                        <div class="send-btn">
                                            <a href="javascript:;" (click)="addReply(commentList[0])">
                                                <span class="icon-icon-right-angle-arrow"></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-comments-item" *ngFor="let reply of commentList[0].replyList">
                                    <div class="d-flex">
                                        <div class="profile-img flex-shrink-0">
                                            <img [src]="reply.userInfo.photo ? imgUrl + reply.userInfo.photo : '../../../../assets/images/gitfunded.svg'"
                                                (error)="img.src4 = '../../../../assets/images/gitfunded.svg'" #img4
                                                alt="" width="40" height="40">
                                        </div>
                                        <div class="comments-detail">
                                            <h5>{{reply.userInfo.name}} | {{reply.createdAt | date:
                                                dateFormat.generalDate}}
                                            </h5>
                                            <p *ngIf="!reply.edit">{{reply.comment}}</p>
                                            <input *ngIf="reply.edit" type="text" placeholder="Edit your public reply.."
                                                [(ngModel)]="reply.text">
                                            <a *ngIf="reply.edit" href="javascript:;" (click)="editComment(reply)">
                                                <span class="icon-icon-right-angle-arrow"></span>
                                            </a>
                                            <div class="video-action">
                                                <ul>
                                                    <li><a href="javascript:;"
                                                            [ngClass]="reply.isUserLikes ? 'selected' : ''"
                                                            (click)="reply.isUserLikes ? like(reply, true, false, 'comment') : like(reply, true, true, 'comment')"><span
                                                                [ngClass]="reply.isUserLikes ? 'icon-icon-like-selected' : 'icon-icon-like'"></span>
                                                            {{reply.likeTotal}}</a>
                                                    </li>
                                                    <li><a href="javascript:;"
                                                            [ngClass]="reply.isUserDislikes ? 'selected' : ''"
                                                            (click)="reply.isUserDislikes ? like(reply, false, false, 'comment') : like(reply, false, true, 'comment')"><span
                                                                [ngClass]="reply.isUserDislikes ? 'icon-icon-unlike-selected' : 'icon-icon-unlike'"></span>
                                                            {{reply.dislikeTotal}}</a></li>
                                                    <li><a href="javascript:;"><span class="icon-icon-comment"></span>
                                                            {{reply.commentTotal}}</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dropdown-box">
                                        <div class="btn-group" dropdown>
                                            <a href="javascript:void(0);" id="my-feed" dropdownToggle
                                                class="feed-dropdown" aria-controls="dropdown-feed">
                                                <span class="icon-dots"></span>
                                            </a>
                                            <ul id="dropdown-feed" *dropdownMenu
                                                class="dropdown-menu dropdown-menu-right" role="menu"
                                                aria-labelledby="my-feed">
                                                <li role="menuitem" *ngIf="loggedInUserId === reply.userInfo.id">
                                                    <a class="dropdown-item" href="javascript:void(0);"
                                                        (click)="showEditInput(reply)"><span
                                                            class="icon-icon-edit"></span>Edit</a>
                                                </li>
                                                <li role="menuitem" *ngIf="loggedInUserId === reply.userInfo.id"><a
                                                        class="dropdown-item" href="javascript:void(0);"
                                                        (click)="deleteComment(reply._id, commentList[0])"><span
                                                            class="icon-icon-delete"></span>Delete</a></li>
                                                <li role="menuitem" *ngIf="!(loggedInUserId === reply.userInfo.id)"><a
                                                        class="dropdown-item" href="javascript:void(0);"
                                                        (click)="reportComment(reply._id)"><span
                                                            class="icon-flags"></span>Report</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="commentList[0].replyList ? commentList[0].replyList.length<commentList[0].totalReply : false">
                                    <a href="javascript:;" (click)="getReplies(commentList[0],true)">View More
                                        Replies</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="comments-view" *ngIf="videoDetail?.commentTotal > 0">
                        <div class="comments-item" *ngFor="let comment of commentList; index as i">
                            <div class="profile-img">
                                <img [src]="comment.userInfo.photo ? imgUrl + comment.userInfo.photo : '../../../../assets/images/gitfunded.svg'"
                                    (error)="img1.src = '../../../../assets/images/gitfunded.svg'" #img1 alt=""
                                    width="40" height="40">
                            </div>
                            <div class="comments-detail">
                                <h5>{{comment.userInfo.name}} | {{comment.createdAt | date: dateFormat.generalDate}}
                                </h5>
                                <p *ngIf="!comment.edit">{{comment.comment}}</p>
                                <input *ngIf="comment.edit" type="text" placeholder="Edit your public comment.."
                                    [(ngModel)]="comment.text">
                                <a *ngIf="comment.edit" href="javascript:;" (click)="editComment(comment)">
                                    <span class="icon-icon-right-angle-arrow"></span>
                                </a>
                                <div class="video-action">
                                    <ul>
                                        <li><a href="javascript:;" [ngClass]="comment.isUserLikes ? 'selected' : ''"
                                                (click)="comment.isUserLikes ? like(comment, true, false, 'comment') : like(comment, true, true, 'comment')"><span
                                                    [ngClass]="comment.isUserLikes ? 'icon-icon-like-selected' : 'icon-icon-like'"></span>
                                                {{comment.likeTotal}}</a>
                                        </li>
                                        <li><a href="javascript:;" [ngClass]="comment.isUserDislikes ? 'selected' : ''"
                                                (click)="comment.isUserDislikes ? like(comment, false, false, 'comment') : like(comment, false, true, 'comment')"><span
                                                    [ngClass]="comment.isUserDislikes ? 'icon-icon-unlike-selected' : 'icon-icon-unlike'"></span>
                                                {{comment.dislikeTotal}}</a></li>
                                        <li><a href="javascript:;"
                                                (click)="comment.showReply ? (comment.showReply = false) :getReplies(comment)"><span
                                                    class="icon-icon-comment"></span>
                                                {{comment.totalReply ? comment.totalReply :
                                                comment.commentTotal}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="dropdown-box">
                                <div class="btn-group" dropdown>
                                    <a href="javascript:void(0);" id="my-feed" dropdownToggle class="feed-dropdown"
                                        aria-controls="dropdown-feed">
                                        <span class="icon-dots"></span>
                                    </a>
                                    <ul id="dropdown-feed" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                                        role="menu" aria-labelledby="my-feed">
                                        <li role="menuitem" *ngIf="loggedInUserId === comment.userInfo.id">
                                            <a class="dropdown-item" href="javascript:void(0);"
                                                (click)="showEditInput(comment)"><span
                                                    class="icon-icon-edit"></span>Edit</a>
                                        </li>
                                        <li role="menuitem" *ngIf="loggedInUserId === comment.userInfo.id"><a
                                                class="dropdown-item" href="javascript:void(0);"
                                                (click)="deleteComment(comment._id)"><span
                                                    class="icon-icon-delete"></span>Delete</a></li>
                                        <li role="menuitem" *ngIf="!(loggedInUserId === comment.userInfo.id)"><a
                                                class="dropdown-item" href="javascript:void(0);"
                                                (click)="reportComment(comment._id)"><span
                                                    class="icon-flags"></span>Report</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="reply-box" *ngIf="comment.showReply">
                                <div class="add-comments-in-replay">
                                    <div class="profile-img">
                                        <img [src]="photo ? photo :'../../../../assets/images/gitfunded.svg'"
                                            (error)="img7.src = '../../../../assets/images/gitfunded.svg'" #img7
                                            alt="Profile" width="40" height="40">
                                    </div>
                                    <div class="comment-input">
                                        <input type="text" placeholder="Add your reply.." [(ngModel)]="comment.reply"
                                            (keydown.enter)="addReply(comment)">
                                        <div class="send-btn">
                                            <a href="javascript:;" (click)="addReply(comment)">
                                                <span class="icon-icon-right-angle-arrow"></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-comments-item" *ngFor="let reply of comment.replyList">
                                    <div class="profile-img">
                                        <img [src]="reply.userInfo.photo ? imgUrl + reply.userInfo.photo : '../../../../assets/images/gitfunded.svg'"
                                            (error)="img.src6 = '../../../../assets/images/gitfunded.svg'" #img6 alt=""
                                            width="40" height="40">
                                    </div>
                                    <div class="comments-detail">
                                        <h5>{{reply.userInfo.name}} | {{reply.createdAt | date: dateFormat.generalDate}}
                                        </h5>
                                        <p *ngIf="!reply.edit">{{reply.comment}}</p>
                                        <input *ngIf="reply.edit" type="text" placeholder="Edit your public reply.."
                                            [(ngModel)]="reply.text">
                                        <a *ngIf="reply.edit" href="javascript:;" (click)="editComment(reply)">
                                            <span class="icon-icon-right-angle-arrow"></span>
                                        </a>
                                        <div class="video-action">
                                            <ul>
                                                <li><a href="javascript:;"
                                                        [ngClass]="reply.isUserLikes ? 'selected' : ''"
                                                        (click)="reply.isUserLikes ? like(reply, true, false, 'comment') : like(reply, true, true, 'comment')"><span
                                                            [ngClass]="reply.isUserLikes ? 'icon-icon-like-selected' : 'icon-icon-like'"></span>
                                                        {{reply.likeTotal}}</a>
                                                </li>
                                                <li><a href="javascript:;"
                                                        [ngClass]="reply.isUserDislikes ? 'selected' : ''"
                                                        (click)="reply.isUserDislikes ? like(reply, false, false, 'comment') : like(reply, false, true, 'comment')"><span
                                                            [ngClass]="reply.isUserDislikes ? 'icon-icon-unlike-selected' : 'icon-icon-unlike'"></span>
                                                        {{reply.dislikeTotal}}</a></li>
                                                <li><a href="javascript:;"><span class="icon-icon-comment"></span>
                                                        {{reply.commentTotal}}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="dropdown-box">
                                        <div class="btn-group" dropdown>
                                            <a href="javascript:void(0);" id="my-feed" dropdownToggle
                                                class="feed-dropdown" aria-controls="dropdown-feed">
                                                <span class="icon-dots"></span>
                                            </a>
                                            <ul id="dropdown-feed" *dropdownMenu
                                                class="dropdown-menu dropdown-menu-right" role="menu"
                                                aria-labelledby="my-feed">
                                                <li role="menuitem" *ngIf="loggedInUserId === reply.userInfo.id">
                                                    <a class="dropdown-item" href="javascript:void(0);"
                                                        (click)="showEditInput(reply)"><span
                                                            class="icon-icon-edit"></span>Edit</a>
                                                </li>
                                                <li role="menuitem" *ngIf="loggedInUserId === reply.userInfo.id"><a
                                                        class="dropdown-item" href="javascript:void(0);"
                                                        (click)="deleteComment(reply._id, comment)"><span
                                                            class="icon-icon-delete"></span>Delete</a></li>
                                                <li role="menuitem" *ngIf="!(loggedInUserId === reply.userInfo.id)"><a
                                                        class="dropdown-item" href="javascript:void(0);"
                                                        (click)="reportComment(reply._id)"><span
                                                            class="icon-flags"></span>Report</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="comment.replyList ? comment.replyList.length<comment.totalReply : false"> <a
                                        href="javascript:;" (click)="getReplies(comment,true)">View More Replies</a>
                                </div>
                            </div>
                        </div>
                        <div class="view-more-comments" *ngIf="commentList ? commentList.length<totalComments : false">
                            <a href="javascript:;" (click)="getComments(true)">View More Comments</a>
                        </div>
                    </div>
                    <div class="add-comments">
                        <div class="profile-img">
                            <img [src]="photo ? photo :'../../../../assets/images/gitfunded.svg'" alt="Profile"
                                (error)="img5.src = '../../../../assets/images/gitfunded.svg'" #img5 width="40"
                                height="40">
                        </div>
                        <div class="comment-input">
                            <input type="text" placeholder="Add your public comment.." [(ngModel)]="myComment"
                                (keydown.enter)="addComment()">
                            <div class="send-btn">
                                <a href="javascript:;" (click)="addComment()">
                                    <span class="icon-icon-right-angle-arrow"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>